import { CONSTANT } from "../../../constants/constants";
import { CategoryType } from "../../../parametersConstancy/parametersConstancyTypes";
import { getDateAgo } from "../../../utils";
import {
  RequestCategoriesType,
  RequestFiltersType,
  SummaryCategoryResult,
  VisitorRecoginitonDataRequestDto,
  VRDataType,
} from "../types";

export const createVRRequestDto = (
  requestData: RequestFiltersType,
  dgm: number,
): VisitorRecoginitonDataRequestDto => {
  const {
    partnersIds,
    actionTerminations,
    deviceTypes,
    sites,
    jsVersions,
    countries,
    platforms,
    calls,
    timeGrouping,
  } = requestData;

  const customDateRange = requestData.customDateRange;

  let requestedPeriod: { from: Date | null; to: Date | null } = {
    from: customDateRange.customDateStart,
    to: customDateRange.customDateEnd,
  };

  if (timeGrouping !== CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
    if (timeGrouping)
      requestedPeriod = timeGrouping
        ? getRequestPeriod(timeGrouping)
        : getRequestPeriod(CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value);
  }
  const callsValue = calls?.filter((unit: CategoryType) => unit.isSelected)[0]?.id;
  const selectedOptionCalls: number = platforms.length > 0 ? -1 : Number(callsValue);

  const categories: RequestCategoriesType = {
    actionTerminations: actionTerminations
      .filter((unit: CategoryType) => unit.isSelected)
      .map((item) => {
        return item.id;
      }),
    deviceTypes: deviceTypes
      .filter((unit: CategoryType) => unit.isSelected)
      .map((item) => {
        return item.id;
      }),
    sites: sites
      .filter((unit: CategoryType) => unit.isSelected)
      .map((item) => {
        return item.id;
      }),
    jsVersions: jsVersions
      .filter((unit: CategoryType) => unit.isSelected)
      .map((item) => {
        return item.id;
      }),
    countries: countries
      .filter((unit: CategoryType) => unit.isSelected)
      .map((item) => {
        return item.id;
      }),
    platforms: platforms
      .filter((unit: CategoryType) => unit.isSelected)
      .map((item) => {
        return item.id;
      }),
  };

  const requestDto: VisitorRecoginitonDataRequestDto = {
    partnersIds,
    categories,
    dgm,
    calls: selectedOptionCalls,
    currentPeriod: requestedPeriod,
    previousPeriod: requestedPeriod,
  };
  return requestDto;
};

const getRequestPeriod = (timeGrouping: number) => {
  const today = new Date();

  if (timeGrouping === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value) {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const to = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), yesterday.getUTCDate()));
    const from = null;
    return { from, to };
  }

  if (timeGrouping === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value) {
    const to = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 0));
    today.setDate(0);
    const from = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1));
    return { from, to };
  }

  let reportDateStart = getDateAgo(today, timeGrouping);
  let from = new Date(
    Date.UTC(
      reportDateStart.startDate.getUTCFullYear(),
      reportDateStart.startDate.getUTCMonth(),
      reportDateStart.startDate.getUTCDate(),
    ),
  );
  let toDate = new Date(today.setDate(today.getDate() - 1));
  let to = new Date(Date.UTC(toDate.getUTCFullYear(), toDate.getUTCMonth(), toDate.getUTCDate()));

  return { from, to };
};

export const summarizeCategoryDataByDate = (data: VRDataType[]): SummaryCategoryResult => {
  const unsortedResult = data.reduce((acc: SummaryCategoryResult, item: VRDataType) => {
    const date = "" + item.dateCreated; // Convert Date to string
    const isActionTerminations = Boolean(item.actionTerminations?.name);
    const actionTermination = item.actionTerminations?.name ?? "[Unrecognized]";
    const totalRecords = +item.totalRecords;
    const totalOnlineRecords = +(item?.totalOnlineRecords ?? 0);
    const dataSendTotalRecords = +(item?.dataSendTotalRecords ?? 0);

    if (!acc[date]) {
      acc[date] = {
        actionTerminations: {},
        totalRecords: 0,
        totalOnlineRecords: 0,
        dataSendTotalRecords: 0,
      };
    }

    if (isActionTerminations && !acc[date].actionTerminations[actionTermination]) {
      acc[date].actionTerminations[actionTermination] = {
        totalRecords: 0,
        totalOnlineRecords: 0,
      };
    }
    if (isActionTerminations) {
      acc[date].actionTerminations[actionTermination].totalRecords += totalRecords;
      acc[date].actionTerminations[actionTermination].totalOnlineRecords += totalOnlineRecords;
    }
    acc[date].totalRecords += totalRecords;
    acc[date].totalOnlineRecords += totalOnlineRecords;
    acc[date].dataSendTotalRecords += dataSendTotalRecords;

    return acc;
  }, {});

  // Sort the entries by date and create a new object
  const sortedEntries = Object.entries(unsortedResult).sort((a, b) => {
    return new Date(a[0]).getTime() - new Date(b[0]).getTime();
  });

  return Object.fromEntries(sortedEntries);
};
