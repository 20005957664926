import moment from "moment";
import { Typography, Box } from "@mui/material";
import { convertLocal } from "../../../../utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { getGridStringOperators } from "@mui/x-data-grid";
import { getRenderPeriod } from "./table-helpers";
import { BasicTooltip } from "../../../../components/BasicTooltip/BasicTooltip";
import { CONSTANT } from "../../../../constants/constants";

export const nameToColor = (str) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, "0");
  }
  return color;
};

export function addAlpha(color, opacity) {
  // set values between 0 and 1.
  let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

  return color + _opacity.toString(16).toUpperCase();
}
const renderCurrencyStringInOrder = (cellValue, cellUnit) => {
  return (
    <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
      {convertLocal(Number(cellValue).toFixed(2))}
    </Typography>
  );
};
const renderMainTableCell = (cellValue, cellUnit) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "left",
      }}
    >
      {renderCurrencyStringInOrder(cellValue, cellUnit)}
    </Box>
  );
};

const customFilter = getGridStringOperators().map((operator) => {
  const newOperator = { ...operator };
  const newGetApplyFilterFn = (filterItem, column) => {
    return (params) => {
      switch (filterItem.operator.toLowerCase()) {
        case "equals":
          return (
            !filterItem?.value ||
            params.value.toLowerCase() === filterItem?.value.toLowerCase() ||
            params.formattedValue.toLowerCase() === filterItem?.value.toLowerCase()
          );
        case "startswith":
          return (
            !filterItem?.value ||
            params.value.toLowerCase().startsWith(filterItem?.value.toLowerCase()) ||
            params.formattedValue.toLowerCase().startsWith(filterItem?.value.toLowerCase())
          );
        case "endswith":
          return (
            !filterItem?.value ||
            params.value.toLowerCase().endsWith(filterItem?.value.toLowerCase()) ||
            params.formattedValue.toLowerCase().endsWith(filterItem?.value.toLowerCase())
          );
        case "isempty":
          return (
            !params.value ||
            params.value.trim() === "" ||
            !params.formattedValue ||
            params.formattedValue.trim() === ""
          );
        case "isnotempty":
          return (
            (params.value && params.value.trim() !== "") ||
            (params.formattedValue && params.formattedValue.trim() !== "")
          );
        case "isanyof":
          // Assuming filterItem.value is an array of values to check against
          return (
            !filterItem?.value ||
            !filterItem?.value.length ||
            filterItem.value.includes(params.value.toLowerCase()) ||
            filterItem.value.includes(params.formattedValue.toLowerCase())
          );
        case "contains":
          return (
            !filterItem?.value ||
            params.value.toLowerCase().includes(filterItem?.value.toLowerCase()) ||
            params.formattedValue.toLowerCase().includes(filterItem?.value.toLowerCase())
          );
        default:
          throw new Error("Invalid operator");
      }
    };
  };
  newOperator.getApplyFilterFn = newGetApplyFilterFn;
  return newOperator;
});

const headerTitleAlign = "center";

export const defaultColumns = (columnsProps) => [
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.DATE.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.DATE.header,
    headerClassName: "group-header-single-child",
    // hideable: false,
    summaryRowName: "submitted_date",
    flex: 1,
    align: "center",
    headerTitleAlign: "center",
    // valueFormatter: (props) => {
    //   // valueFormatter: ({ value }) => {

    //   return formatDate(new Date(props.value)).fullFormat
    // },
    printToSummaryRow: true,
    renderCell: (props) => {
      let text = "";
      let st = "";
      if ("groupedByHours" in props.colDef && props.colDef.groupedByHours === true) {
        text = moment(props.value).format("DD.MM-HH:mm");
        let str = text.split("-");
        text = `${str[0]}`;
        st = `${str[1]}`;
      } else {
        text = getRenderPeriod(props.value, columnsProps.dateGroupingMode, columnsProps.renderSelectedDate);
      }
      return (
        <Box
          sx={{
            width: "100%",
            margin: "0px auto",
            // padding: "5px",
            display: "flex-wrap",
            justifyContent: "center",
            // backgroundColor: "#F0FAFF",
            whiteSpace: "normal",
            borderRadius: "5px",
          }}
        >
          <Typography sx={{ fontSize: "12px" }}>
            {text} {Boolean(st) ? " -" : null}
          </Typography>
          {Boolean(st) ? <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>{st}</Typography> : null}
        </Box>
      );
    },
    //  width: 100
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.PARTNERS.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.PARTNERS.header,
    headerClassName: "group-header-single-child",
    base: "partners",
    patnerName: 1,
    summaryRowName: "pName",
    flex: 1.5,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    customStylingSummaryRowItem: { flex: 1.5 }, //applied to summary row div
    // valueFormatter: ({ value }) => value + "%",
    unit: "partner",
    printToSummaryRow: true,
    renderCell: (props) => {
      return (
        <Box
          sx={{
            width: "100%",
            // margin: "0px auto",
            padding: "2px 0px",
            display: "flex",
            justifyContent: "center",
            background: addAlpha(nameToColor(props.row.pName), 0.3),
            // borderLeft: `6px solid ` + nameToColor(props.row.pName.toLowerCase()),
            borderRadius: "5px",
          }}
        >
          <Typography
            sx={{
              marginLeft: "4px",
              color: "#000",
              fontSize: "12px",
              fontWeight: 400,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {columnsProps.isPresentationModeEnabled ? props.row.dummy_id : props.row.pName}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.PCT.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.PCT.header,
    headerClassName: "group-header-single-child",
    base: "pct",
    summaryRowName: "pct",
    flex: 1,
    // width: "50px",
    customStylingSummaryRowItem: { flex: 1 }, //applied to summary row div
    align: "center",
    headerTitleAlign: headerTitleAlign,
    type: "number",
    valueFormatter: ({ value }) => value + "%",
    unit: "mode",
    printToSummaryRow: true,
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CURRENCIES.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CURRENCIES.header,
    headerClassName: "group-header-single-child",
    base: "currencies",
    summaryRowName: "pct",
    flex: 1,
    // width: "50px",
    customStylingSummaryRowItem: { flex: 1 }, //applied to summary row div
    align: "center",
    headerTitleAlign: headerTitleAlign,
    // valueParser: (value) => {
    //   console.log(value);
    //   return getSymbolFromCurrency(value) || value;
    // },
    filterOperators: customFilter,
    valueFormatter: ({ value }) => {
      return getSymbolFromCurrency(value) || value;
    },
    unit: "mode",
    printToSummaryRow: true,
    sortComparator: (v1, v2) => {
      return v1.localeCompare(v2);
    },
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.AB_REVENUE.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.AB_REVENUE.header,
    headerClassName: "group-header-child",
    fieldApt: 1,
    summaryRowName: "abRevLift",
    groupColumnName: "A/B Revenue",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    unit: "currency",
    printToSummaryRow: true,
    type: "number",
    renderCell: ({ row: { abRevLift, currency } }) => {
      const borderColorStl = Number(abRevLift) > 0 ? "none" : Number(abRevLift) === 0 ? "none" : "orange";
      return (
        <Box
          sx={{
            width: "100%",
            margin: "0px auto",
            display: "flex",
            justifyContent: "center",
            border: `1px solid ${borderColorStl}`,
            borderRadius: "5px",
          }}
        >
          {renderCurrencyStringInOrder(abRevLift, currency)}
        </Box>
      );
    },
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.AB_REVENUE_PERCENT.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.AB_REVENUE_PERCENT.header,
    headerClassName: "group-header-child",
    summaryRowName: "abRevActualRateLift",
    groupColumnName: "A/B Revenue",
    flex: 1,
    align: "center",
    headerTitleAlign: "center",
    unit: "%",
    printToSummaryRow: true,
    type: "number",
    renderCell: ({ row: { abRevActualRateLift } }) => {
      return (
        <BasicTooltip tooltipText={Number(abRevActualRateLift).toFixed(6) + "%"}>
          <Typography variant="body"> {Number(abRevActualRateLift).toFixed(3) + "%"}</Typography>
        </BasicTooltip>
      );
    },
  },

  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.REVENU_WITH_IIQ.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.REVENU_WITH_IIQ.header,
    headerClassName: "group-header-child",
    fieldApt: 1,
    summaryRowName: "total_cpm1",
    groupColumnName: "Revenue",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    unit: "currency",
    printToSummaryRow: true,
    customStylingSummaryRowItem: {
      borderRadius: "5px",
      backgroundColor: "#47C9FF",
    }, //applied to summary row div
    type: "number",
    renderCell: ({ row: { total_cpm1, currency } }) => {
      return (
        <Box
          sx={{
            width: "100%",
            margin: "0px auto",
            display: "flex",
            justifyContent: "center",
            borderRadius: "5px",
          }}
        >
          {renderCurrencyStringInOrder(total_cpm1, currency)}
        </Box>
      );
    },
    // valueGetter: (param) => Number(param.value).toFixed(2) + " " + param.row.currency,
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.REVENUE_WITHOUT_IIQ.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.REVENUE_WITHOUT_IIQ.header,
    headerClassName: "group-header-child",
    fieldApt: 1,
    summaryRowName: "total_cpm2",
    groupColumnName: "Revenue",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    unit: "currency",
    printToSummaryRow: true,
    type: "number",
    renderCell: ({ row: { total_cpm2, currency } }) => renderMainTableCell(total_cpm2, currency),
  },

  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.IMPRESSIONS_WITH_IIQ.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.IMPRESSIONS_WITH_IIQ.header,
    headerClassName: "group-header-child",
    summaryRowName: "total_rows1",
    groupColumnName: "Imp.",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    printToSummaryRow: true,
    type: "number",
    valueFormatter: ({ value }) => convertLocal(Number(value)),
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.IMPRESSIONS_WITHOUT_IIQ.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.IMPRESSIONS_WITHOUT_IIQ.header,
    headerClassName: "group-header-child",
    summaryRowName: "total_rows2",
    groupColumnName: "Imp.",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    printToSummaryRow: true,
    filterable: true,
    type: "number",
    valueFormatter: ({ value }) => convertLocal(Number(value)),
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_WITH_IIQ.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_WITH_IIQ.header,
    headerClassName: "group-header-child",
    fieldApt: 1,
    summaryRowName: "avg_cpma",
    groupColumnName: "CPM",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    unit: "currency",
    printToSummaryRow: true,
    type: "number",
    renderCell: ({ row: { avg_cpma, currency } }) => renderMainTableCell(avg_cpma, currency),
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_WITHOUT_IIQ.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_WITHOUT_IIQ.header,
    headerClassName: "group-header-child",
    fieldApt: 1,
    summaryRowName: "avg_cpmb",
    groupColumnName: "CPM",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    unit: "currency",
    printToSummaryRow: true,
    type: "number",
    renderCell: ({ row: { avg_cpmb, currency } }) => {
      return renderMainTableCell(avg_cpmb, currency);
    },
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_LIFT.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_LIFT.header,
    headerClassName: "group-header-child",
    fieldApt: 1,
    summaryRowName: "avgCpmRateLift",
    groupColumnName: "CPM",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    unit: "%",
    printToSummaryRow: true,
    type: "number",
    valueFormatter: ({ value }) => Number(value).toFixed(2) + "%",
  },
  {
    field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.FILL_RATE.field,
    headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.FILL_RATE.header,
    headerClassName: "group-header-single-child",
    summaryRowName: "abFillRateLift",
    flex: 1,
    align: "center",
    headerTitleAlign: headerTitleAlign,
    unit: "%",
    printToSummaryRow: true,
    type: "number",
    renderCell: ({ row: { abFillRateLift } }) => {
      return (
        <BasicTooltip tooltipText={Number(abFillRateLift).toFixed(6) + "%"}>
          <Typography variant="body">{Number(abFillRateLift).toFixed(3) + "%"}</Typography>
        </BasicTooltip>
      );
    },
  },
];

export const optionalColumns = (columnsProps) => {
  return {
    deviceTypes: {
      field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.DEVICE_TYPES.field,
      headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.DEVICE_TYPES.header,
      headerClassName: "group-header-single-child",
      base: "deviceTypes",
      summaryRowName: "device_type",
      align: "left",
      headerTitleAlign: headerTitleAlign,
      unit: "device",
      printToSummaryRow: true,
      flex: 1,
    },
    countries: {
      field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.COUNTRIES.field,
      headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.COUNTRIES.header,
      headerClassName: "group-header-single-child",
      base: "countries",
      summaryRowName: "countryName",
      align: "left",
      headerTitleAlign: headerTitleAlign,
      unit: "country",
      printToSummaryRow: true,
      renderCell: ({ row: { countryName } }) => {
        let height = 22;
        let width = 24;
        let name = countryName?.trim().replace(/ /g, "_").toLowerCase();
        try {
          const Icon = require(`../../../../assets/svgIcons/${name}.svg`).ReactComponent;
          return (
            <Box
              sx={{
                // padding: "0px",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                // margin: "0px",
                gap: "10px",
                textAlign: "center",
              }}
            >
              <Icon id={`item_id_${name}`} height={height} width={width} />
              <Typography
                sx={{ paddingTop: "2px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
                variant="body3XXSmall"
              >
                {countryName}
              </Typography>
            </Box>
          );
        } catch (err) {
          return (
            <Box
              sx={{
                padding: "5px",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <Typography variant="body3XXSmall">{countryName}</Typography>
            </Box>
          );
        }
      },
      flex: 1,
    },
    biddercodes: {
      field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.BIDDERS.field,
      headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.BIDDERS.header,
      headerClassName: "group-header-single-child",
      // base: "biddercodes",
      summaryRowName: "biddercode",
      align: "left",
      headerTitleAlign: "center",
      unit: "bidder",
      printToSummaryRow: true,
      flex: 1,
    },
    browsers: {
      field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.BROWSERS.field,
      headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.BROWSERS.header,
      headerClassName: "group-header-single-child",
      base: "browsers",
      summaryRowName: "browser",
      // width: 110,
      flex: 1,
      customStylingSummaryRowItem: { flex: 1 }, //applied to summary row div
      align: "center",
      headerTitleAlign: "left",
      cellClassName: "name-column--cell",
      unit: "browser",
      printToSummaryRow: true,
      renderCell: ({ row: { client_type } }) => {
        let height = 20;
        let name = client_type?.trim().toLowerCase();

        try {
          const Icon = require(`../../../../assets/svgIcons/${name}.svg`).ReactComponent;
          return <Icon id={`item_id_${name}`} height={height} />;
        } catch (err) {
          return (
            <Box
              sx={{
                padding: "5px",
                display: "flex",
                // justifyContent: "center",
                borderRadius: "5px",
              }}
            >
              <Typography>{client_type}</Typography>
            </Box>
          );
        }
      },
      // editable: true
    },
    sites: {
      field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.SITES.field,
      headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.SITES.header,
      headerClassName: "group-header-single-child",
      base: "sites",
      summaryRowName: "vr_site",
      unit: "site",
      printToSummaryRow: true,
      flex: 1,
      renderCell: (props) => {
        return (
          <Box
            sx={{
              width: "100%",
              margin: "0px auto",
              padding: "5px",
              display: "flex",
              justifyContent: "left",
              // background: addAlpha(nameToColor(props.row.pName), 0.3),
              // borderLeft: `6px solid ` + nameToColor(props.row.pName.toLowerCase()),
              borderRadius: "5px",
              background: "none",
            }}
          >
            <Typography
              sx={{
                marginLeft: "4px",
                color: "#000",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              {columnsProps.isPresentationModeEnabled ? props.row.vr_site : props.row.site_url}
            </Typography>
          </Box>
        );
      },
    },
    jsVersions: {
      field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.JS_VERSIONS.field,
      headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.JS_VERSIONS.header,
      headerClassName: "group-header-single-child",
      base: "jsVersions",
      summaryRowName: "js_version",
      flex: 1,
      unit: "version",
      printToSummaryRow: true,
      renderCell: ({ row: { js_version } }) => {
        return (
          <Box
            sx={{
              width: "60%",
              margin: "0px auto",
              padding: "5px",
              display: "flex",
              justifyContent: "left",
              // backgroundColor: "pink",
              borderRadius: "5px",
            }}
          >
            <Typography>{js_version}</Typography>
          </Box>
        );
      },
    },
    actionTerminations: {
      field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.ACTION_TERMINATIONS.field,
      headerName: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.ACTION_TERMINATIONS.header,
      headerClassName: "group-header-single-child",
      base: "actionTerminations",
      summaryRowName: "action_termination",
      unit: "cause",
      printToSummaryRow: true,
      flex: 1,
      // width: 110,
      // editable: true
    },
  };
};

export const columnGroupingModel = [
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.DATE.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.DATE.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.PARTNERS.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.PARTNERS.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.PCT.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.PCT.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.ACTION_TERMINATIONS.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.ACTION_TERMINATIONS.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.JS_VERSIONS.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.JS_VERSIONS.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.BIDDERS.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.BIDDERS.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.SITES.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.SITES.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.DEVICE_TYPES.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.DEVICE_TYPES.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.COUNTRIES.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.COUNTRIES.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.BROWSERS.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.BROWSERS.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CURRENCIES.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CURRENCIES.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.AB_REVENUE.group,
    headerClassName: "group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.AB_REVENUE.field,
      },
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.AB_REVENUE_PERCENT.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.REVENU_WITH_IIQ.group,
    headerClassName: "group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.REVENU_WITH_IIQ.field,
      },
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.REVENUE_WITHOUT_IIQ.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.IMPRESSIONS_WITH_IIQ.group,
    headerClassName: "group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.IMPRESSIONS_WITH_IIQ.field,
      },
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.IMPRESSIONS_WITHOUT_IIQ.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_WITH_IIQ.group,
    headerClassName: "group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_WITH_IIQ.field,
      },
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_WITHOUT_IIQ.field,
      },
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.CPM_LIFT.field,
      },
    ],
  },
  {
    groupId: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.FILL_RATE.group,
    headerName: "",
    headerClassName: "single-group-header",
    children: [
      {
        field: CONSTANT.ADVANCED_REPORT.TABLE_COLUMNS_FIELD_NAMES.FILL_RATE.field,
      },
    ],
  },
];
