import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import logo from "../../assets/images/logo-negative.svg";
import CopyrightIcon from "@mui/icons-material/Copyright";

const stl = {
  card: {
    maxWidth: 600,
    margin: "auto",
    marginTop: "5px",
    paddingBottom: "2px",
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: "2px",
    color: "gray",
  },
  textField: {
    marginLeft: "2px",
    marginRight: "2px",
    width: 100,
  },
  submit: {
    margin: "auto",
    marginBottom: "2px",
  },
  logout: {
    margin: "auto",
    marginBottom: "2px",
    backgroundColor: "#70c6ed",
  },
  descriptionLine: {
    textAlign: "left",
    color: "rgb(1, 45, 76)",
    fontSize: "14px",
    margin: "10px 0px 16px",
    padding: "10px",
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    borderRadius: "5px",
  },
};
const wrapper = {
  backgroundColor: "#001738",
  borderRadius: "8px",
  p: "1rem",
};

const logoContainer = {
  img: {
    width: "100px",
    height: "28px",
  },
};

export default function VersionInfoPopup(props) {
  const reactAppVersion = localStorage.getItem("reactAppVersion");

  return (
    <Box sx={stl.card}>
      <Box sx={wrapper}>
        <Box sx={logoContainer}>
          <img src={logo} alt="logo" />
        </Box>
      </Box>

      <Box sx={{ margin: "25px 0px 10px" }}>
        <Typography sx={{ fontSize: "1rem", fontWeight: 700 }}>
          Bid Enhacement monitoring system <br />
        </Typography>
        <Typography sx={{ fontSize: "0.85rem", marginBottom: "25px" }}>Version: {reactAppVersion}</Typography>
        <Divider />

        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
          <Typography sx={{ position: "relative", fontSize: "0.85rem", paddingLeft: "15px" }}>
            <CopyrightIcon sx={{ position: "absolute", top: "3px", left: "-2px", fontSize: "0.9rem" }} /> 2009-
            {new Date().getFullYear()}
          </Typography>
          <Typography sx={{ fontSize: "0.85rem" }}>INTENT IQ</Typography>
          <Typography sx={{ fontSize: "0.85rem" }}>All rights reserved</Typography>
        </Box>
      </Box>
    </Box>
  );
}
