import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { cardWrapperStl, avatarStl } from "./styles";
import { formatDate } from "../../utils";
import { Button, TextField, ToggleButtonGroup, ToggleButton, styled, Divider, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { colors } from "../../theme";
import { useUpdatePartnerMutation } from "../partnersApi";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";
import { useGetAccountsQuery } from "../accountsApi";
import { useDispatch } from "react-redux";
import { getPartnersList, getPartnersListType } from "../../role/roleSlice";
import auth from "../../auth/auth-helper";
import { ThunkDispatch } from "@reduxjs/toolkit";

const cardItemStl = {
  fontSize: "1rem",
  color: "#506077",
  marginBottom: "1rem",
};

const gridItemStl = {
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: 1,
};

interface PartnerCardData {
  partnerName: string;
  isActive: boolean;
  creationDate: string;
  partnerId: number;
  createdBy: string;
  updatedBy: string;
  pid: string;
  updatedAt: Date;
  isAccountActive: boolean;
  canAdmin: boolean;
}

interface PartnerCardProps {
  d: PartnerCardData;
}

const PartnerCard: React.FC<PartnerCardProps> = ({ d }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState<PartnerCardData>({ ...d });
  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: string) => {
    setFormData({ ...formData, isActive: newStatus === "active" });
  };
  const [loading, setLoading] = useState(false);
  const { refetch } = useGetAccountsQuery();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const [updatePartner, { isLoading, error }] = useUpdatePartnerMutation();
  useEffect(() => {
    setFormData({ ...d });
  }, [isEdit]);

  useEffect(() => {
    setFormData({ ...d });
  }, [d]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "partnerId" ? parseInt(value, 10) : value,
    });
  };

  const jwt = auth.isAuthenticated();

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const updateData = {
        pid: formData.pid,
        pdid: formData.partnerId,
        name: formData.partnerName,
        isActive: formData.isActive,
      };
      const res = (await updatePartner({ pdid: d.partnerId, updateData })) as any;
      if (res?.error) throw error;
      alert("Partner updated successfully");
      setIsEdit(false);
      setLoading(false);
      refetch();
      dispatch(
        getPartnersListType({
          id: jwt.data.user.id,
          token: jwt.data.accessToken,
        }),
      );
    } catch (err) {
      alert("Failed to update partner: " + err);
      setLoading(false);
    }
  };

  const toggleEdit = () => setIsEdit(!isEdit);

  return (
    <Box
      sx={{
        ...cardWrapperStl,
        padding: "2rem",
        position: "relative",
        boxShadow: "0px 12px 20px 0px rgba(0, 0, 0, 0.1)",
        display: "flex",
        //flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box sx={{ fontSize: "1rem", fontWeight: 600, top: "1rem", left: "20px" }}>
        <Avatar
          sx={{
            ...avatarStl,
            width: 35,
            fontSize: "0.75rem",
            height: 35,
            backgroundColor: "#bebebe",
          }}
        >
          {d.partnerName[0] ?? ""}
        </Avatar>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>
        <Box sx={{ display: "flex", marginBottom: "1rem", justifyContent: "space-between" }}>
          <Box sx={{ fontSize: "1.5rem", fontWeight: 600, display: "flex", alignItems: "center" }}>
            <Box>
              <Box sx={{ marginRight: "16px" }}>{d.partnerName}</Box>
            </Box>

            <ToggleButtonGroup
              value={formData.isActive ? "active" : "inactive"}
              exclusive
              onChange={handleStatusChange}
              aria-label="Status toggle"
              disabled={!isEdit}
            >
              <ToggleButton
                value="active"
                aria-label="active"
                sx={{
                  textTransform: "none",
                  fontSize: "0.875rem",
                  padding: "4px 10px",
                  "&.Mui-selected": {
                    backgroundColor: formData.isActive ? colors.secGreen5 : "#f0f0f0",
                    color: formData.isActive ? colors.secGreen100 : "#f0f0f0",
                  },
                  "&:hover": {
                    backgroundColor: formData.isActive ? colors.secGreen5 : "#f0f0f0",
                  },
                }}
              >
                Active
              </ToggleButton>
              <ToggleButton
                value="inactive"
                aria-label="inactive"
                sx={{
                  textTransform: "none",
                  fontSize: "0.875rem",
                  padding: "4px 10px",
                  "&.Mui-selected": {
                    backgroundColor: !formData.isActive ? colors.secRed10 : "#f0f0f0",
                    color: !formData.isActive ? colors.secRed100 : "#f0f0f0",
                  },
                  "&:hover": {
                    backgroundColor: !formData.isActive ? colors.secRed10 : "#f0f0f0",
                  },
                }}
              >
                Inactive
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {d.isAccountActive && d.canAdmin && (
            <Button startIcon={<EditIcon />} sx={{ minWidth: "20px" }} onClick={toggleEdit}></Button>
          )}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 1 }}>
          <Box>
            <Typography variant="body2XSmallMain600" textAlign={"left"}>
              {"Date of creation:  "}
            </Typography>
            <Typography variant="body2XSmallMain400" textAlign={"left"}>
              {formData.createdBy} | {formatDate(new Date(formData.creationDate)).fullFormat}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2XSmallMain600" textAlign={"left"}>
              {" Last modify:  "}
            </Typography>
            <Typography variant="body2XSmallMain400" textAlign={"left"}>
              {formData.updatedBy || "--"} |{" "}
              {formData.updatedAt ? formatDate(new Date(formData.updatedAt)).fullFormat : "--"}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ margin: "16px 0 16px 0" }}></Divider>

        {isEdit ? (
          !loading ? (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", gap: 1 }}>
              <Grid container sx={cardItemStl} spacing={1}>
                <Grid item sx={gridItemStl} xs={6}>
                  <Typography variant="body2XSmallMain600" textAlign={"left"}>
                    Partner name
                  </Typography>
                  <TextField
                    name="partnerName"
                    value={formData.partnerName}
                    onChange={handleInputChange}
                    size="small"
                  />
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} xs={6}>
                  <Button variant="contained" onClick={handleUpdate}>
                    Save changes
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <LoaderWithProgress
              sx={{
                display: "flex",
                width: "60px",
                maxHeight: "60px",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )
        ) : (
          <>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2XSmallMain600"> {"Partner id: "} </Typography>
                <Typography variant="body2XSmallMain400"> {formData.partnerId}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2XSmallMain600"> {"Pricing plan: "}</Typography>
                <Typography variant="body2XSmallMain400"> {"---"} </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2XSmallMain600"> {"Created by: "}</Typography>
                <Typography variant="body2XSmallMain400"> {formData.createdBy}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2XSmallMain600"> {"Integration type: "} </Typography>
                <Typography variant="body2XSmallMain400"> {"---"} </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PartnerCard;
