import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Box, DialogTitle, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setAvatrDialogOpen, setEditedUser } from "../users/usersSlice";
import { CONSTANT } from "../../constants/constants";

export interface AvatarItem {
  id: string;
  src: string;
  label: string;
}

const numberOfDefaultAvatars = 14;
const getAvatars = (): AvatarItem[] => {
  const res: AvatarItem[] = [];
  for (let index = 0; index <= numberOfDefaultAvatars; index++) {
    const av: AvatarItem = {
      id: `default-${index}`,
      src: `${CONSTANT.avatarUrlS3BucketPrefix}-${index}.svg`,
      label: `Default Avatar ${index}`,
    };
    res.push(av);
  }
  return res;
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "72px",
  height: "72px",
  padding: 0,
  cursor: "pointer",
  border: `2px solid ${theme.palette.background.paper}`,
  "&.selected": {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  "&:hover": {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));

interface SelectAvatarDialogProps {
  open: boolean;
  onClose: () => void;
  onSelectAvatar: (avatar: AvatarItem) => void;
  avatarUrl: string;
}

const SelectAvatarDialog: React.FC<SelectAvatarDialogProps> = ({ onSelectAvatar, avatarUrl }) => {
  const dispatch = useDispatch();
  const avatars = getAvatars();
  const [selectedAvatar, setSelectedAvatar] = React.useState<AvatarItem | null>(null);
  const { editedUserAvatarUrl, editedUser } = useSelector((state: any) => state.users);
  const handleAvatarClick = (avatar: AvatarItem) => {
    setSelectedAvatar(avatar);
  };
  const handleSaveAvatar = () => {
    if (selectedAvatar) {
      dispatch(setEditedUser({ ...editedUser, avatarUrl: selectedAvatar.src }));
      dispatch(setAvatrDialogOpen(false));
    }
  };

  return (
    <Box
      sx={{
        padding: "14px",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", padding: 0, width: "100%", alignItems: "center" }}
      >
        <Typography variant="h1XLargeDark800">Select avatar</Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            dispatch(setAvatrDialogOpen(false));
          }}
          sx={{
            position: "relative",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Avatar
        src={selectedAvatar?.src || editedUserAvatarUrl || avatarUrl || CONSTANT.defaultAvatarUrl}
        sx={{ width: "116px", height: "116px" }}
      ></Avatar>
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          spacing={1}
          sx={{
            overflow: "hidden",
            width: "100%",
            gap: "0px",
            // Ensure the container fits exactly 5 columns
            gridTemplateColumns: "repeat(5, 1fr)",
          }}
        >
          {avatars.map((avatar) => (
            <Grid
              item
              key={avatar.id}
              xs={2.4} // Use 2.4 to represent 1/5th (100%/5) of the container's width, effectively creating 5 columns
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
              }}
            >
              <StyledAvatar
                src={avatar.src}
                alt={avatar.label}
                onClick={() => handleAvatarClick(avatar)}
                className={avatar.id === selectedAvatar?.id ? "selected" : ""}
              />
            </Grid>
          ))}
        </Grid>
        <Button onClick={handleSaveAvatar} variant="contained">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default SelectAvatarDialog;
