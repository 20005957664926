import { useState, useEffect, useRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridColumnMenu, GridToolbarColumnsButton } from "@mui/x-data-grid";
import { CustomPagination } from "./pagination/CustomPagination";
import { mainTableStyle, columnTablePanelStyle } from "./mainTableStyle";
import CheckboxWrapper from "../../../../components/CustomCheckbox/Checkbox";
import { CustomSummary } from "./CustomSummary";
import ExportMenu from "./exportCsv/ExportMenu";
import { singleBtn } from "../styles";
import ColumnsIcon from "../../../../components/IconComponents/ColumnsIcon";
import { columnGroupingModel } from "./tableColumnsModel";
import CustomGridColumnsPanel from "./customGridColumnsPanel/CustomGridColumnsPanel";
import NotificationBadge from "./sortingNotification/NotificationBadge";
import { tablesRowsAmountPerPageOptions } from "../../../../parametersConstancy/parametersConstancyTypes";
import { setCurrentPlatformParameter } from "../../../../parametersConstancy/platformParametersSlice";
import { setParametersConstancyWrapper } from "../../../../parametersConstancy/parametersConstancyUtils";

export function CustomColumnMenuComponent(props) {
  const { hideMenu, colDef, color, ...other } = props;

  return (
    <GridColumnMenu
      sx={{
        // padding: "1rem",
        borderRadius: "1.5rem",
        color: "#001738",
        fontSize: "0.9rem",
        boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.1)",
        "& svg": {
          fill: "#1BB2FF",
        },
      }}
      hideMenu={hideMenu}
      colDef={colDef}
      {...other}
    />
  );
}

function CustomToolbar(props) {
  let { setFilterButtonEl, ...rest } = props; //removing non-existent prop of GridToolbarContainer (removing console warnings)
  const { filters } = useSelector((state) => state.advancedReport);
  const activeFilters = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values.filter((unit) => unit.isSelected)]),
  );
  return (
    <GridToolbarContainer {...rest} id="theExportToXLSBtn">
      <GridToolbarColumnsButton
        startIcon={null}
        sx={{
          ...singleBtn,
        }}
        endIcon={<ColumnsIcon />}
        ref={props.setFilterButtonEl}
      />
      <ExportMenu activeFilters={activeFilters} hCols={props.hc} />
    </GridToolbarContainer>
  );
}

function MainTable1(props, ref) {
  const dispatch = useDispatch();
  let { columnsModel, activeTimeGrouping, paginationDisabled } = props;
  const { filters, mainTableUtils } = useSelector((state) => state.advancedReport);
  const columnVisibilityModel = useSelector(
    (state) =>
      state.platformParameters.currentPlatformParameters.performanceReportParameters.columnVisibilityModel ?? {},
  );
  // const activeFilters = Object.fromEntries(
  //   Object.entries(filters).map(([key, values]) => [key, values.filter((unit) => unit.isSelected)]),
  // );
  let { rows2, summaryRows } = mainTableUtils;
  const [open, setOpen] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState(
    Object.keys(columnVisibilityModel).length > 0 ? columnVisibilityModel : props.hiddenColumns,
  );
  const initialRender = useRef(true);
  //const summaryRowsRef = useRef(summaryRows);
  const conbinedDataRef = useRef([]);

  useEffect(() => {
    return () => {
      saveToSliceHiddenColumnsModel();
    };
  }, [hiddenColumns]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    //summaryRowsRef.current = summaryRows;
    return () => {};
  }, [props.pct, props.currency, hiddenColumns]);

  const saveToSliceHiddenColumnsModel = () => {
    props.setHC(hiddenColumns);
  };

  // let rowsAmountPerPage = [25, 50, 100];
  // if (rows2.length < MAX_ROWS_PER_PAGE) rowsAmountPerPage.push({ label: "All", value: rows2.length });
  //is is not rerendering

  function CustomFooter(p) {
    let { summaryRowsT, hc: hiddenColumns } = p;

    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", position: "relative" }}>
        {!paginationDisabled && (
          <CustomPagination rows={rows2} rowsPerPageOptions={tablesRowsAmountPerPageOptions} />
        )}
      </Box>
    );
  }

  return (
    <>
      <Box
        id="mainTableWrapper"
        sx={{
          background: "#FFFFFF",
          borderRight: "1px solid #E0E2E7",
          borderLeft: "1px solid #E0E2E7",
          borderBottom: "1px solid #E0E2E7",
          boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
          borderRadius: "0 0 16px 16px",
        }}
      >
        <Box
          id="innerMainTableBox"
          sx={{
            ...mainTableStyle("baseline", true),
            height: "auto",
            position: "relative",
          }}
        >
          {
            <NotificationBadge
              selectedCellName={props.selectedCellName}
              filterTerms={props.filterTerms}
              resetSort={props.resetSort}
              resetColumnFilter={props.resetColumnFilter}
            />
          }
          <DataGrid
            //onRowClick={handleRowClick}
            // autoHeight={rows2.length < 25}
            autoHeight={true}
            rowHeight={36}
            rows={rows2}
            // sx={{ display: "flex", flexDirection: "column-reverse" }}
            columns={props.columnsModel} //represent column name and key to use
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
            }}
            // sortingOrder={["desc", "asc"]}
            sortModel={props.sortModel}
            onSortModelChange={props.handleSort}
            getCellClassName={(params) => {
              if (props.selectedCell) {
                return props.selectedCell && props.selectedCell.field === params.field
                  ? "highlight-cell"
                  : "highlight-cell-b";
              } else {
                return "";
              }
            }}
            filterModel={props.filterModel}
            onFilterModelChange={props.handleColumnFilter}
            initialState={{
              columns: {
                // dont show columns which have been hidden by user, the other columns will remain visible
                columnVisibilityModel: {
                  ...(Object.keys(columnVisibilityModel).length > 0 ? columnVisibilityModel : props.hiddenColumns),
                },
              },
              sorting: {
                sortModel: [{ field: "submitted_date", sort: "desc" }],
              },

              // pagination: paginationDisabled
              //   ? paginationDisabled
              //   : {
              //       paginationModel: {
              //         pageSize: tablesRowsAmountPerPageOptions[0],
              //       },
              //     },
            }}
            onStateChange={(state) => {
              let hiddenColumns1 = state.columns.columnVisibilityModel;
              // if (Object.keys(hiddenColumns1).length > 0) {
              setHiddenColumns(hiddenColumns1);
              ref.current = hiddenColumns1;
              // }
            }}
            slots={{
              columnMenu: CustomColumnMenuComponent,
              baseCheckbox: CheckboxWrapper,
              toolbar: CustomToolbar,
              footer: CustomFooter,
              loadingOverlay: (props) => <CustomSummary {...props} columnsModel={columnsModel} />,
              columnsPanel: CustomGridColumnsPanel,
            }}
            // localeText={{
            //   toolbarColumns: "Table columns",
            // }}
            loading
            slotProps={{
              baseIconButton: {
                title: "",
              },
              columnMenu: { background: "#fff" },
              panel: {
                anchorEl: filterButtonEl,
                placement: "bottom-start",
                sx: columnTablePanelStyle,
              },
              footer: { summaryRowsT: summaryRows, hc: hiddenColumns },
              loadingOverlay: {
                summaryRowsT: summaryRows,
                hc: hiddenColumns,
                // makes no effect
                //sx: { position: "sticky !important", top: 0, zIndex: -1, pointerEvents: "none" },
              },
              toolbar: { colmod: columnsModel, setFilterButtonEl, hc: hiddenColumns },
              columnsPanel: {
                // colmod: columnsModel,
              },
            }}
            onColumnVisibilityModelChange={(props) => {
              dispatch(
                setCurrentPlatformParameter(
                  setParametersConstancyWrapper("performanceReportParameters", "columnVisibilityModel", props),
                ),
              );
              // setHiddenColumns(props);
            }}
            hideFooterPagination={false}
            // pageSizeOptions={[5, 10, 25]}
            checkboxSelection={false}
            // disableColumnSelector
            disableRowSelectionOnClick
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel}
            // onPaginationModelChange={(props) => {
            //   dispatch(
            //     setCurrentCommonParameter(
            //       setCurrentCommonParameterWrapper("tablesRowsAmountPerPage", props.pageSize),
            //     ),
            //   );
            // }}
          />
        </Box>
      </Box>
    </>
  );
}

const MainTable = forwardRef(MainTable1);
export default MainTable;
