export const link = {
  display: "flex",
  alignItems: "cover",
  cursor: "pointer",
  borderRadius: "30px",
  "&:hover": {
    backgroundColor: "#e8f5fe",
    color: "#50b7f5",
    transition: "color 100ms ease-out",
  },
  "& > h2": {
    fontWeight: 700,
    fontSize: "20px",
    marginRight: "20px",
  },
  "& .MuiSvgIcon-root": {
    padding: "20px",
  },
};

export const sideBarContainer = (isShown) => {
  return {
    width: isShown ? "280.93px" : "65px",
    minWidth: isShown ? "280.93px" : "65px",
    transition: "150ms",
  };
};

export const sideBarExpandButton = (isShown) => {
  return {
    width: "1.5625rem",
    height: "1.5625rem",
    backgroundColor: "#fff",
    borderRadius: "50%",
    position: "absolute",
    top: "0px",
    right: "-10px",
    transition: "150ms",
    boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.65)",
    zIndex: 1500,
    cursor: "pointer",
    "& #sidebar-toggler-btn": {
      transform: isShown ? "rotate(-90deg)" : "rotate(90deg)",
    },
  };
};

export const menuItemActive = {
  color: "#fff",
  fontWeight: 500,
  // color: 'text.lightGrey',
  borderLeft: 5,
  borderColor: "#1BB2FF",
  boxShadow: "-3px 0 0 lighblue",

  svg: {
    path: {
      fill: (theme) => "#1BB2FF",
      fillOpacity: 1,
    },
  },
};

export const menuItemActiveCollapsed = (isOnTop) => {
  return {
    color: "#fff",
    fontWeight: 500,
    // color: 'text.lightGrey',
    borderLeft: isOnTop ? null : 3,
    borderColor: "#1BB2FF",
    // boxShadow: " 0 0 6px hsl(210 4% 61%)",
    svg: {
      path: {
        fill: (theme) => "#1BB2FF",
        fillOpacity: 1,
      },
    },
  };
};

export const menuItem = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  // paddingLeft: "69px",
  textIndent: "3.3125rem",
  marginBottom: "6px",
  marginLeft: "5px",
  color: "rgba(255,255,255,0.5)",
  height: "4.375rem",
  fontSize: "1.25rem",
  borderLeft: 5,
  borderColor: "transparent",
  svg: {
    path: {
      fill: (theme) => "#fff",
      fillOpacity: 0.5,
      // fill: (theme) => theme.palette.primary.main,
    },
  },
  "&:hover": {
    transition: "color 500ms ease-out",
    color: "rgba(255,255,255,1)",
    svg: {
      path: {
        fill: (theme) => "#fff",
        fillOpacity: 1,
      },
    },
  },
};

export const menuItemCollapsed = (isOnTop = false) => {
  return {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textIndent: null,
    marginBottom: isOnTop ? null : "1rem",
    marginLeft: isOnTop ? "1rem" : "1px",
    marginRight: isOnTop ? "1rem" : null,
    color: "rgba(255,255,255,0.5)",
    height: "4.375rem",
    fontSize: "1.2rem",
    borderLeft: 3,
    borderColor: "transparent",
    svg: {
      path: {
        fill: (theme) => "#fff",
        fillOpacity: 0.5,
        // fill: (theme) => theme.palette.primary.main,
      },
    },
    "&:hover": {
      transition: "color 500ms ease-out",
      color: "rgba(255,255,255,1)",
      svg: {
        path: {
          fill: (theme) => "#fff",
          fillOpacity: 1,
        },
      },
    },
  };
};

export const logoContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "3rem 0px",
  img: {
    width: "9.28rem",
    height: "2.29rem",
  },
};

export const wrapperMenuItemTop = {
  width: "100%",
  backgroundColor: "#001738",
  borderBottomLeftRadius: "1.5625rem",
  borderBottomRightRadius: "1.5625rem",
  paddingLeft: "40px",
  fontSize: "20px",
  color: "#fff",
};

export const innerMenuItemTop = {
  background: "#001738",
  padding: "1rem",
  color: "#fff",
  cursor: "pointer",
};

export const iconSx = (sideBarIsOpen) => ({
  left: sideBarIsOpen ? "-2.125rem" : "-3.125rem",
  position: "absolute",
  //top: "30%",
});
