import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { singleBtn, activeBtn, stylesWidget } from "../../core/overview/styles";
import { useSendRequests } from "../../core/overview/helpers/useSendRequests";
import auth from "../../auth/auth-helper";
import { setDays, prepareDateFormat } from "../../utils";
import { resetSavedCustomRawDataState } from "../../savedRawData/savedRawDataSlice";
import { resetRawPctAndRawCurrencyIndicators, resetSummaryCompleteDate } from "../../report/reportSlice";
import { getReportByPlacement } from "../../report/reportThunks/getReportByPlacement";
import { getReportPreviousPeriodByPlacement } from "../../report/reportThunks/getReportPreviousPeriodByPlacement";
import { getSummaryReport } from "../../summary/summaryThunks/getSummaryReport";
import { WrapperMonthPicker } from "./WrapperMonthPicker";
import { MonthPicker } from "./MonthPicker";
import { resetSavedRawDataState } from "../../savedRawData/savedRawDataSlice";
import { resetSavedRawDataPreviousPeriodState } from "../../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import { resetSummaryChartRecords } from "../../summary/summarySlice";
import {
  resetMainTableData,
  resetTimeRangeOnly,
  resetVRTData,
} from "../../core/performanceReports/advancedReport/advancedReportSlice";
import { handleReset, monthPickChange } from "./monthPicker-utils";
import { CONSTANT } from "../../constants/constants";
import { validateSelection } from "./datePickerValidator";
import { setCurrentCommonParameter } from "../../parametersConstancy/platformParametersSlice";
import { setCurrentCommonParameterWrapper } from "../../parametersConstancy/parametersConstancyUtils";
import { BasicTooltip } from "../BasicTooltip/BasicTooltip";

const styleDatePicker = {
  position: "absolute",
  top: "50px",
  left: "-85px",
  zIndex: 10000,
  boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.1)",
};

const styleDatePickerBG = {
  position: "absolute",
  top: "51px",
  left: "-890px",
  zIndex: 9,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(67, 138, 198, 0.09)",
  backdropFilter: "blur(3px)",
};

export const adjustForTimezone = (dateString) => {
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export default function DatePicker(props) {
  const { filterBase, summaryCompleteData } = useSelector((state) => state.report);
  const active_range_date = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange.value,
  );
  const dateGroupingMode = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode,
  );
  const customDateRange = useSelector(
    (state) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange ?? {
        customDateStart: null,
        customDateEnd: null,
      },
  );

  const defaultEndDate = new Date();
  const defaultStartDate = setDays(defaultEndDate, -2);
  const contRef = useRef(null);
  const refModal = useRef(null);
  const selectInnerRef = useRef(dateGroupingMode);
  const selectSliceRef = useRef(dateGroupingMode);
  const warnRef = useRef(false);
  const [warnMessage, setWarnMessage] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [showWarn, setShowWarn] = useState(false);
  const [dateGroupingModeState, setGroupingMode] = useState(dateGroupingMode);
  const [dateRange, setDateRange] = useState([
    {
      startDate: !!customDateRange.customDateStart
        ? adjustForTimezone(customDateRange.customDateStart)
        : defaultStartDate,
      endDate: !!customDateRange.customDateEnd ? adjustForTimezone(customDateRange.customDateEnd) : defaultEndDate,
      key: "selection",
    },
  ]);
  const [checked, setChecked] = useState(true);
  const [reset, setReset] = useState(true);
  const savedRawData = useSelector((state) => state.savedRawData[-1]);
  const dispatch = useDispatch();
  const [request] = useSendRequests();

  useEffect(() => {
    /**
     * close datepicker if clicked on outside of element (on blured bg)
     */

    const handleClickOutside = (event) => {
      if (warnRef.current) {
        setShowWarn(false);
        warnRef.current = false;
      }
      if (
        refModal.current &&
        (!refModal.current.contains(event.target) || !contRef.current.contains(event.target))
      ) {
        if (selectInnerRef.current !== selectSliceRef.current) setGroupingMode(selectSliceRef.current);
        setOpenDate(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refModal]);

  useEffect(() => {
    selectInnerRef.current = dateGroupingMode;
    selectSliceRef.current = dateGroupingMode;
    setGroupingMode(dateGroupingMode);
  }, [dateGroupingMode]);

  const handleOpenClick = (savedDatesRange, savedRawData, filterBase) => {
    setOpenDate(!openDate);
    //if custom date is active - don't reload chart
    if (active_range_date === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) return;

    const abortController = new AbortController();
    const signal = abortController.signal;
    const jwt = auth.isAuthenticated();

    const { startDate, endDate } = dateRange[0];

    let customDateRange = {
      customDateStart: prepareDateFormat(startDate),
      customDateEnd: prepareDateFormat(endDate),
    };

    if (
      savedDatesRange.customDateStart === customDateRange.customDateStart &&
      savedDatesRange.customDateEnd === customDateRange.customDateEnd
    ) {
      // GET DATA FROM SAVED SLICE ,
      // ELSE SHOW NOTHING - wait for "Apply" click to make new request with SKIP SEARCHING IN SAVED DATA
      // filterBase
      let res = [];
      for (let i in savedRawData) {
        if (savedRawData[i].length) {
          res.push(i);
        }
      }

      if (res.length > 0 && res.includes(filterBase)) {
        //do internal search
        if (filterBase === "placement_id") {
          let payload = {
            id: jwt.data.user.id,
            signal,
            token: jwt.data.accessToken,
            rt: CONSTANT.DATE_RANGE_TYPES.CUSTOM.value,
            // filterBase: filterBase,
            skipSetFilterBaseAsActive: true,
            customDateRange,
          };
          dispatch(getSummaryReport(payload)); // as SUMMARY CHART
          dispatch(getReportByPlacement(payload));
          dispatch(getReportPreviousPeriodByPlacement(payload));
        } else {
          request(jwt, signal, CONSTANT.DATE_RANGE_TYPES.CUSTOM.value, props.filterBase, true, customDateRange);
        }
      } else {
        //nothing to declare
        return;
      }
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleApplyClick = () => {
    let { isValid, warnMessage } = validateSelection(dateGroupingModeState, dateRange);
    setWarnMessage(warnMessage);
    if (!isValid) {
      warnRef.current = true;
      setShowWarn(true);
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    const jwt = auth.isAuthenticated();

    setOpenDate(false);
    const { startDate, endDate } = dateRange[0];

    let customDateRange = {
      customDateStart: prepareDateFormat(startDate),
      customDateEnd: prepareDateFormat(endDate),
    };

    dispatch(
      setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateRange", CONSTANT.DATE_RANGE_TYPES.CUSTOM)),
    );
    dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("customDateRange", customDateRange)));

    if (Object.keys(summaryCompleteData).length) {
      dispatch(resetSummaryCompleteDate());
    }
    dispatch(resetSavedCustomRawDataState());
    dispatch(resetRawPctAndRawCurrencyIndicators());
    dispatch(resetMainTableData());
    dispatch(resetVRTData());

    if (dateGroupingModeState !== dateGroupingMode) {
      // apply time group mode if it was changed
      dispatch(
        setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateGroupingMode", dateGroupingModeState)),
      );

      //and reset all saved raw datas
      dispatch(resetSummaryCompleteDate());
      dispatch(resetSavedRawDataState());
      dispatch(resetSavedRawDataPreviousPeriodState());
      dispatch(resetSummaryChartRecords());
      dispatch(resetTimeRangeOnly());
    }

    // "Apply" click - make new request with SKIP SEARCHING IN SAVED DATA and pass to slice 'skipInternalSearch=true'
    if (filterBase === "placement_id") {
      let payload = {
        id: jwt.data.user.id,
        signal,
        token: jwt.data.accessToken,
        rt: CONSTANT.DATE_RANGE_TYPES.CUSTOM.value,
        skipSetFilterBaseAsActive: true,
        customDateRange,
        skipInternalSearch: true,
      };
      dispatch(getSummaryReport(payload)); // as SUMMARY CHART
      dispatch(getReportByPlacement(payload));
      dispatch(getReportPreviousPeriodByPlacement(payload));
    } else {
      request(jwt, signal, -1, props.filterBase, true, customDateRange, true);
    }
  };

  return (
    <div className="lsItem" style={{ position: "relative" }}>
      {/* <label>Custom Date</label> */}
      <BasicTooltip tooltipText="Choose a date range…">
        <Button
          style={props.isActiveButton ? activeBtn : null}
          disabled={props.isDisabled}
          data-testid="timeRangeCustom"
          sx={singleBtn}
          onClick={() => handleOpenClick(customDateRange, savedRawData, filterBase)}
        >
          Custom
          {/* {`${dateRange[0].startDate} to ${dateRange[0].endDate}`} */}
        </Button>
      </BasicTooltip>
      {openDate && (
        <div
          //  style={styleDatePickerBG}
          ref={contRef}
        >
          <div
            style={{ ...styleDatePicker, width: checked ? "615px" : 1140, backgroundColor: "#fff" }}
            ref={refModal}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "330px" }}>
                <MonthPicker
                  className="MonthPicker"
                  initialRange={{
                    // start: { year: 2023, month: 4 },
                    // end: { year: 2023, month: 6 }
                    start: {
                      year: null,
                      month: null,
                    },
                    end: {
                      year: null,
                      month: null,
                    },
                  }}
                  onChange={(selectedRange) => {
                    monthPickChange(selectedRange, setDateRange);
                  }}
                  onReset={reset}
                  darkMode={false}
                />
              </div>
              <WrapperMonthPicker>
                <DateRange
                  maxDate={new Date()}
                  editableDateInputs={true}
                  onChange={(item) => {
                    handleReset(setReset);
                    setDateRange([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  direction={"horizontal"}
                  months={checked ? 1 : 2}
                />
              </WrapperMonthPicker>
            </div>

            <div
              style={{
                textAlign: "center",
                background: "#fff",
                paddingBottom: "12px",
              }}
            >
              <Button type="button" onClick={() => handleReset(setReset)} sx={{ marginRight: "2rem" }}>
                Reset months
              </Button>
              <Button
                sx={{ ...singleBtn, width: "3rem", padding: 0, color: "#1BB2FF" }}
                type="button"
                className="btn"
                onClick={() => setOpenDate(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{ ...singleBtn, width: "3rem", padding: 0, color: "#1BB2FF" }}
                type="button"
                className="btn"
                onClick={handleApplyClick}
              >
                Apply
              </Button>
              {/* <Box sx={stylesWidget.checkboxWrapper}> */}
              <Tooltip
                title={
                  <Box style={{ fontSize: "16px" }}>{checked ? "Expand calendar" : "Show only one month"}</Box>
                }
              >
                <Checkbox
                  sx={stylesWidget.tickSize}
                  // disabled={matches.length == 0}
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Tooltip>
              {/* </Box> */}
            </div>
            {showWarn ? (
              <Fade
                in={true}
                // style={{ transformOrigin: '0 0 0' }}
                timeout={1300}
              >
                <Box
                  sx={{
                    padding: "15px",
                    fontSize: "1rem",
                    color: "coral",
                    width: "75%",
                  }}
                >
                  {warnMessage}
                </Box>
              </Fade>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
