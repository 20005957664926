import { OverviewIcon, OverviewIconHeader } from "../IconComponents/OverviewIcon";
import { AdvancedReportIcon, AdvancedReportIconHeader } from "../IconComponents/AdvancedReportIcon";
import { SettingsIcon } from "../IconComponents/SettingsIcon";
import BillingIcon from "../IconComponents/BillingIcon";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import CircleIcon from "@mui/icons-material/Circle";
import {
  AccountsIcon,
  AccountsIconHeader,
  RolesManagementIconHeader,
  UsersManagementIconHeader,
} from "../IconComponents/AccountsIcon";
import { CONSTANT } from "../../constants/constants.js";
import auth from "../../auth/auth-helper.js";
import NotificationsIcon from "../IconComponents/NotificationsIcon";

export const getSidebarData = () => [
  {
    title: "POC Portal",
    path: process.env.REACT_APP_EVERAFTER_LINK || "https://app.everafter.ai/login",
    pathArr: [process.env.REACT_APP_EVERAFTER_LINK || "https://app.everafter.ai/login"],
    icon: <RocketLaunchIcon />,
    iconHeader: <RocketLaunchIcon />,
    style: "test.style1",
    isDisabled: true,
    hideHeaderContent: false,
  },
  {
    title: "Overview",
    path: CONSTANT.PAGES.OVERVIEW.path,
    pathArr: [CONSTANT.PAGES.OVERVIEW.path],
    icon: <OverviewIcon />,
    iconHeader: <OverviewIconHeader />,
    style: "test.style1",
    isDisabled: false, //!auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.OVERVIEW.modules),
    hideHeaderContent: false,
  },
  {
    title: "Reports",
    path: CONSTANT.PAGES.PERFORMANCE_REPORT.path,
    pathArr: [CONSTANT.PAGES.PERFORMANCE_REPORT.path, CONSTANT.PAGES.VISITOR_RECOGNITION.path],
    icon: <AdvancedReportIcon />,
    iconHeader: <AdvancedReportIcon />,
    style: "test.style1",
    isDisabled: false, //!auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.PERFORMANCE_REPORT.modules),
    hideHeaderContent: false,
    children: [
      {
        title: "Performance Report",
        path: CONSTANT.PAGES.PERFORMANCE_REPORT.path,
        pathArr: [CONSTANT.PAGES.PERFORMANCE_REPORT.path],
        icon: <CircleIcon sx={{ fontSize: "10px" }} />,
        iconHeader: <AdvancedReportIconHeader />,
        style: "test.style1",
        isDisabled: false, //!auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.PERFORMANCE_REPORT.modules),
        hideHeaderContent: false,
      },
      {
        title: "Visitor Recognition",
        path: CONSTANT.PAGES.VISITOR_RECOGNITION.path,
        pathArr: [CONSTANT.PAGES.VISITOR_RECOGNITION.path],
        icon: <CircleIcon sx={{ fontSize: "10px" }} />,
        iconHeader: <AdvancedReportIconHeader />,
        style: "test.style1",
        isDisabled: false, //!auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.VISITOR_RECOGNITION.modules),
        hideHeaderContent: false,
      },
    ],
  },
  {
    title: "Accounts and Users",
    path: CONSTANT.PAGES.ACCOUNTS.path,
    pathArr: [
      CONSTANT.PAGES.ACCOUNTS.path,
      "/accounts/user_details",
      CONSTANT.PAGES.USERS.path,
      CONSTANT.PAGES.ROLES.path,
    ],
    icon: <AccountsIcon />,
    iconHeader: <AccountsIconHeader />,
    style: "test.style1",
    isDisabled: false, //!auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.ACCOUNTS.modules),
    hideHeaderContent: true,
    children: [
      {
        title: "Accounts",
        path: CONSTANT.PAGES.ACCOUNTS.path,
        pathArr: [CONSTANT.PAGES.ACCOUNTS.path],
        icon: <CircleIcon sx={{ fontSize: "10px" }} />,
        iconHeader: <AccountsIconHeader />,
        style: "test.style1",
        isDisabled: false, //!auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.ACCOUNTS.modules),
        hideHeaderContent: true,
      },
      {
        title: "Users management",
        path: CONSTANT.PAGES.USERS.path,
        pathArr: [CONSTANT.PAGES.USERS.path],
        icon: <CircleIcon sx={{ fontSize: "10px" }} />,
        iconHeader: <UsersManagementIconHeader />,
        style: "test.style1",
        //FIXME
        isDisabled: false,
        // !(
        //   // auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.USERS.modules) &&
        //   (auth.isAuthenticated().data?.user.acctId === CONSTANT.IIQ_)
        // ),
        shouldBeVisibleFor: [CONSTANT.IIQ_],
        hideHeaderContent: true,
      },
      {
        title: "Roles management",
        path: CONSTANT.PAGES.ROLES.path,
        pathArr: [CONSTANT.PAGES.ROLES.path],
        icon: <CircleIcon sx={{ fontSize: "10px" }} />,
        iconHeader: <RolesManagementIconHeader />,
        style: "test.style1",
        isDisabled: false, //!auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.ROLES.modules),
        hideHeaderContent: true,
      },
    ],
  },
  {
    title: "Billing",
    path: CONSTANT.PAGES.BILLING.path,
    pathArr: [CONSTANT.PAGES.BILLING.path],
    icon: <BillingIcon />,
    iconHeader: <AccountBalanceIcon fontSize="large" />,
    style: "test.style1",
    //FIXME compare with IIQ_ need to be deleted
    isDisabled: !(
      // auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.BILLING.modules) &&
      (auth.isAuthenticated().data?.user.acctId === CONSTANT.IIQ_)
    ),
    hideHeaderContent: true,
  },
  {
    title: "Notifications",
    path: CONSTANT.PAGES.NOTIFICATIONS.path,
    pathArr: [CONSTANT.PAGES.NOTIFICATIONS.path],
    icon: <NotificationsIcon />,
    iconHeader: <NotificationsIcon fontSize="large" />,
    style: "test.style1",
    //FIXME compare with IIQ_ need to be deleted
    isDisabled: !(
      // auth.checkmodulesVisibility(CONSTANT.MODULES_PAGES.BILLING.modules) &&
      (auth.isAuthenticated().data?.user.acctId === CONSTANT.IIQ_)
    ),
    hideHeaderContent: true,
  },
  {
    title: "Supply management",
    path: CONSTANT.PAGES.SUPPLY.path,
    pathArr: [CONSTANT.PAGES.SUPPLY.path],
    icon: <SettingsIcon />,
    iconHeader: <OverviewIconHeader />,
    style: "test.style1",
    isDisabled: true,
    hideHeaderContent: true,
  },
  {
    title: "Integration",
    path: CONSTANT.PAGES.INTEGRATION.path,
    pathArr: [CONSTANT.PAGES.INTEGRATION.path],
    icon: null,
    iconHeader: null,
    style: "test.style1",
    isDisabled: true,
    hideHeaderContent: true,
  },
];
