import { alpha, Box, Button, MenuItem, Modal, Select, SxProps, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { feedBackBtnStlExpanded, stlBtn } from "./feedbackStyle";
import ConfirmSend from "./ConfirmSend";
import { sendFeedback } from "./feedback-api";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import LoaderWithProgress from "../LoaderWithProgress/LoaderWithProgress";
import UploadForm from "./UploadForm";
import { UploadFile } from "antd";
import { colors } from "../../theme";

const styleModalForm: SxProps = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: alpha(`${colors.mainWhite}`, 1),
  border: "none",
  textAlign: "center",
  boxShadow: 24,
  borderRadius: "8px",
  padding: "3rem 5rem",
};
interface FeedbackFormProps {
  feedbackOpen: boolean;
  setFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const titleFeebackForm: string =
  "Welcome to IntentIQ Support page, please submit your ticket and our Support team will reach out shortly";
const menuItems: string[] = ["None", "Technical issue", "Account configuration", "Reporting"];
const FeedbackForm: React.FC<FeedbackFormProps> = ({ feedbackOpen, setFeedbackOpen }) => {
  const [message, setMessage] = useState<string>("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [topic, setTopic] = useState<string>(menuItems[0]);
  const [isFeedbackSend, setIsFeedbackSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const [waitingSubmit, setWatingSubmit] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  // let activeFeedbackRef = useRef(auth.isStoredSettings() ? auth.getStoredSettings()?.fb : false);

  const handleSendFeedback = async () => {
    try {
      setWatingSubmit(true);
      setIsSubmitDisabled(true);
      const code = await sendFeedback({ topic, message, fileList });
      if (code === 201) {
        setIsFeedbackSend(true);
        setTopic(menuItems[0]);
        setMessage("");
        setFileList([]);
        setIsSubmitDisabled(true);
      } else {
        alert("Failed to send");
      }
    } catch (error) {
      console.error("Error feedback:", error);
      setIsFeedbackSend(true);
      setIsError(true);
    } finally {
      setWatingSubmit(false);
      setIsSubmitDisabled(true);
    }
  };
  const handleOpenForms = () => {
    setFeedbackOpen(true);
    setIsFeedbackSend(false);
  };
  const handleCloseForms = () => {
    setFeedbackOpen(false);
    setIsFeedbackSend(false);
    setIsError(false);
  };

  return (
    <Box>
      <Button sx={feedBackBtnStlExpanded} onClick={handleOpenForms}>
        <SentimentSatisfiedAltIcon />
        <Typography variant="body1Small400White">&nbsp;Need Help?</Typography>
      </Button>
      <Modal
        open={feedbackOpen}
        onClose={handleCloseForms}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={
            waitingSubmit
              ? { ...styleModalForm, bgcolor: alpha(`${colors.backgroudDisabled}`, 1) }
              : styleModalForm
          }
        >
          {waitingSubmit && (
            <Box>
              <LoaderWithProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 9999,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  scale: "55%",
                }}
              />
            </Box>
          )}
          {!isFeedbackSend ? (
            <Box>
              <Box sx={{ marginBottom: "2rem" }}>
                <Typography variant="h1XLargeDark800">{titleFeebackForm}</Typography>
              </Box>
              <Box sx={{ textAlign: "left", fontSize: "1rem", fontWeight: 400, marginBottom: "1rem" }}>
                <Box>
                  <Typography variant="body3XXSmall">Technical issue:</Typography>
                </Box>
                <Box sx={{ width: "100%", mb: "1rem" }}>
                  <Select
                    disabled={waitingSubmit}
                    sx={{ textAlign: "left", margin: "0.5rem 0", width: "100%" }}
                    MenuProps={{
                      MenuListProps: {
                        sx: {
                          margin: "0.5rem 0",
                          border: "1px solid #BEBEBE",
                          borderRadius: "5px",
                          boxShadow: "0px 4px 9px 0px #0017380D",
                        },
                      },
                    }}
                    id="demo-simple-select"
                    value={topic}
                    onChange={(e) => {
                      setTopic(e.target.value);
                    }}
                  >
                    {menuItems.map((item) => (
                      <MenuItem key={item} title={item} value={item}>
                        <Typography variant="body3XXSmall">{item}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Typography variant="body3XXSmall">Message:</Typography>
                </Box>
                <TextField
                  disabled={waitingSubmit}
                  id="outlined-multiline-static"
                  fullWidth
                  multiline
                  placeholder="Type..."
                  rows={4}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    e.target.value.length > 0 ? setIsSubmitDisabled(false) : setIsSubmitDisabled(true);
                  }}
                />
              </Box>
              <UploadForm fileList={fileList} setFileList={setFileList} disabled={waitingSubmit} />
              <Button
                variant="contained"
                sx={
                  waitingSubmit
                    ? { ...stlBtn, backgroundColor: "gray !important", color: "darkgray !important" }
                    : stlBtn
                }
                disabled={isSubmitDisabled}
                onClick={handleSendFeedback}
              >
                Submit
              </Button>
            </Box>
          ) : !isError ? (
            <ConfirmSend />
          ) : (
            <Box>
              <Typography variant="body2XSmall" sx={{ color: "red" }}>
                An error occurred while sending feedback
              </Typography>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default FeedbackForm;
