import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { btnStl, avatarStl, cardWrapperStl } from "./styles";
import { formatDate } from "../../utils";
import SideBoardWrapper from "../sideBoard/SideBoardWrapper";
import AdminElement from "../../components/ElementWrappers/AdminElement";
import { fetchAccountImg } from "../api/accounts-api";
import Fade from "@mui/material/Fade";
import { useGetAccountsQuery } from "../accountsApi";
import { BasicTooltip } from "../../components/BasicTooltip/BasicTooltip";
import { Button, ButtonBase } from "@mui/material";
import UserStatusChip from "../../components/Chips/UserStatusChip";
import AccountStatusMenu from "../AccountStatusMenu";
import { CONSTANT } from "../../constants/constants";
import { useSelector } from "react-redux";

const getStyledBadge = () => ({
  position: "relative",
  "&:after": {
    position: "absolute",
    content: '"Multi"',
    fontSize: "0.875rem",
    top: "6px",
    // right: "-70px",
    padding: "0.25rem 0.5rem",
    background: "orchid",
    borderRadius: "8px",
    color: "#fff",
    fontWweight: 700,
  },
});

const stl = { width: "50%", fontSize: "2rem", fontWeight: 600, padding: "1.5rem 1rem 1rem" };

export default function AccountDetails(props) {
  const [img, setImg] = useState(null);
  const { data: accounts, isLoading, isFetching, refetch } = useGetAccountsQuery();
  const [anchorEl, setAnchorEl] = useState(null);
  const { editedUser, loggedUser } = useSelector((state) => state.users);

  useEffect(() => {
    setImg(null);
    fetchImage();
  }, [isLoading, isFetching, props.actData.id]);

  const fetchImage = async () => {
    try {
      const res = await fetchAccountImg(props.actData.id);
      setImg(res);
    } catch (e) {
      setImg(null);
    }
  };

  return (
    <Box
      sx={{
        ...cardWrapperStl,
        boxShadow: "0px 12px 20px 0px rgba(0, 0, 0, 0.1)",
        padding: "0.15rem 1rem",
      }}
    >
      <Box sx={{ display: "flex", paddingBottom: "0.5rem", borderBottom: "1px solid #E0E2E7" }}>
        <Box sx={{ width: "50%", fontSize: "2rem", fontWeight: 600, padding: "1rem" }}>
          <Box sx={{ position: "relative" }}>
            {!!img ? (
              <Fade
                in={true}
                // style={{ transformOrigin: '0 0 0' }}
                timeout={1000}
              >
                <Box
                  sx={{
                    width: "120px",
                    height: "75px",
                    backgroundImage: `url(${img})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                >
                  {/* <img src={img} /> */}
                </Box>
              </Fade>
            ) : (
              <Avatar sx={avatarStl}>{props?.actData.company[0].toUpperCase() ?? "A"}</Avatar>
            )}
            <Box sx={{ position: "absolute", top: "55px", left: img ? "120px" : "70px" }}>
              <AdminElement canAdmin={props.userManagementAdminPermission}>
                <SideBoardWrapper
                  type="edit"
                  btnTitle={
                    <BasicTooltip tooltipText="Edit account">
                      <EditOutlinedIcon sx={{ fontSize: "18px" }} />
                    </BasicTooltip>
                  }
                  headerTitle="Edit account"
                  btnIcon={false}
                  btnStyles={{
                    ...btnStl,
                    width: "10px",
                    padding: "3px",
                    borderRadius: "50%",
                    minWidth: "27px",
                  }}
                  updateDataAndRows={props.updateDataAndRows}
                  value={props.actData}
                />
              </AdminElement>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", paddingBottom: "1rem" }}>
        <Box sx={props.isVirtual ? { ...getStyledBadge(), ...stl } : { ...stl }}>
          {props?.actData.company ?? ""}
        </Box>
        <Box sx={{ width: "50%", padding: "1rem", textAlign: "right" }}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              sx={{ width: "30%", padding: "1.5rem 1rem 0rem", fontSize: "1rem" }}
            >
              <Grid item md={3} sx={{ fontSize: "1.rem" }}>
                Status
              </Grid>
              <Grid item md={3} sx={{ marginTop: "0.5rem", fontSize: "1rem", fontWeight: 600 }}>
                <ButtonBase
                  onClick={(event) => {
                    if (props.actData.id !== CONSTANT.IIQ_ACCOUNT_ID && loggedUser.canCreateAccount)
                      setAnchorEl(event.currentTarget);
                  }}
                >
                  <UserStatusChip status={props?.actData.status} />
                </ButtonBase>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              // alignItems="flex-start"
              sx={{ width: "30%", padding: "1.5rem 1rem 0rem", fontSize: "1rem" }}
            >
              <Grid item md={3} sx={{ fontSize: "1.rem" }}>
                Creation date
              </Grid>
              <Grid item md={3} sx={{ marginTop: "0.5rem", fontSize: "1rem", fontWeight: 600 }}>
                {formatDate(new Date(props.actData.creationDate)).fullFormat}
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              sx={{ width: "30%", padding: "1.5rem 1rem 0rem", fontSize: "1rem" }}
            >
              <Grid item md={3} sx={{ fontSize: "1.rem" }}>
                Trial period
              </Grid>
              <Grid item md={3} sx={{ marginTop: "0.5rem", fontSize: "1rem", fontWeight: 600 }}>
                {`${props.actData.trialStartDate ? formatDate(new Date(props.actData.trialStartDate)).fullFormat : "not set"} - ${props.actData.trialEndDate ? formatDate(new Date(props.actData.trialEndDate)).fullFormat : "not set"}`}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <AccountStatusMenu
        account={props.actData}
        currentStatus={props.actData.status}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        isVirtual={props.actData?.virtualAccount === 1}
      />
    </Box>
  );
}
