import React from "react";
import { Alert, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import { colors } from "../../theme";

interface BannerProps {
  text: string;
  level: BannerLevelNames;
  visible: boolean;
  onClose: () => void;
}

interface LevelProps {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  icon: React.ReactNode;
}

export enum BannerLevelNames {
  INFO,
  WARNING,
  URGENT,
}

interface BannerLevel {
  [BannerLevelNames.INFO]: LevelProps;
  [BannerLevelNames.WARNING]: LevelProps;
  [BannerLevelNames.URGENT]: LevelProps;
}

export const bannerLevels: BannerLevel = {
  [BannerLevelNames.INFO]: {
    textColor: colors.brandDefault,
    backgroundColor: colors.blue50,
    borderColor: colors.blue300,
    icon: <WarningIcon sx={{ color: colors.brandDefault }} />,
  },
  [BannerLevelNames.WARNING]: {
    textColor: colors.statusWarningHover,
    backgroundColor: colors.statusWarningBackgroundSubtle,
    borderColor: colors.yellow300,
    icon: <WarningIcon sx={{ color: colors.statusWarningHover }} />,
  },
  [BannerLevelNames.URGENT]: {
    textColor: colors.brightRed900,
    backgroundColor: colors.brightRed50,
    borderColor: colors.brightRed300,
    icon: <ErrorIcon sx={{ color: colors.brightRed300 }} />,
  },
};

const Banner: React.FC<BannerProps> = ({ text, visible, onClose, level }) => {
  if (!visible) return null;

  return (
    <Alert
      icon={bannerLevels[level].icon}
      //severity="info"
      sx={{
        backgroundColor: bannerLevels[level].backgroundColor,
        color: bannerLevels[level].textColor,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
        borderRadius: "32px",
        border: `1px solid ${bannerLevels[level].borderColor}`,
        height: "36px",
      }}
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      <Typography sx={{ color: bannerLevels[level].textColor }}>{text}</Typography>
    </Alert>
  );
};

export default Banner;
