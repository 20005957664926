import React from "react";
import { Button } from "@mui/material";
import { useDeleteConnectionMutation } from "./connectionsApi";

interface DeleteButtonProps {
  id: string;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ id }) => {
  const [deleteConnection, { isLoading }] = useDeleteConnectionMutation();

  const handleDelete = async () => {
    try {
      await deleteConnection(id).unwrap();
      alert("Connection deleted successfully");
    } catch (error) {
      console.error("Error deleting the connection:", error);
      alert("Failed to delete the connection");
    }
  };

  return (
    <Button onClick={handleDelete} disabled={isLoading} color="error">
      Delete
    </Button>
  );
};

export default DeleteButton;
