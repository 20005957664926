import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { BasicTooltip } from "../../components/BasicTooltip/BasicTooltip";
import { singleBtn, dateContainer, btnWrapper } from "./styles";
import { CONSTANT } from "../../constants/constants";
import { daysAgoSinceStartDate, getMTD } from "../../utils";
const MONTH = CONSTANT.DATE_GROUP_MODES.MONTH;
const RANGE_DATE_YESTERDAY = CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value;
const LAST_MONTH = CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value;

export default function DateRangePanel({
  renderSelectedDate,
  isActiveButton,
  handleDateRangeButtons,
  isDisabled,
  dateGroupingMode,
  filterBase,
  active_range_date,
  matches,
  datePicker,
  hourPicker,
  extendedDateGroupingSelection,
}) {
  return (
    <Box sx={btnWrapper}>
      <BasicTooltip
        tooltipText={`Latest reports - ${renderSelectedDate({
          rangeTimeParam: RANGE_DATE_YESTERDAY,
        })}`}
      >
        <span>
          <Button
            data-testid="timeRangeYesterday"
            sx={singleBtn}
            style={isActiveButton(RANGE_DATE_YESTERDAY)}
            disabled={
              isDisabled || dateGroupingMode === MONTH || dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER
            }
            onClick={() =>
              handleDateRangeButtons({
                type: CONSTANT.DATE_RANGE_TYPES.YESTARDAY.type,
                value: CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value,
              })
            }
          >
            Yesterday
          </Button>
        </span>
      </BasicTooltip>
      <BasicTooltip
        tooltipText={`Reports of ${renderSelectedDate({
          rangeTimeParam: 7,
        })}`}
      >
        <span>
          <Button
            data-testid="timeRangeLastWeek"
            sx={singleBtn}
            disabled={
              isDisabled || dateGroupingMode === MONTH || dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER
            }
            style={isActiveButton(CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value)}
            onClick={() =>
              handleDateRangeButtons({
                type: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.type,
                value: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value,
              })
            }
          >
            Last week
          </Button>
        </span>
      </BasicTooltip>
      <BasicTooltip
        tooltipText={
          getMTD() === 0
            ? "Option is unavailable - zero completed days from the beginning of the current month"
            : `${renderSelectedDate({ rangeTimeParam: getMTD() })}`
        }
      >
        <span>
          <Button
            disabled={
              isDisabled ||
              getMTD() === 0 ||
              dateGroupingMode === MONTH ||
              dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER
            }
            data-testid="timeRangeMtd"
            sx={singleBtn}
            style={isActiveButton(getMTD())}
            onClick={() => handleDateRangeButtons({ type: CONSTANT.DATE_RANGE_TYPES.MTD.type, value: getMTD() })}
          >
            MTD
          </Button>
        </span>
      </BasicTooltip>
      <BasicTooltip
        tooltipText={`${renderSelectedDate({
          rangeTimeParam: LAST_MONTH,
        })}`}
      >
        <span>
          <Button
            disabled={isDisabled || dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER}
            data-testid="timeRangeLastMonth"
            sx={singleBtn}
            style={isActiveButton(LAST_MONTH)}
            onClick={() =>
              handleDateRangeButtons({
                type: CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.type,
                value: CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value,
              })
            }
          >
            Last Month
          </Button>
        </span>
      </BasicTooltip>
      <BasicTooltip
        tooltipText={`Since beginning of the year, ${renderSelectedDate({
          rangeTimeParam: daysAgoSinceStartDate(),
        })}`}
      >
        <span>
          <Button
            disabled={isDisabled || dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER}
            data-testid="timeRangeYtd"
            sx={singleBtn}
            style={isActiveButton(daysAgoSinceStartDate())}
            onClick={() =>
              handleDateRangeButtons({ type: CONSTANT.DATE_RANGE_TYPES.YTD.type, value: daysAgoSinceStartDate() })
            }
          >
            YTD
          </Button>
        </span>
      </BasicTooltip>
      <BasicTooltip
        tooltipText={`Earliest reports till ${renderSelectedDate({
          rangeTimeParam: RANGE_DATE_YESTERDAY,
        })}`}
      >
        <span>
          <Button
            disabled={isDisabled || dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER}
            data-testid="timeRangeAllTime"
            sx={singleBtn}
            style={isActiveButton(CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value)}
            onClick={() =>
              handleDateRangeButtons({
                type: CONSTANT.DATE_RANGE_TYPES.ALL_TIME.type,
                value: CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value,
              })
            }
          >
            All time
          </Button>
        </span>
      </BasicTooltip>
      {datePicker}
      {hourPicker != null && hourPicker}
      {extendedDateGroupingSelection != null && extendedDateGroupingSelection}
      <Box
        data-testid="timeRangeSelected"
        style={{
          flexGrow: 1,
          textAlign: "right",
        }}
      >
        <Typography sx={dateContainer}>{renderSelectedDate({ isShortFormat: !matches })}</Typography>
      </Box>
    </Box>
  );
}
