import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, TextField, Button, Typography } from "@mui/material";
import { TooltipMultiselectGroup } from "../BasicTooltip/BasicTooltip";
import { colors } from "../../theme";

interface ISelectedItemButton {
  item: any;
  onDelete: (account: any) => void;
  itemFieldName: string;
}

export const SelectedItemButton: React.FC<ISelectedItemButton> = ({ item, onDelete, itemFieldName }) => {
  return (
    <Button
      sx={{
        color: colors.mainDarkPrimary100,
        backgroundColor: colors.blue50,
        height: "32px",
        fontSize: "12px",
        width: "fit-content",
        padding: "5px 10px 5px 10px",
        key: item.id,
        borderRadius: "16px",
      }}
    >
      <TooltipMultiselectGroup tooltipText={item[itemFieldName]}>
        <Box sx={{ display: "flex", gap: "3px" }}>
          <Typography>{item[itemFieldName] + " | "}</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CloseIcon
              sx={{
                fontSize: "1rem",
                marginRight: "4px",
                "&:hover": {
                  // Styles for hover state of EditOutlinedIcon
                  color: "salmon", // Example: change color on hover
                  cursor: "pointer",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (onDelete) {
                  onDelete(item);
                }
              }}
            ></CloseIcon>
          </Box>
        </Box>
      </TooltipMultiselectGroup>
    </Button>
  );
};
