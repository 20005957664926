import * as React from "react";
import { Box } from "@mui/material";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { styled } from "@mui/material/styles";
import { colors } from "../../theme";

interface CustomToggleProps {
  tableView: boolean;
  onChange: (checked: any) => void;
}

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "32px",
  width: "32px",
  fontSize: "16px",
  border: `1px solid ${colors.lightGrey}`,
  borderRadius: "4px",
}));

export const TableCardsToggleSwitch: React.FC<CustomToggleProps> = ({ tableView, onChange, ...props }) => {
  const handleToggle = () => {
    onChange(!tableView);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "32px",
        width: "68px",
        justifyContent: "space-between",
        cursor: "pointer",
        position: "relative",
        margin: "4px",
      }}
      onClick={handleToggle}
    >
      <IconContainer
        sx={{
          color: tableView ? colors.mainDarkPrimary100 : "white",
          backgroundColor: !tableView ? colors.mainDarkPrimary100 : "white",
        }}
      >
        <ViewModuleIcon fontSize="inherit" />
      </IconContainer>
      <IconContainer
        sx={{
          color: !tableView ? colors.mainDarkPrimary100 : "white",
          backgroundColor: tableView ? colors.mainDarkPrimary100 : "white",
        }}
      >
        <DensityMediumIcon fontSize="inherit" />
      </IconContainer>
    </Box>
  );
};
