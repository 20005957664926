import { store } from "../../../../../store/store";
import { CONSTANT } from "../../../../../constants/constants";
import auth from "../../../../../auth/auth-helper";
import { prepareDateFormat } from "../../../../../utils";

const SERVER_URL = CONSTANT.path.host;

const getCsv = async (hCols) => {
  let currentState = store.getState();
  const payload = Object.fromEntries(
    Object.entries(currentState.advancedReport.filters).map(([key, values]) => [
      key,
      values.filter((unit) => unit.isSelected),
    ]),
  );
  let cols = [];
  for (let i in hCols) {
    if (hCols[i] === false) cols.push(i);
  }

  let jwt = auth.isAuthenticated();
  let token = jwt.data.accessToken;

  let target = "csv_create_download";

  let rt = currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange.value;
  let customDateRange =
    currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange;
  let dgm =
    currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode;
  if ("timeGrouping" in payload && payload.timeGrouping) rt = payload.timeGrouping;

  const summaryBody = {
    rt: rt + "",
    dgm: dgm,
    pid: currentState.role.partnersSelected.map((p) => p.dummy_id).join(","),
    client_type:
      payload.browsers.length === currentState.advancedReport.filters.browsers.length &&
      payload.browsers.length > 0
        ? "*"
        : payload.browsers.map((unit) => unit.id).join(","),
    device_type:
      payload.deviceTypes.length === currentState.advancedReport.filters.deviceTypes.length &&
      payload.deviceTypes.length > 0
        ? "*"
        : payload.deviceTypes.map((unit) => unit.id).join(","),
    action_termination:
      payload.actionTerminations.length === currentState.advancedReport.filters.actionTerminations.length &&
      payload.actionTerminations.length > 0
        ? "*"
        : payload.actionTerminations.map((unit) => unit.id).join(","),
    site_id:
      payload.sites.length === currentState.advancedReport.filters.sites.length && payload.sites.length > 0
        ? "*"
        : payload.sites.map((unit) => unit.id).join(","),
    js_version:
      payload.jsVersions.length === currentState.advancedReport.filters.jsVersions.length &&
      payload.jsVersions.length > 0
        ? "*"
        : payload.jsVersions.map((unit) => unit.id).join(","),
    country_id:
      payload.countries.length === currentState.advancedReport.filters.countries.length &&
      payload.countries.length > 0
        ? "*"
        : payload.countries.map((unit) => unit.id).join(","),
    biddercode:
      payload.biddercodes.length === currentState.advancedReport.filters.biddercodes.length &&
      payload.biddercodes.length > 0
        ? "*"
        : payload.biddercodes.map((unit) => unit.id).join(","),
    pct: payload.pct.map((unit) => unit.id).join(","),
    currency: payload.currencies.map((unit) => unit.id).join(","),
    cstm_start: rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value ? customDateRange.customDateStart : null,
    cstm_end: rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value ? customDateRange.customDateEnd : null,
    cdm: currentState.advancedReport.combinedDataMode,
    hc: cols.join(","),
  };

  try {
    let response = await fetch(`${SERVER_URL}/statistics/${target}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },

      body: JSON.stringify(summaryBody),
    });

    if (!response.ok) {
      throw new Error("server can not get report");
    }

    const blob = await response.blob();
    let contentDisposition = response.headers.get("Content-Disposition");

    let fileName = contentDisposition
      ? contentDisposition.split("filename=")[1].split(";")[0].replace(/["']/g, "")
      : "report" +
        "_" +
        prepareDateFormat(new Date()) +
        "_" +
        currentState.report.selectedCurrency +
        "_" +
        currentState.report.selectedPct +
        ".zip";

    const newBlob = new Blob([blob]);
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
    } else {
      // For other browsers: create a link pointing to the ObjectURL containing the blob.
      const objUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = objUrl;
      link.download = fileName;
      link.click();
      // For Firefox it is necessary to delay revoking the ObjectURL.
      setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
      }, 250);
    }
  } catch (error) {
    console.log("Error: ", error.message);
    throw error;
  }
};

export { getCsv };
