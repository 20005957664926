import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 33, // Adjusted width
  height: 19, // Adjusted height
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(14px)", // Adjusted for new width
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2.5,
    "&.Mui-checked": {
      transform: "translateX(14px)", // Adjusted for new width
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15, // Adjusted thumb width
    height: 15, // Adjusted thumb height
    borderRadius: 7.5, // Adjusted to be half of height for circular shape
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 19 / 2, // Adjusted to match new height
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export const BasicSwitch = styled(AntSwitch)(
  ({ uncheckedColor = "#263558" }) => ({
    width: 33, // Override external box width
    height: 19, // Override external box height
    "& .MuiSwitch-thumb": {
      width: 14, // Override internal switch circle diameter
      height: 14, // Make height equal to width for circle
      borderRadius: "50%", // Makes it a circle
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        transform: "translateX(14px)", // Adjust this to correct the thumb position when checked
        "& + .MuiSwitch-track": {
          backgroundColor: "#1BB2FF", // Background color for checked state
        },
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#1BB2FF", // Background color for checked state
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 19 / 2, // Adjusted to match new height
      backgroundColor: uncheckedColor, // Parameterized background color for unchecked state
      opacity: 1,
    },
  })
);
