import React from "react";
import { Box, FormControl, FormLabel, FormControlLabel, Checkbox } from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import { colors } from "../../theme";

interface ModulePermissionsCheckboxesProps {
  modulesTypes: { [key: string]: [number, number, string] };
  value: { [key: string]: number | null };
  handleChangeCheckbox: (moduleKey: string, permissionValue: number) => void;
  colors: { lightBlue: string };
}

export const modulesTypesArray: [string, number, number, string][] = [
  ["rmp", CONSTANT.MODULE.RMP_ADMIN, CONSTANT.MODULE.RMP_VIEW, "Roles management"],
  ["dashboard", CONSTANT.MODULE.DASHBOARD_ADMIN, CONSTANT.MODULE.DASHBOARD_VIEW, "Dashboard"],
  ["billing", CONSTANT.MODULE.BILLING_ADMIN, CONSTANT.MODULE.BILLING_VIEW, "Billing"],
];

export const ModulePermissionsCheckboxes: React.FC<ModulePermissionsCheckboxesProps> = ({
  value,
  handleChangeCheckbox,
}) => {
  return (
    <>
      {modulesTypesArray.map(([moduleKey, adminPermission, viewPermission, label]) => (
        <Box key={moduleKey}>
          <FormControl sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <FormLabel sx={{ lineHeight: "35px", marginRight: "1.5rem" }} id={`${moduleKey}-checkbox-group-label`}>
              {label}
            </FormLabel>
            <Box>
              {[adminPermission, viewPermission].map((permissionValue, index) => (
                <FormControlLabel
                  key={`${moduleKey}-${index}`}
                  control={
                    <Checkbox
                      checked={value[moduleKey] === permissionValue}
                      onChange={() => handleChangeCheckbox(moduleKey, permissionValue)}
                      name={moduleKey}
                      sx={{
                        "&.Mui-checked": {
                          color: colors.lightBlue,
                        },
                      }}
                    />
                  }
                  label={permissionValue % 2 === 0 ? "Admin" : "View"}
                />
              ))}
            </Box>
          </FormControl>
        </Box>
      ))}
    </>
  );
};
