import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { pageContainerStyle } from "../../accounts/styles";
import CreateNewNotificationForm from "./CreateNewNotificationForm";
import NotificationHistory from "./NotificationHistory";

const menuTabTypes = {
  createNew: {
    id: 1,
    title: "Create New",
    isDisabled: false,
  },
  history: {
    id: 2,
    title: "History",
    isDisabled: true,
  },
  //   interactions: {
  //     id: 3,
  //     title: "Interactions",
  //     isDisabled: true,
  //   },
};
export const menuTabs = Object.values(menuTabTypes);

const NotificationsPage: React.FC = () => {
  const [tabIdSelected, setTabIdSelected] = useState(1);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIdSelected(newValue);
  };

  return (
    <Box sx={pageContainerStyle}>
      <Box sx={{ marginRight: 0, display: "flex", justifyContent: "space-between", gap: "10px", width: "100%" }}>
        <Tabs value={tabIdSelected} onChange={handleTabChange} aria-label="notification management tabs">
          {menuTabs.map((tab, index) => (
            <Tab label={tab.title} key={tab.id} value={tab.id} />
          ))}
        </Tabs>
      </Box>
      {tabIdSelected === menuTabTypes.createNew.id && <CreateNewNotificationForm />}
      {tabIdSelected === menuTabTypes.history.id && <NotificationHistory />}
      {/* {filteredAccounts && <AccountsTable filteredAccounts={filteredAccounts} />} */}
    </Box>
  );
};

export default NotificationsPage;
