import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  container_MonthPickerTis,
  header_MonthPickerTis,
  navigation_MonthPickerTis,
  navIcon_MonthPickerTis,
  year_MonthPickerTis,
  months_MonthPickerTis,
  month_MonthPickerTis,
  selected_MonthPickerTis,
  selectedFirst_MonthPickerTis,
  selectedLast_MonthPickerTis,
} from "./style"

const currentYear = moment().year();
const monthIndicator = { year: null, month: null };
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MonthPicker = ({
  className,
  darkMode,
  onChange,
  onReset,
  initialRange = {
    start: { ...monthIndicator },
    end: { ...monthIndicator },
  },
  id,
  ...attrs
}) => {
  const [year, setYear] = useState(initialRange?.start?.year || currentYear);
  const [selectedRange, setSelectedRange] = useState(initialRange);

  useEffect(() => {
    if (typeof onChange === 'function' && selectedRange.end.year)
      onChange(selectedRange);
  }, [selectedRange.end]); // eslint-disable-line

  useEffect(() => {
    //reset selection - if click on right side of calendar
    setSelectedRange(initialRange);
  }, [onReset]); // eslint-disable-line

  const onMonthClick = index => {
    const selectedData = { year, month: index };
    const { start, end } = selectedRange;

    const isBeforeStart = () =>
      selectedData.year < start.year ||
      (selectedData.year === start.year && selectedData.month < start.month);

    /* check if date-range has been selected, selected date is before prev star
    date or none date has been selected yet as make it as start date. */
    if (!start.year || end.year || isBeforeStart())
      setSelectedRange({ start: selectedData, end: { ...monthIndicator } });
    else setSelectedRange(range => ({ ...range, end: selectedData }));
  };


  const isSelected = index => {
    const {
      start: { year: startYear, month: startMonth },
      end: { year: endYear, month: endMonth },
    } = selectedRange;

    const isAfterStart = index >= startMonth;
    const isBeforeEnd = index <= endMonth;

    if (endYear)
      return year === startYear && startYear === endYear
        ? isAfterStart && isBeforeEnd
        : year === startYear
          ? isAfterStart
          : year === endYear
            ? isBeforeEnd
            : year > startYear && year < endYear;
  };

  const isSelectedHead = (index, isFirst) => {
    const { start, end } = selectedRange;
    const comparisonObj = isFirst ? start : end;

    return year === comparisonObj.year && index === comparisonObj.month;
  };


  return (
    <div
      id={id}
      style={container_MonthPickerTis}
      {...attrs}
    >
      <header style={header_MonthPickerTis}>
        <div style={navigation_MonthPickerTis}>
          <button
            disabled={(2022 == year) ? true : false}
            style={navIcon_MonthPickerTis}
            onClick={() => {
              setYear(y => y - 1);
            }}
          >
            <ChevronLeftIcon />
          </button>
        </div>
        <span style={year_MonthPickerTis} data-testid='year'>
          {year}
        </span>

        <div style={navigation_MonthPickerTis}>

          <button
            disabled={(new Date().getFullYear() == year) ? true : false}
            style={navIcon_MonthPickerTis}
            onClick={() => setYear(y => y + 1)}
          >
            <ChevronRightIcon />
          </button>
        </div>
      </header>

      <div style={months_MonthPickerTis}>
        {months.map((month, index) => {
          let current = new Date();
          current.setMonth(current.getMonth() - 1);
          let btnDate = new Date(year, index);
          let isDisabled = btnDate > current ? true : false;

          let obj = { ...month_MonthPickerTis };
          if (isSelected(index)) Object.assign(obj, selected_MonthPickerTis);
          if (isSelectedHead(index, true)) Object.assign(obj, selectedFirst_MonthPickerTis);
          if (isSelectedHead(index, false)) Object.assign(obj, selectedLast_MonthPickerTis);

          return (
            <Box
              id={isSelectedHead(index, true) ? "theFirst" : isSelectedHead(index, false) ? "theLast" : null}
              key={index}
              sx={{
                ...obj,
                color: isDisabled ? "#bebebe" : obj.color,
                cursor: isDisabled ? "default" : "pointer",
              }}

              onClick={isDisabled ? null : () => onMonthClick(index)}
            >
              <span>{month}</span>
            </Box>
          )
        })}
      </div>
    </div>
  );
};

MonthPicker.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  darkMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  initialRange: PropTypes.shape({
    start: PropTypes.shape({
      year: PropTypes.number,
      month: PropTypes.number,
    }),
    end: PropTypes.shape({
      year: PropTypes.number,
      month: PropTypes.number,
    })
  }),
};