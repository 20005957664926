import { CONSTANT } from "../../constants/constants";
import auth from "../../auth/auth-helper";
const SERVER_URL = CONSTANT.path.host;

const fetchAccountsList = async (signal) => {
  try {
    let token = auth.isAuthenticated().data.accessToken;
    let response = await fetch(
      `${SERVER_URL}/accounts/fetch_accounts_list`,
      {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
        },
      },
      { signal },
    );

    return await response.json();
  } catch (error) {
    console.log(`Could not fetch : ${error}`);
  }
};

const fetchAccountImg = async (accountId, signal) => {
  const imageUrl = `${SERVER_URL}/accounts/account_img/${accountId}`;
  const cacheName = CONSTANT.cacheNameAccountImages;

  if ("caches" in window) {
    try {
      const cacheStorage = await caches.open(cacheName);
      const cachedResponse = await cacheStorage.match(imageUrl);
      if (cachedResponse) {
        const noContentMarker = cachedResponse.headers.get("X-No-Content");
        if (noContentMarker === "true") {
          // console.log(`No content marker found for account ${accountId}`);
          return null; // Indicate no content available
        }
        // console.log(`Serving cached image for account ${accountId}`);
        return URL.createObjectURL(await cachedResponse.blob());
      }
    } catch (error) {
      console.warn("Cache API is available but an error occurred:", error);
    }
  }

  try {
    const token = auth.isAuthenticated().data.accessToken;
    const response = await fetch(imageUrl, {
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
      signal: signal,
    });

    if (!response.ok || response.status === 204) {
      if ("caches" in window) {
        // console.log(`Caching no content marker for account ${accountId}`);
        // Use a 200 OK status but with a custom header to indicate no content.
        const headers = new Headers({ "X-No-Content": "true" });
        const noContentResponse = new Response("", { status: 200, headers });
        await caches.open(cacheName).then((cache) => cache.put(imageUrl, noContentResponse));
      }
      return null;
    }

    if ("caches" in window) {
      // console.log(`Caching image for account ${accountId}`);
      await caches.open(cacheName).then((cache) => cache.put(imageUrl, response.clone()));
    }

    const imageBlob = await response.blob();
    return URL.createObjectURL(imageBlob);
  } catch (error) {
    console.error(`Could not fetch the image: ${error}`);
    throw error;
  }
};

const fetchAccountImgData = async (rt, signal) => {
  try {
    let token = auth.isAuthenticated().data.accessToken;
    let response = await fetch(
      `${SERVER_URL}/accounts/account_img_data/${rt}`,
      {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
        },
      },
      { signal },
    );
    return await response.text();
  } catch (error) {
    console.log(`Could not fetch img data: ${error}`);
  }
};

// const getAccountDetails = async (id, signal) => {
//   try {
//     let jwt = auth.isAuthenticated();
//     let token = jwt.data.accessToken;

//     let response = await fetch(
//       `${SERVER_URL}/accounts/account_details/${id}`,
//       {
//         method: "GET",
//         headers: {
//           authorization: "Bearer " + token,
//         },
//       },
//       { signal }
//     );

//     return await response.json();
//   } catch (error) {
//     console.log(`Could not fetch : ${error}`);
//   }
// };
const getRoleDetails = async (id, signal) => {
  try {
    let jwt = auth.isAuthenticated();
    let token = jwt.data.accessToken;

    let response = await fetch(
      `${SERVER_URL}/accounts/role_details/${id}`,
      {
        method: "GET",
        headers: {
          authorization: "Bearer " + token,
        },
      },
      { signal },
    );

    return await response.json();
  } catch (error) {
    console.log(`Could not fetch : ${error}`);
  }
};

const createAccount = async (data) => {
  let token = auth.isAuthenticated().data.accessToken;
  try {
    let response = await fetch(`${SERVER_URL}/accounts/create_account`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
      },
      body: data,
      // body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

const updateAccount = async (data, id) => {
  let token = auth.isAuthenticated().data.accessToken;
  try {
    let response = await fetch(`${SERVER_URL}/accounts/update_account/${id}`, {
      method: "PATCH",
      headers: {
        authorization: "Bearer " + token,
        Accept: "application/json",
      },
      body: data,
      // body: JSON.stringify(data),
    });
    await invalidateAccountCache(id);
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

const addPartnerToAccount = async (data, id) => {
  let token = auth.isAuthenticated().data.accessToken;
  try {
    let response = await fetch(`${SERVER_URL}/accounts/add_partner/${id}`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

const unassignRole = async (data) => {
  let token = auth.isAuthenticated().data.accessToken;
  try {
    let response = await fetch(`${SERVER_URL}/accounts/unassign_user_role`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

const remove = async (params, credentials) => {
  try {
    let response = await fetch("/api/users/" + params.userId, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + credentials.t,
      },
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

const invalidateAccountCache = async (accountId) => {
  if ("caches" in window) {
    const cacheName = "account-images";
    const imageUrl = `${SERVER_URL}/accounts/account_img/${accountId}`;
    const cacheStorage = await caches.open(cacheName);
    const deleted = await cacheStorage.delete(imageUrl);
    if (deleted) {
      console.log(`Cache invalidated for account ${accountId}`);
    } else {
      console.log(`No cache found for account ${accountId} to invalidate`);
    }
  }
};

export {
  createAccount,
  updateAccount,
  addPartnerToAccount,
  fetchAccountImg,
  fetchAccountImgData,
  fetchAccountsList,
  getRoleDetails,
  unassignRole,
};
