import React from "react";

export default function ReportChartWrapper(props) {
  let { width = "365px", marginLeft = "15px" } = props;
  return (
    <div
      style={{
        width: width,
        margin: `0px 15px 0px ${marginLeft}`,
        height: "280px",
        padding: "1rem",
        background: "#FFFFFF",
        border: "1px solid #E0E2E7",
        boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
        borderRadius: "8px",
      }}
    >
      {props.children}
    </div>
  );
}
