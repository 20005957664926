import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { compareDesc, format, isToday, isYesterday, parseISO } from "date-fns";
import PointIcon from "../../components/IconComponents/PointIcon";
import { NotificationStatus, ResponseNotificationDto } from "./noticeTypes";
import { colors } from "../../theme";
import React, { useMemo } from "react";
import { useMarkNotificationAsReadMutation } from "./notificationsApi";
import TextWithLinks from "./TextWithLinks";

interface ReceivedNotificationsListProps {
  notifications: ResponseNotificationDto[];
}

const formatCreatedAt = (dateString: string) => {
  const date = parseISO(dateString);
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    return format(date, "yyyy-MM-dd");
  }
};

const ReceivedNotificationsList: React.FC<ReceivedNotificationsListProps> = ({ notifications }) => {
  const [markAsRead] = useMarkNotificationAsReadMutation();
  const sortedNotifications = useMemo(() => {
    return [...notifications].sort((a, b) => {
      const dateA = parseISO("" + a.createdAt);
      const dateB = parseISO("" + b.createdAt);
      return compareDesc(dateA, dateB);
    });
  }, [notifications]);
  const handleClickMarkNoticeAsRead = async (
    event: React.MouseEvent<HTMLSpanElement>,
    noticeId: number,
  ): Promise<void> => {
    event.stopPropagation();
    try {
      await markAsRead([noticeId]).unwrap();
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  return (
    <List sx={{ width: "100%", bgcolor: colors.mainWhite }}>
      {sortedNotifications.map((notice) => (
        <ListItem key={notice.id} alignItems="flex-start" sx={{ height: "auto" }}>
          <ListItemAvatar
            sx={{
              marginTop: "8px",
              textAlign: "start",
              minWidth: "90px",
            }}
          >
            <Typography variant="bodyMedium" sx={{ color: colors.textBaseTertiary }}>
              {formatCreatedAt("" + notice.createdAt)}
            </Typography>
          </ListItemAvatar>
          <ListItemText
            sx={{
              marginTop: "8px",
              textAlign: "start",
            }}
            primary={
              <Typography
                variant="body2XSmallMain400"
                sx={{
                  fontWeight: 600,
                  alignSelf: "flex-start",
                  lineHeight: "20px",
                  textAlign: "start",
                }}
              >
                {notice.title}
              </Typography>
            }
            secondary={
              <React.Fragment>
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "8px",
                  }}
                >
                  <Typography
                    variant="body2XSmallMain400"
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    <TextWithLinks text={notice.text} />
                  </Typography>
                  {notice.notificationStatus === NotificationStatus.SENT && (
                  // {notice.status === NotificationStatus.SENT && (
                    <Typography
                      onClick={(e) => handleClickMarkNoticeAsRead(e, notice.id)}
                      variant="body2XSmall"
                      sx={{
                        pointerEvents: "auto",
                        fontWeight: 600,
                        lineHeight: "20px",
                        color: colors.brandDefault,
                        cursor: "pointer",
                      }}
                    >
                      Mark as read
                    </Typography>
                  )}
                </Box>
              </React.Fragment>
            }
          />
          <ListItemAvatar sx={{ marginTop: "6px", minWidth: "40px" }}>
            {notice.notificationStatus === NotificationStatus.SENT ? <PointIcon /> : null}
          </ListItemAvatar>
        </ListItem>
      ))}
    </List>
  );
};

export default ReceivedNotificationsList;
