import { createAsyncThunk } from "@reduxjs/toolkit";
import auth from "../../../auth/auth-helper";
import { CONSTANT } from "../../../constants/constants";
import { getReportPctModes } from "../../../report/utils";
import { setCurrencyPctModeData, resetSummaryCompleteDate } from "../../../report/reportSlice";
import { resetSavedCustomRawDataState } from "../../../savedRawData/savedRawDataSlice";
import { setGetStatsBody } from "./advancedReportSlice";
import { checkVersion } from "../../../infrastructure/infrastructureHelpers";
import { getVisitorRecognitionStats } from "./getVisitorRecognitionStats";
import { transformColumnBaseToRowBase } from "./utils";
const host = CONSTANT.path.host;

export const getValues = (arr, field, paramName) => {
  let res = [];
  for (let i of arr) {
    res.push(i[field]);
  }
  let str = res.join(",");
  return `&${paramName}=${str}`;
};

export const getStats = createAsyncThunk(
  "advancedReport/getStats",
  async (payload, { rejectWithValue, dispatch, getState }) => {
    let { signal } = payload;
    const vrPayload = payload;
    let currentState = getState();
    let jwt = auth.isAuthenticated();
    let token = jwt.data.accessToken;

    let target = "stats";

    let rt = payload.timeGrouping;
    let dgm =
      payload.dgm ??
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode;
    let customDateRange = payload.customDateRange;
    const partnersSelected = currentState.role.partnersSelected;
    if (auth.isAdminRole()) {
      if (!Boolean(partnersSelected.length)) {
        return false;
      }
      target = "stats_observe";
    }
    const summaryBody = {
      rt: rt,
      dgm: dgm,
      pid: partnersSelected.map((p) => p.dummy_id).join(","),
      client_type:
        (!currentState.advancedReport.filtersIsLoading.browsers &&
          payload.browsers.length === currentState.advancedReport.filters.browsers.length &&
          payload.browsers.length > 0) ||
        payload.browsers[0]?.id === "*"
          ? "*"
          : payload.browsers
              .filter((unit) => unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      device_type:
        (!currentState.advancedReport.filtersIsLoading.deviceTypes &&
          payload.deviceTypes.length === currentState.advancedReport.filters.deviceTypes.length &&
          payload.deviceTypes.length > 0) ||
        payload.deviceTypes[0]?.id === "*"
          ? "*"
          : payload.deviceTypes
              .filter((unit) => unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      action_termination:
        (!currentState.advancedReport.filtersIsLoading.actionTerminations &&
          payload.actionTerminations.length === currentState.advancedReport.filters.actionTerminations.length &&
          payload.actionTerminations.length > 0) ||
        payload.actionTerminations[0]?.id === "*"
          ? "*"
          : payload.actionTerminations
              .filter((unit) => unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      site_id:
        (!currentState.advancedReport.filtersIsLoading.sites &&
          payload.sites.length === currentState.advancedReport.filters.sites.length &&
          payload.sites.length > 0) ||
        payload.sites[0]?.id === "*"
          ? "*"
          : payload.sites
              .filter((unit) => unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      js_version:
        (!currentState.advancedReport.filtersIsLoading.jsVersions &&
          payload.jsVersions.length === currentState.advancedReport.filters.jsVersions.length &&
          payload.jsVersions.length > 0) ||
        payload.jsVersions[0]?.id === "*"
          ? "*"
          : payload.jsVersions
              .filter((unit) => unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      country_id:
        (!currentState.advancedReport.filtersIsLoading.countries &&
          payload.countries.length === currentState.advancedReport.filters.countries.length &&
          payload.countries.length > 0) ||
        payload.countries[0]?.id === "*"
          ? "*"
          : payload.countries
              .filter((unit) => unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      biddercode:
        (!currentState.advancedReport.filtersIsLoading.biddercodes &&
          payload.biddercodes.length === currentState.advancedReport.filters.biddercodes.length &&
          payload.biddercodes.length > 0) ||
        payload.biddercodes[0]?.id === "*"
          ? "*"
          : payload.biddercodes
              .filter((unit) => unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      pct:
        payload.pct[0]?.id === "*"
          ? ""
          : payload.pct
              .filter((unit) => unit.id && unit.id !== "NaN" && unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      currency:
        payload.currencies[0]?.id === "*"
          ? ""
          : payload.currencies
              .filter((unit) => unit.id && unit.id !== "null" && unit.isSelected)
              .map((unit) => unit.id)
              .join(","),
      cstm_start: rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value ? customDateRange.customDateStart : null,
      cstm_end: rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value ? customDateRange.customDateEnd : null,
      cdm: currentState.advancedReport.combinedDataMode,
    };
    const summaryBodyStr = JSON.stringify(summaryBody);
    if (
      summaryBodyStr === currentState.advancedReport.getStatsBody &&
      currentState.advancedReport.stats.length > 0
    ) {
      console.log("Reading data from cache...");
      return { useCache: true };
    }
    vrPayload.dgm = dgm;
    dispatch(getVisitorRecognitionStats(vrPayload));
    try {
      const postResponse = await fetch(`${host}/statistics/${target}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        signal,
        body: JSON.stringify(summaryBody),
      });

      if (!postResponse.ok) {
        throw new Error("server can not get report");
      }
      let result = {};
      dispatch(setGetStatsBody(summaryBodyStr));
      const responseJSON = await postResponse.json();
      result.data = transformColumnBaseToRowBase(responseJSON.data);
      result.reactAppVersion = responseJSON.reactAppVersion;
      checkVersion(result.reactAppVersion);
      const mapping = partnersSelected.reduce((acc, obj) => {
        if (obj.partner_name && obj.dummy_id) {
          //acc[obj.partner_name] = obj.dummy_id;
          acc[obj.dummy_id] = obj.partner_name;
        }
        return acc;
      }, {});
      for (const dat of result.data) {
        //dat.dummy_id = mapping[dat?.pName];
        dat.dummy_id = dat.partner_id;
        dat.pName = mapping[dat.partner_id];
      }

      let data = {
        result,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
      };

      //apply same date range to report slice
      if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
        dispatch(resetSavedCustomRawDataState());
      }
      dispatch(resetSummaryCompleteDate());

      if (result.data.length === 0) {
        return data;
      }

      let reportSelectedPct = currentState.report.selectedPct;
      let reportSelectedCurrency = currentState.report.selectedCurrency;
      let shouldUpdateSelected = false;
      let { pctModes, currencyModes, uniqPairs, maxCurrencyPctCombination } = getReportPctModes(
        result.data,
        payload.pct,
        payload.currencies,
      );

      let has = false;
      for (let i of uniqPairs) {
        if (i.currency === reportSelectedCurrency && i.pct === reportSelectedPct) has = true;
      }
      if (!has) {
        reportSelectedPct = uniqPairs[0].pct;
        reportSelectedCurrency = uniqPairs[0].currency;
        shouldUpdateSelected = true;
      }

      let payloadToSet = {
        pctModes,
        currencyModes,
        uniqPairs,
        shouldUpdateSelected,
        maxCurrencyPctCombination,
      };

      data.pctModes = pctModes;
      data.currencyModes = currencyModes;
      data.uniqPairs = uniqPairs;
      data.reportSelectedPct = reportSelectedPct;
      data.reportSelectedCurrency = reportSelectedCurrency;
      data.customDateRange = customDateRange;
      dispatch(setCurrencyPctModeData(payloadToSet));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
export const getStatsType = (payload = {}) => getStats(payload);
