import React from 'react'
import logos from "../../assets/images/company_logos.png";
import Box from '@mui/material/Box';

const stl = {
  clipped: {
    width: { md: "400px", lg: "600px", xl: "970px" },
    height: { md: "400px", lg: "600px", xl: "970px" },
    position: "absolute",
    right: "-25px",
    top: "0px",
    background: `url(${logos}) repeat, #FCFDFF`,
    backgroundSize: "200%",
    // background: "linear-gradient(to left, #bebebe, transparent)",
    WebkitClipPath: "url(#my-clip-path)",
    clipPath: "url(#my-clip-path)",
    boxShadow: "inset 5px 17px 157px rgba(0, 23, 56, 0.15)",
    animation: "scroll-anim 120s linear infinite",
    "@keyframes scroll-anim": {
      "100%": { opacity: 0.1 },
      "99%": { opacity: 1 },
      "1%": { opacity: 1 },
      "0%": { opacity: 0.1 },
      "100%": {
        backgroundPosition: "100% 0",
      }
    },
  },
};

export default function Splashscreen() {
  return (
    <>

      <svg>
        <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
          <path
            d="M0.972,0.72 L0.897,0.645 C0.949,0.568,0.98,0.474,0.98,0.373 C0.98,0.103,0.76,-0.116,0.49,-0.116 C0.22,-0.116,0,0.103,0,0.373 C0,0.643,0.22,0.862,0.49,0.862 C0.59,0.862,0.683,0.832,0.761,0.781 L0.837,0.856 C0.874,0.894,0.935,0.894,0.972,0.856 C1,0.819,1,0.758,0.972,0.72 M0.755,0.504 L0.725,0.473 C0.687,0.436,0.626,0.436,0.589,0.473 C0.552,0.511,0.552,0.572,0.589,0.609 L0.619,0.639 C0.58,0.659,0.536,0.669,0.49,0.669 C0.326,0.669,0.193,0.537,0.193,0.373 C0.193,0.21,0.326,0.077,0.49,0.077 C0.653,0.077,0.786,0.21,0.786,0.373 C0.786,0.42,0.775,0.465,0.755,0.504">
          </path>
        </clipPath>
      </svg>

      <Box sx={stl.clipped}></Box>

    </>
  )
}
