import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  bgcolor: "#fff",
  border: "none",
  textAlign: "center",
  boxShadow: 24,
  borderRadius: "8px",
  padding: "2rem 2rem",
};

export default function BasicModal({
  title = "",
  description = "",
  renderButton = false,
  renderCloseButton = false,
  renderCloseIcon = false,
  textCloseButton = "Close",
  isOpenByDefault = false,
  cb = () => {},
  btnSx = {},
  btnTxt = "Open",
  boxStl = {},
  children,
}) {
  const [open, setOpen] = useState(isOpenByDefault);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (cb) cb();
  };

  return (
    <div>
      {renderButton ? (
        <Button sx={btnSx} onClick={handleOpen}>
          {btnTxt}
        </Button>
      ) : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          ".MuiBackdrop-root": {
            backdropFilter: "blur(7px)",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <Box sx={{ ...style, ...boxStl, outline: "none" }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography
              id="modal-modal-title"
              sx={{ textAlign: "left", fontWeight: "bold", fontSize: "24px", lineHeight: "32px" }}
            >
              {title}
            </Typography>
            <Box sx={{ textAlign: "right" }}>
              {renderCloseIcon ? (
                <CloseIcon sx={{ fontSize: "20px", cursor: "pointer", color: "grey" }} onClick={handleClose} />
              ) : null}
            </Box>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} component="div">
            {description}
          </Typography>
          {children ? <Box sx={{ mt: 2 }}>{children}</Box> : null}
          {renderCloseButton ? (
            <Button
              variant="contained"
              sx={{
                width: "100%",
                fontSize: "16px",
                padding: "0.25rem",
                marginTop: "15px",
                border: "1px solid silver",
                borderRadius: "8px",
              }}
              onClick={handleClose}
            >
              {textCloseButton}
            </Button>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
