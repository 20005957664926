import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { btnStl, avatarStl, cardWrapperStl } from "../tableComponents/styles";
import { formatDate } from "../../utils";
import SideBoardWrapper from "../sideBoard/SideBoardWrapper";
import AdminElement from "../../components/ElementWrappers/AdminElement";
import UserStatusChip from "../../components/Chips/UserStatusChip";
import { CONSTANT } from "../../constants/constants";
import { colors } from "../../theme";
import { useDeleteUserByIdMutation } from "../accountsApi";
import { OriginNames } from "../users/interfaces";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";

export default function UserDetailsCard(props) {
  const { loggedUser } = useSelector((state) => state.users);
  const origin = useSelector((state) => state.users.navigation.origin);
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteUser, { isLoading: isLoadingDeleteUeser, isError: isErrorDeleteUeser }] =
    useDeleteUserByIdMutation();
  const handleHistorUsersItemClick = () => {
    navigate("/users");
  };
  const handleConfirm = async () => {
    if (props?.userData.id) {
      try {
        const result = await deleteUser(props?.userData.id).unwrap();
        alert(`User ${props?.userData?.firstName} ${props?.userData?.lastName} was deleted`);
        //refetchAccounts();
        if (origin?.name === OriginNames.Accounts) {
          navigate(`/accounts/users/${props?.userData?.account_id.id}`);
        }
        if (origin?.name === OriginNames.Users) {
          handleHistorUsersItemClick();
        }
      } catch (error) {
        alert("User was not deleted: " + error.data.message);
        setIsDialogOpen(false);
      }
    }
  };

  const handleDecline = () => {
    setIsDialogOpen(false);
  };
  return (
    <Box
      data-testid="userDetailsSection"
      sx={{
        ...cardWrapperStl,
        display: "flex",
        boxShadow: "0px 12px 20px 0px rgba(0, 0, 0, 0.1)",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", width: "50%", paddingBottom: "0.5rem", position: "relative" }}>
        <Box sx={{ fontSize: "2rem", fontWeight: 600, padding: "1rem" }}>
          <Avatar sx={avatarStl} src={props?.userData.avatarUrl || CONSTANT.defaultAvatarUrl}>
            {props?.userData.firstName[0] ?? "A"} {props?.userData.lastName[0] ?? "A"}
          </Avatar>
        </Box>
        <Box sx={{ position: "absolute", top: "70px", left: "85px" }}>
          <AdminElement canAdmin={props?.userData.canAdminPersonalData}>
            <Box sx={{ width: "50%", textAlign: "right" }}>
              <SideBoardWrapper
                type="edit_user"
                btnTitle={<EditOutlinedIcon sx={{ fontSize: "18px" }} />}
                headerTitle="Edit user"
                btnIcon={false}
                btnStyles={{
                  ...btnStl,
                  width: "20px",
                  padding: "3px",
                  borderRadius: "50%",
                  minWidth: "27px",
                }}
                updateDataAndRows={props.updateDataAndRows}
                value={props.userData}
              />
            </Box>
          </AdminElement>
        </Box>
        <Box sx={{ width: "50%", fontSize: "2rem", fontWeight: 600, padding: "1.5rem 1rem 1rem" }}>
          {props?.userData.firstName ?? ""} <br /> {props?.userData.lastName ?? ""}
        </Box>
      </Box>

      {/* user data - right side */}
      <Box
        sx={{
          width: "50%",
          textAlign: "right",
          display: "flex",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          sx={{ width: "30%", padding: "1rem 0rem", fontSize: "1rem" }}
        >
          <Grid item md={3} sx={{ fontSize: "1.rem" }}>
            Email
          </Grid>
          <Grid item md={3} sx={{ marginTop: "0.5rem", fontSize: "1rem", fontWeight: 600 }}>
            {props.userData.email}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          sx={{ width: "30%", padding: "1rem 0rem", fontSize: "1rem" }}
        >
          <Grid item md={3} sx={{ fontSize: "1.rem" }}>
            Phone
          </Grid>
          <Grid item md={3} sx={{ marginTop: "0.5rem", fontSize: "1rem", fontWeight: 600 }}>
            {props.userData.phone}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          sx={{ width: "30%", padding: "1rem 0rem", fontSize: "1rem" }}
        >
          <Grid item md={3} sx={{ fontSize: "1.rem" }}>
            Registration date
          </Grid>
          <Grid item md={3} sx={{ marginTop: "0.5rem", fontSize: "1rem", fontWeight: 600 }}>
            {formatDate(new Date(props.userData.createdAt)).fullFormat}
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "30%", padding: "1rem 0rem", fontSize: "1rem" }}
        >
          <Grid item md={3} sx={{ fontSize: "1.rem" }}>
            Status
          </Grid>
          <Grid item md={3} sx={{ marginTop: "0.5rem" }}>
            <UserStatusChip status={props.userData.active} />
          </Grid>
        </Grid>
        <AdminElement canAdmin={props?.userData.canAdminAccount && props?.userData.id !== loggedUser.id}>
          <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ width: "90px" }}>
            <Grid item>
              <Button
                sx={{ border: `1px ${colors.gray200} solid`, minWidth: "40px", height: "40px", padding: 0 }}
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              >
                <DeleteOutlinedIcon sx={{ color: colors.mainDarkPrimary100 }} />
              </Button>
            </Grid>
          </Grid>
        </AdminElement>
      </Box>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        title="Confirm delete user"
        body={
          <span>
            Are you sure you want to delete{" "}
            <b>
              {props?.userData?.firstName} {props?.userData?.lastName}
            </b>{" "}
            user?
          </span>
        }
        confirmButtonTitle="Yes, delete"
        declineButtonTitle="No, go back"
        onConfirm={handleConfirm}
        onDecline={handleDecline}
      />
    </Box>
  );
}
