import * as XLSX from "xlsx";
import { DataType } from "./tableStructure";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getSymbolFromCurrency from "currency-symbol-map";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

type ExportReportDataType = {
  [key: string]: string | number;
  "Partner Id": string;
  Partner: string;
  Account: string;
  Amount: number | string;
  Currency: string;
  "Price plan": string;
  Period: string;
  "Date of last invoice": string;
  "Date Of registration": string;
};

// Function to calculate column widths based on data
const calculateColumnWidths = (data: ExportReportDataType[]): Map<string, number> => {
  const widths = new Map<string, number>();
  // Exclude the last row if it's a summary row
  const rowsToConsider = data.slice(0, -1);
  Object.keys(data[0]).forEach((key) => {
    const columnNameWidth = key.length + 2;
    const columnWidth = Math.max(...rowsToConsider.map((item) => String(item[key]).length + 2));
    widths.set(key, Math.max(columnWidth, columnNameWidth));
  });
  return widths;
};

export const handleGenerateReportXLSX = (searchingData: DataType[]) => {
  console.log("generate report  ");
  // Format the data
  const formattedData: ExportReportDataType[] = formatData(searchingData);
  const columnWidths = calculateColumnWidths(formattedData);
  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(formattedData);
  // Create a worksheet
  columnWidths.forEach((width, key) => {
    ws["!cols"] = ws["!cols"] || [];
    const columnIndex = Object.keys(formattedData[0]).indexOf(key);
    ws["!cols"][columnIndex] = { wch: width };
  });
  // Create a workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  // Save to file
  XLSX.writeFile(wb, "exported_data.xlsx");
};

export const handleGenerateReportPDF = (searchingData: DataType[]) => {
  console.log("generate PDF report");
  const formattedData: ExportReportDataType[] = formatData(searchingData);
  const pdfContent = {
    content: [
      {
        layout: "lightHorizontalLines", // You can adjust the layout as needed
        table: {
          headerRows: 1,
          widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"], // Adjust the number of columns as needed
          body: [
            Object.keys(formattedData[0]).map((key) => ({
              text: key,
              style: "tableHeader",
            })), // Header row
            ...formattedData.map((item) => Object.values(item)),
          ],
        },
      },
    ],
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 10,
      },
    },
    defaultStyle: {
      fontSize: 10,
    },
    pageOrientation: "landscape",
  };
  pdfMake.createPdf(pdfContent as any).download("exported_data.pdf");
};

const formatData = (rawData: DataType[]): ExportReportDataType[] => {
  const formattedData: ExportReportDataType[] = rawData.map((item) => {
    return {
      "Partner Id": item.partnerId,
      Partner: item.partner,
      Account: item.account,
      Amount: item.amount,
      Currency: getSymbolFromCurrency(item.currency) || item.currency,
      "Price plan": item.pricePlan,
      Period: item.period,
      "Date of last invoice": `${item.dateOfLastInvoice}`.split("T")[0],
      "Date Of registration": `${item.dateOfRegistration}`.split("T")[0],
    };
  });

  let totalAmount: { [key: string]: number } = {};
  rawData.forEach(({ amount, currency }: { amount: number; currency: string }) => {
    totalAmount[currency] = +((totalAmount[currency] || 0) + amount).toFixed(2);
  });

  const summaryRow: ExportReportDataType = {
    "Partner Id": "Total",
    Partner: "",
    Account: "",
    Amount: Object.keys(totalAmount)
      .map((key) => `${getSymbolFromCurrency(key)}${totalAmount[key].toFixed(2)}`)
      .join("\n"),
    Currency: "",
    "Price plan": "",
    Period: "",
    "Date of last invoice": "",
    "Date Of registration": "",
  };
  formattedData.push(summaryRow);
  return formattedData;
};
