import React from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Popper,
} from "@mui/material";
import { CONSTANT } from "../constants/constants"; // Adjust the import path as necessary
import { useUpdateStatusByIdMutation } from "./accountsApi"; // Adjust the import path as necessary
import { colors } from "../theme";

interface AccountStatusMenuProps {
  account: any;
  currentStatus: number | null;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  isVirtual: boolean;
}

const statusOptions = [
  {
    value: CONSTANT.ACCOUNT_STATUS.ACTIVE.value,
    label: CONSTANT.ACCOUNT_STATUS.ACTIVE.label,
    color: "green",
  },
  {
    value: CONSTANT.ACCOUNT_STATUS.TRIAL.value,
    label: CONSTANT.ACCOUNT_STATUS.TRIAL.label,
    color: "orange",
  },
  {
    value: CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.value,
    label: CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.label,
    color: "red",
  },
];

const AccountStatusMenu: React.FC<AccountStatusMenuProps> = ({
  account,
  currentStatus,
  anchorEl,
  onClose,
  isVirtual,
}) => {
  const [updateStatusById, { isLoading }] = useUpdateStatusByIdMutation();

  const handleStatusChange = async (status: number) => {
    if (account && currentStatus !== status) {
      try {
        await updateStatusById({ id: account.id, status }).unwrap();
        onClose();
      } catch (error) {
        console.error("Failed to update status:", error);
      }
    }
  };

  return (
    <Popper open={anchorEl !== null && account.id !== CONSTANT.IIQ_ACCOUNT_ID} anchorEl={anchorEl}>
      <>
        {isLoading ? (
          <CircularProgress sx={{ width: "300px", margin: "8px" }} />
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={true}
            onClose={onClose}
            slotProps={{
              paper: {
                elevation: 3,
                sx: {
                  borderRadius: "8px",
                  overflow: "hidden",
                  mt: 1.5,
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                },
              },
            }}
          >
            {statusOptions.map((option) => {
              if (option.value === CONSTANT.ACCOUNT_STATUS.TRIAL.value && isVirtual)
                return <MenuItem key={option.value}></MenuItem>;
              return (
                <MenuItem
                  key={option.value}
                  selected={option.value === currentStatus}
                  onClick={() => handleStatusChange(option.value)}
                  sx={{
                    bgcolor: option.value === currentStatus ? colors.foreground02 : "transparent",
                    borderRadius: "8px",
                    margin: "4px 8px",
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "unset", marginRight: "8px" }}>
                    <Box
                      sx={{
                        width: "14px",
                        height: "14px",
                        bgcolor: option.color,
                        borderRadius: "50%",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="bodyLargeRegular">{option.label}</Typography>}
                    sx={{ color: option.color, fontWeight: option.value === currentStatus ? "bold" : "normal" }}
                  />
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </>
    </Popper>
  );
};

export default AccountStatusMenu;
