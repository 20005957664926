import moment from "moment";
import { getAllTimeFirstDate, sortByDate } from "../../../summary/utils";
import { sumObjectsByKey, prepareDateFormat, getDateAgo, getPrevMonthDates, substractHours } from "../../../utils";
import { CONSTANT } from "../../../constants/constants";
import {
  abRevLiftCalc,
  calcAbFillRateLiftToSummaryRow,
  calcAvgCpmRateLiftToSummaryRow,
  calcAvgCpmToSummaryRow,
  getValues,
} from "./table/table-helpers";
import { validateSelection } from "../../../components/DatePicker/datePickerValidator";
const LAST_MONTH = CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value;

const prepareTemplate = (start, end) => {
  let arr = new Array();
  let dt = new Date(start);
  let template = {
    date: "",
    revenue: 0,
    revenueLift: 0,
    cpmLift: 0,
    fillRateLift: 0,
  };
  while (dt.toValue() <= end.toValue()) {
    arr.push({ ...template, date: prepareDateFormat(dt) });
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};

const prepareTemplateMonthlyGrouped = (start, endDate) => {
  let arr = new Array();
  let template = {
    date: "",
    revenue: 0,
    revenueLift: 0,
    cpmLift: 0,
    fillRateLift: 0,
  };

  let dt = new Date(start);
  let m = dt.getMonth() + 1;
  let end = new Date(endDate).getMonth() + 1;
  let monthsArray = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

  for (let i = m; i <= end; i++) {
    arr.push({
      ...template,
      date: prepareDateFormat(dt),
      nameMonth: monthsArray[i - 1],
    });
    dt.setMonth(dt.getMonth() + 1);
  }

  return arr;
};

const prepareTemplateHours = (start, end) => {
  let arr = new Array();
  let dt = start;
  let template = {
    date: "",
    revenue: 0,
    revenueLift: 0,
    cpmLift: 0,
    fillRateLift: 0,
  };

  while (dt <= end) {
    arr.push({ ...template, date: dt.valueOf() });
    dt = moment(dt).add(60, "m").valueOf();

    // dt = moment(dt).add(60, 'm').toDate();
    // dt = new Date(dt.getTime() + 60 * 60000);
  }
  return arr;
};

const calcFillRateLift = ({ fillRateLift: agregatedValues, date }, pct) => {
  let normalizedGrB =
    pct === 100 ? pct * agregatedValues.total_rows2 : (pct / (100 - pct)) * agregatedValues.total_rows2;
  if (normalizedGrB === 0) {
    return 100;
  }
  let res = 100 * (agregatedValues.total_rows1 / normalizedGrB - 1);
  return res;
};

const calcCPMLift = (i) => {
  let sum_avg_cpma = 0;
  let sum_avg_cpmb = 0;

  if (+i.total_cpm1 !== 0 || +i.total_rows1 !== 0) {
    sum_avg_cpma = (1000 * +i.total_cpm1) / +i.total_rows1;
  }

  if (+i.total_cpm2 !== 0 || +i.total_rows2 !== 0) {
    sum_avg_cpmb = (1000 * +i.total_cpm2) / +i.total_rows2;
  }

  return sum_avg_cpma - sum_avg_cpmb;
};
const compare = (dataObj, i, h) => {
  return dataObj.setHours(0, 0, 0, 0) === new Date(i.submitted_date).setHours(0, 0, 0, 0);
};

const convertToWidgetAreaChartData = (template, data, pct = 95, timeGrouping) => {
  // NOT IN USE
  for (let j of template) {
    let dataObj = timeGrouping === CONSTANT.DATE_RANGE_TYPES.HOURLY.value ? j.date : new Date(j.date);
    for (let i of data) {
      // if (dataObj.setHours(0, 0, 0, 0) == new Date(i.submitted_date).setHours(0, 0, 0, 0)) {
      if (compare(dataObj, i, timeGrouping)) {
        let fillRateObj = {
          total_rows1: +i.total_rows1,
          total_rows2: +i.total_rows2,
        };

        let cpmLift = calcCPMLift(i);

        j.date = timeGrouping === CONSTANT.DATE_RANGE_TYPES.HOURLY.value ? dataObj : i.submitted_date.toString();
        j.revenue += i.total_cpm1 + i.total_cpm2;
        j.revenueLift += i.total_cpm1 - i.total_cpm2 * (i.pct / (100 - i.pct));
        j.cpmLift += cpmLift;
        //agregate values - then calcFillRateLift
        let res = sumObjectsByKey(j.fillRateLift, fillRateObj);
        j.fillRateLift = res;
      }
    }

    if (Object.keys(j.fillRateLift).length) {
      j.fillRateLift = calcFillRateLift(j, pct);
    }
  }

  return sortByDate(template);
};

const generateDateToAreaChart = (state) => {
  // generate template and prepare data for area chart
  let reportDateStart = undefined;
  let chartLastDate = undefined;
  let date = new Date();
  let dateCopy = new Date(date);

  if (state.timeGrouping === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
    reportDateStart = { startDate: state.customDateRange.customDateStart };
    chartLastDate = +new Date(state.customDateRange.customDateEnd);
  } else if (state.timeGrouping === CONSTANT.DATE_RANGE_TYPES.HOURLY.value) {
    let substractedDate = substractHours(state.timeSelection);
    reportDateStart = { startDate: substractedDate };
    chartLastDate = moment(date).valueOf();
  } else {
    if (state.timeGrouping === LAST_MONTH) {
      let { start, end } = getPrevMonthDates();
      reportDateStart = { startDayNumber: 1, startDate: start };
      chartLastDate = new Date(end);
    } else {
      reportDateStart = getDateAgo(date, state.timeGrouping);
      chartLastDate = dateCopy.setDate(date.getDate() - 1);
    }
  }

  return {
    reportDateStart,
    chartLastDate,
  };
};

const generateDateRange = (rt, customDateStart, customDateEnd, dgm, timeSelection) => {
  if (dgm === CONSTANT.DATE_GROUP_MODES.AGGREGATED) return [customDateStart];
  const dates = [];
  let endDate = new Date();
  endDate.setDate(endDate.getDate() - 1);
  let startDate = new Date();

  // Adjust the start and end dates based on the rt parameter
  switch (rt) {
    case -1: // Custom date range
      startDate = new Date(customDateStart);
      endDate = new Date(customDateEnd);
      break;
    case -10: // Custom date range
      startDate = new Date(customDateStart);
      break;
    case -30: // Last 30 days (approx. last month)
      startDate = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth() - 1, 1));
      endDate = new Date();
      endDate = new Date(Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), 0));
      break;
    case -2: // Last 'timeSelection' hours
      startDate = new Date(Date.now() - timeSelection * 60 * 60 * 1000);
      // Adjust to the start of the hour
      startDate.setMinutes(0, 0, 0);
      break;
    default: // Last 'rt' days
      startDate = new Date(
        Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate() - rt),
      );
  }

  let currentDate = startDate;
  while (currentDate <= endDate) {
    // Format date based on dgm and add it to the dates array
    const formattedDate = formatDate(currentDate, dgm, rt);
    dates.push(formattedDate);

    // Increment currentDate based on dgm
    currentDate = incrementDate(currentDate, dgm, rt);
  }
  return dates;
};

const formatDate = (date, dgm, rt) => {
  if (dgm === CONSTANT.DATE_GROUP_MODES.HOUR) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else {
    return date.toISOString().split("T")[0];
  }
};

const incrementDate = (date, dgm, rt) => {
  switch (dgm) {
    case 1: // Daily
      return new Date(date.getTime() + 24 * 60 * 60 * 1000);
    case 2: // Monthly
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 1));
    case 3: // Quarterly
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 3, 1));
    case 5: // Hourly
      return new Date(date.getTime() + 1 * 60 * 60 * 1000);
    default:
      return date;
  }
};

const processStatsToAreaChart = (stats, rt, currencyArray, customDateStart, customDateEnd, dgm, timeSelection) => {
  if (rt === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value || dgm === CONSTANT.DATE_GROUP_MODES.AGGREGATED) {
    customDateStart = getAllTimeFirstDate(stats);
  }
  const dateRange = generateDateRange(rt, customDateStart, customDateEnd, dgm, timeSelection);
  const result = {
    revenueLift: {},
    fillRateLift: {},
    cpmLiftRate: {},
  };

  // Initialize objects for each date
  dateRange.forEach((date) => {
    result.revenueLift[date] = { date, submitted_date: date };
    result.fillRateLift[date] = { date, submitted_date: date };
    result.cpmLiftRate[date] = { date, submitted_date: date };

    currencyArray.forEach((currency) => {
      result.revenueLift[date][currency] = 0;
      result.fillRateLift[date][currency] = 0;
      result.cpmLiftRate[date][currency] = 0;

      const currentDateStats = stats.filter((s) => s.submitted_date === date);
      let summary = getValues(currentDateStats, currencyArray);
      let { sum_avg_cpma, sum_avg_cpmb, sum_total_cpm1, sum_total_cpm2 } = calcAvgCpmToSummaryRow(
        summary,
        currencyArray,
      );

      let sum_avg_cpmRateLift = calcAvgCpmRateLiftToSummaryRow(sum_avg_cpma, sum_avg_cpmb);
      let abFillRateLift = calcAbFillRateLiftToSummaryRow(summary);
      const abRevLift = abRevLiftCalc(currentDateStats, currencyArray);

      result.revenueLift[date][currency] = abRevLift[currency].value;
      result.fillRateLift[date][currency] = abFillRateLift;
      result.cpmLiftRate[date][currency] = sum_avg_cpmRateLift[currency].value || 0;
    });
  });

  //console.log("result:", result);
  const res = {
    revenueLift: Object.values(result.revenueLift),
    fillRateLift: Object.values(result.fillRateLift),
    cpmLiftRate: Object.values(result.cpmLiftRate),
  };

  return res;
};

function transformColumnBaseToRowBase(data) {
  const headers = data[0];
  let transformed = [];
  for (let i = 1; i < data.length; i++) {
    let entry = {};
    for (let j = 0; j < headers.length; j++) {
      entry[headers[j]] = data[i][j];
    }
    transformed.push(entry);
  }
  return transformed;
}

function insertAsteriskValues(filtersStored, filters) {
  for (const [key, values] of Object.entries(filtersStored)) {
    for (const value of values) {
      if (value.id === "*") {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          filters[key] = [value];
        }
      }
    }
  }

  return filters;
}
const checkAvailableDgmByDateRange = (dateRange, dateGroupingMode, customDateRange) => {
  switch (dateRange.value) {
    case CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value: {
      if (
        dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH ||
        dateGroupingMode === CONSTANT.DATE_GROUP_MODES.AGGREGATED
      ) {
        return true;
      }
      break;
    }
    case CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value: {
      if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH) {
        return true;
      }
      break;
    }
    case CONSTANT.DATE_RANGE_TYPES.MTD.value: {
      if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH) {
        return true;
      }
      break;
    }
    case CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value: {
      return dateGroupingMode === CONSTANT.DATE_GROUP_MODES.QUARTER;
    }
    case CONSTANT.DATE_RANGE_TYPES.CUSTOM.value: {
      const range = [{ startDate: customDateRange.customDateStart, endDate: customDateRange.customDateEnd }];
      return !validateSelection(dateGroupingMode, range).isValid;
    }
    default: {
      return false;
    }
  }
};

export {
  convertToWidgetAreaChartData,
  prepareTemplate,
  prepareTemplateMonthlyGrouped,
  prepareTemplateHours,
  generateDateToAreaChart,
  processStatsToAreaChart,
  transformColumnBaseToRowBase,
  insertAsteriskValues,
  checkAvailableDgmByDateRange,
};
