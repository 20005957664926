import { colors } from "../../theme";

interface IconProps {
  color?: string;
}
const NotificationsIcon: React.FC<IconProps> = ({ color }) => {
  const fillColor = color ?? colors.netural200;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0012 11.37V17.37L20.2912 18.66C20.9212 19.29 20.4712 20.37 19.5812 20.37H4.41121C3.52121 20.37 3.08121 19.29 3.71121 18.66L5.00121 17.37V11.37C5.00121 8.02 7.36121 5.22 10.5012 4.54V3.37C10.5012 2.54 11.1712 1.87 12.0012 1.87C12.8312 1.87 13.5012 2.54 13.5012 3.37V4.54C16.6412 5.22 19.0012 8.02 19.0012 11.37ZM13.9912 21.38C13.9912 22.48 13.1012 23.37 12.0012 23.37C10.9012 23.37 10.0112 22.48 10.0112 21.38H13.9912Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default NotificationsIcon;
