import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { SelectItemsWrapper } from "../../components/SelectItems/SelectItemsWrapper";
import { titleStlAsterics } from "../sideBoard/boardStl";

const stl = {
  margin: "30px 0",
};

const titleStl = {
  fontSize: "12px",
  fontWeight: 600,
  color: "secondary.mainDark",
  marginBottom: "10.5px",
};

export default function RoleMenu(props) {
  const [rolesWithSelection, setRolesWithSelection] = useState();
  useEffect(() => {
    setRolesWithSelection(
      props?.accountRoles?.map((role) => {
        return { ...role, isSelected: props?.userRoles?.some((r) => r.id === role.id) || false };
      }) || [],
    );
  }, [props?.accountRoles]);

  const setSelectedItems = (newSelectedRoles) => {
    setRolesWithSelection(newSelectedRoles);
    props.onSelect(newSelectedRoles.filter((r) => r.isSelected).map((i) => i.id));
  };

  return (
    <Box sx={stl}>
      <Box
        sx={{
          ...titleStl,
          ...titleStlAsterics,
        }}
      >
        Option to assign availiable in this account role to the user
      </Box>
      <SelectItemsWrapper
        itemName="role"
        itemFieldName="name"
        items={rolesWithSelection}
        setSelectedItems={setSelectedItems}
        actionItem={props.actionItem}
      />
    </Box>
  );
}
