import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  formContainer,
  loginContainer,
  logoContainer,
  mainBackgroundContainer,
  campaignReview,
  formWrapper,
  campaignReviewSpan,
} from "./styles";
import logo from "../../assets/images/logo-negative.svg";
import { getUserMetadataByToken } from "../../auth/auth-api";
import Loader from "../../components/Loader";
import Splashscreen from "../../components/Splashscreen/Splashscreen";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { colors } from "../../theme";
import { CONSTANT } from "../../constants/constants";

const LoginRedirect: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserMetadataWrapper = async () => {
      try {
        const token = await getAccessTokenSilently();
        await getUserMetadataByToken(token, dispatch, true, navigate);
      } catch (e) {
        console.error("Authentication error:", e);
        alert("Failed to log in. Please, contact the administrator");
        logout({ logoutParams: { returnTo: CONSTANT.auth0.logout_uri } });
      }
    };

    if (isAuthenticated) {
      getUserMetadataWrapper();
    } else {
      const urlParams = new URLSearchParams(location.search);
      const error = urlParams.get("error");
      const errorDescription = urlParams.get("error_description");

      if (error) {
        alert(`Login Error: ${errorDescription}`);
        navigate("/login");
      }
    }
  }, [isAuthenticated, getAccessTokenSilently, dispatch, navigate, location.search, logout]);

  return (
    <Box sx={loginContainer}>
      <Box sx={formWrapper}>
        <Box sx={{ ...formContainer, display: "flex", flexFlow: "column" }}>
          <Box sx={logoContainer}>
            <Link to="/" onClick={(e) => e.preventDefault()}>
              <img src={logo} alt="logo" />
            </Link>
          </Box>
          <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box
              sx={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Loader color={colors.primaryCta60} cpSize={"80px"} sx={undefined} />
              </Box>
              <Box>
                <Typography variant="h1XLargeWhite800">Please, wait...</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={mainBackgroundContainer}>
        <Splashscreen />
        <Typography component="span" sx={campaignReviewSpan}>
          Monitoring
        </Typography>
        <Typography sx={campaignReview}>Bid Enhancement</Typography>
      </Box>
    </Box>
  );
};

export default LoginRedirect;
