import { useMemo } from "react";
import { useGetCreatedNotificationsQuery, useGetReceivedNotificationsQuery } from "./notificationsApi";
import { DataGrid } from "@mui/x-data-grid";
import {
  notificationColumns,
  NotificationStatus,
  ResponseNotificationDto,
  UserNotificationType,
} from "./noticeTypes";
import { Box } from "@mui/material";
import { compareDesc, parseISO } from "date-fns";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

const NotificationHistory = () => {
  const { data: createdNotifications } = useGetCreatedNotificationsQuery();
  const { data: receivedNotifications } = useGetReceivedNotificationsQuery();
  const { loggedUser } = useSelector((state: RootState) => state.users);

  const rows = useMemo(() => {
    const mappedReceivedNotifications =
      receivedNotifications?.map((notice) => {
        const recipient: UserNotificationType = {
          recipient: notice.recipients[0],
          notificationStatusName: notice.notificationStatusName,
          notificationStatus: notice.notificationStatus,

          // status: notice.status === NotificationStatus.SENT ? NotificationStatus.RECEIVED : notice.status,
          updatedAt: notice.updatedAt,
        };
        return {
          ...notice,
          userNotifications: [recipient],
        };
      }) ?? [];
    const notifications = [...(createdNotifications ?? []), ...mappedReceivedNotifications];
    if (!notifications) {
      return [];
    }

    return notifications
      .flatMap((notification: ResponseNotificationDto) =>
        notification.userNotifications.map((userNotice: UserNotificationType, index) => {
        return({
          id: `${notification.id}-${index}`,
          noticeActionType: notification.actionType,
          notificationId: notification.id,
          title: notification.title,
          type: notification.usageContextName,
          // type: notification.type,
          label: notification.notificationLevel,
          status: userNotice.notificationStatusName,
          notificationStatus: userNotice.notificationStatus,
          createdBy: `${notification.createdBy.firstName} ${notification.createdBy.lastName}`,
          createdAt: notification.createdAt,
          updatedAt: userNotice.updatedAt,
          recipientName:
            userNotice.recipient.id === loggedUser?.id
              ? "Me"
              : `${userNotice.recipient.firstName} ${userNotice.recipient.lastName}`,
          recipientEmail: userNotice.recipient.email,
        })}),
      )
      .sort((a, b) => {
        const dateA = parseISO("" + a.createdAt);
        const dateB = parseISO("" + b.createdAt);
        return compareDesc(dateA, dateB);
      });
  }, [createdNotifications, receivedNotifications]);

  return (
    <Box>
      <DataGrid
        sx={{
          [`& .Mui-even`]: {
            backgroundColor: "secondary.tableOffWhite",
          },
        }}
        autoHeight={true}
        scrollbarSize={1}
        rowHeight={32}
        rows={rows}
        columns={notificationColumns}
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
        }}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default NotificationHistory;
