import { Box, Typography } from "@mui/material";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { colors, graphColors } from "../../../../theme";
import { VRChartCustomTooltip } from "./VRChartCustomTooltip";
import { ChartData, SummaryCategoryResult } from "../../types";
import { CustomizedAxisTick } from "../../../../components/chartTemplates/utils";
import { actionTerminationChartSelectionTypes, chartTypes, defaultChartSelectionTypes } from "../VRWidgetsPanel";
import { Dispatch, SetStateAction } from "react";
import { ISelectableItem } from "../../../../components/SelectItems/interfaces";

const VRLineChart = ({
  chartType,
  summaryCategoryByDate,
  selectedChartItem,
  defaultSelectedChartItems = [],
  setDefaultSelectedChartItems,
}: {
  chartType: string;
  summaryCategoryByDate: SummaryCategoryResult[];
  selectedChartItem?: string;
  defaultSelectedChartItems?: ISelectableItem[];
  setDefaultSelectedChartItems?: Dispatch<SetStateAction<ISelectableItem[]>>;
}) => {
  let data: ChartData[] = [];
  let selectedDefaultSelectedChartItems: ISelectableItem[] = [];

  if (chartType === chartTypes.termination) {
    data =
      summaryCategoryByDate?.flatMap((item) =>
        Object.entries(item).map(([date, summary]) => ({
          name: date,
          value:
            selectedChartItem === actionTerminationChartSelectionTypes.totalRecords.selectionName
              ? summary.totalRecords
              : summary.totalOnlineRecords,
        })),
      ) ?? [];
  } else if (chartType === chartTypes.default) {
    data =
      summaryCategoryByDate?.flatMap((item) =>
        Object.entries(item).map(([date, summary]) => {
          const dataPoint: ChartData = { name: date };

          defaultSelectedChartItems?.forEach((selectedType) => {
            const value = summary[selectedType.selectionName as keyof typeof summary];
            dataPoint[selectedType.selectionName] = +value;
          });

          return dataPoint;
        }),
      ) ?? [];
    selectedDefaultSelectedChartItems = defaultSelectedChartItems.filter((chartItem) => chartItem.isSelected);
  }

  const handleLegendClick = (item: ISelectableItem) => {
    const updatedDefaultSelectedChartItems = defaultSelectedChartItems.map((chartItem) => {
      return {
        ...chartItem,
        isSelected: item.id === chartItem.id ? !chartItem.isSelected : chartItem.isSelected,
      };
    });
    if (setDefaultSelectedChartItems) {
      setDefaultSelectedChartItems(updatedDefaultSelectedChartItems);
    }
  };

  return (
    <Box
      sx={{
        width: chartType === chartTypes.termination ? `calc(100% - 382px)` : `100%`,
        padding: "0",
        height: "280px",
        background: "#FFFFFF",
        border: "1px solid #E0E2E7",
        boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
        borderRadius: "8px",
        position: "relative",
        overflowX: "none",
        overflowY: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 30, //chartType === chartTypes.termination ? 30 : 50,
              right: 10,
              left: 10,
              bottom: 10,
            }}
          >
            {chartType === chartTypes.termination && (
              <>
                <Line type="monotone" dataKey="value" stroke={graphColors.blueGraph100} strokeWidth={2} />
                <YAxis
                  dataKey="value"
                  tick={<CustomizedAxisTick />}
                  tickLine={{ stroke: "none" }}
                  axisLine={false}
                />
              </>
            )}
            {chartType === chartTypes.default && (
              <>
                <Legend
                  wrapperStyle={{ paddingLeft: "60px" }}
                  verticalAlign="top"
                  align="left"
                  height={24}
                  // iconType="dot"
                  content={<CustomLegend items={defaultSelectedChartItems} onClick={handleLegendClick} />}
                />
                <YAxis
                  dataKey={defaultChartSelectionTypes.totalRecords.selectionName}
                  tick={<CustomizedAxisTick />}
                  tickLine={{ stroke: "none" }}
                  axisLine={false}
                />
                {selectedDefaultSelectedChartItems?.map((selectedType) => (
                  <Line
                    key={selectedType.id}
                    type="monotone"
                    dataKey={selectedType.selectionName}
                    stroke={selectedType.color}
                    strokeWidth={2}
                  />
                ))}
              </>
            )}
            <CartesianGrid strokeDasharray="1 1" strokeWidth={1} />
            <XAxis
              dataKey="name"
              interval="preserveStart"
              width={2}
              axisLine={false}
              tick={<CustomizedAxisTick d={data} />}
            />
            {chartType === chartTypes.termination && <Tooltip cursor={false} content={<VRChartCustomTooltip />} />}
            {chartType === chartTypes.default && (
              <Tooltip
                cursor={false}
                content={<VRChartCustomTooltip multiItems={selectedDefaultSelectedChartItems} />}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export const CustomLegend = ({
  items,
  onClick,
}: {
  items: ISelectableItem[];
  onClick: (item: ISelectableItem) => void;
}) => {
  return (
    <Box sx={{ display: "flex", marginTop: "-10px", flexWrap: "wrap" }}>
      {items.map((item, index) => {
        const isActive = item.isSelected;
        const color = isActive ? item.color : "#ccc"; // Grey color for inactive currencies
        return (
          <Box
            key={"custom_legend_" + index}
            onClick={() => onClick(item)}
            style={{
              cursor: "pointer",
              marginRight: 20,
              display: "inline-flex",
              alignItems: "center",
              marginBottom: "6px",
            }}
          >
            <Typography
              style={{
                display: "inline-block",
                width: "16px",
                height: "16px",
                borderRadius: "50%",
                backgroundColor: color,
                marginRight: 5,
              }}
            ></Typography>
            {item.name}
          </Box>
        );
      })}
    </Box>
  );
};

export default VRLineChart;
