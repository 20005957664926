import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AssignRoleToAccount from "../rolesMangementComponents/AssignRoleToAccount";
import AddEntityToRole from "../rolesMangementComponents/AddEntityToRole";
import { useGetAccountsQuery, useCreateRoleMutation } from "../accountsApi";
import Loader from "../../components/Loader";
import { setTargetAccount } from "../users/usersSlice";

export const stlBtn = {
  backgroundColor: "#fff",
  border: "1px solid #E0E2E7",
  color: "black",
  padding: "16px 23px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "16px",
  margin: "4px 2px",
  cursor: "pointer",
  borderRadius: "8px",
};

const stlBtnMain = () => ({
  backgroundColor: "secondary.lightBlue",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    color: "secondary.mainDark",
  },
});

export const feedBackBtnStl = {
  zIndex: 1300,
  position: "fixed",
  top: "75%",
  right: "-43px",
  padding: "0.5rem",
  width: "90px",
  borderRadius: "8px",
  background: "darkorchid",
  color: "darkorchid",
  transform: "rotate(-90deg)",
  fontSize: "10px",
  height: "12px",
  transition: "all 0.5s ease",
  "&:hover": {
    height: "32px",
    right: "-33px",
    fontSize: "1rem",
    backgroundColor: "#131330",
    color: "#FFF",
  },
};

export const feedBackBtnStlExpanded = {
  zIndex: 1300,
  position: "fixed",
  top: "75%",
  right: "-43px",
  padding: "0.5rem",
  width: "90px",
  borderRadius: "8px",
  transform: "rotate(-90deg)",
  height: "32px",
  right: "-33px",
  fontSize: "1rem",
  background: "#131330",
  color: "#FFF",
  "&:hover": {
    background: "#131330",
    color: "#FFF",
  },
};

function CreateNewRoleHandler(props) {
  const [showLoader, setShowLoader] = useState(false);
  const { data: accounts, isFetching, isLoading, refetch } = useGetAccountsQuery();
  const [createRole, { isError: addError }] = useCreateRoleMutation();
  const [selectedAccount, setSelectedAccount] = useState(props?.targetAccount || null);
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    roleName: "",
    roleDescription: "",
    target: null,
    accounts: [],
    partners: [],
    permissions: {},
  });

  const [canNext, setCanNext] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    switch (page) {
      case 0: {
        setButtonEnabled(canNext);
        break;
      }
      case 1: {
        setButtonEnabled(canSubmit);
        break;
      }
    }
  }, [canSubmit, canNext, page]);

  useEffect(() => {
    if (props?.targetAccount) {
      setFormData({ ...formData, target: props?.targetAccount.id });
      setTargetAccount(props?.targetAccount);
    }
  }, []);

  useEffect(() => {
    setCanNext(false);
    setCanSubmit(false);
    if (formData.roleName.length !== 0 && formData.target !== null) setCanNext(true);
    if (
      (formData?.accounts.filter((a) => a.isSelected)?.length !== 0 ||
        formData?.partners.filter((p) => p.isSelected)?.length !== 0) &&
      Object.values(formData?.permissions).some((k) => k !== null)
    ) {
      setCanSubmit(true);
    }
  }, [formData, page]);

  const FormTitles = ["Assign to account", "Add entity to role"];

  let accountsToAdmin = accounts.list.filter((i) => i.canAdmin);
  if (props.targetAccount) {
    accountsToAdmin = accountsToAdmin.filter(
      (a) =>
        a.id === props.targetAccount.id ||
        a.parentAccount === props.targetAccount.id ||
        props.targetAccount.id === 0
    );
  }

  const PageDisplay = () => {
    if (page === 0) {
      return (
        <AssignRoleToAccount
          availiableAccountsList={accountsToAdmin}
          setFormData={setFormData}
          formData={formData}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
        />
      );
    } else if (page === 1) {
      return <AddEntityToRole setFormData={setFormData} formData={formData} />;
    }
  };

  const handleSubmit = async (event) => {
    let payload = {
      roleName: formData.roleName,
      roleDescription: formData.roleDescription,
      target: formData.target,
      permissions: Object.keys(formData.permissions)
        .map((i) => formData.permissions[i])
        .filter((i) => i !== null),
    };

    if (!!formData.accounts.length)
      payload.entities = { ...payload.entities, 2: formData.accounts.map((i) => i.id) };
    if (!!formData.partners.length)
      payload.entities = { ...payload.entities, 1: formData.partners.map((i) => i.partnerId) };

    // let response = await createRole(payload);
    setShowLoader(true);
    createRole(payload).then((data) => {
      setShowLoader(false);
      if (data.error) {
        console.log(data.error);
        alert("Action failed, something went wrong... " + data.error);
      } else {
        refetch();
        props.onClose();
      }
    });
  };

  if (isFetching || isLoading) return <Loader sx={{ height: "80vh" }} />;
  if (showLoader) return <Loader sx={{ height: "80vh" }} />;
  return (
    <div>
      <div>
        <div>{PageDisplay()}</div>
        <Box sx={{ textAlign: "center" }}>
          {page == 0 ? null : (
            <Button
              sx={stlBtn}
              disabled={page == 0}
              onClick={() => {
                //setFormData({ ...formData, permissions: {}, accounts: [], partners: [] });
                setPage((currPage) => currPage - 1);
              }}
            >
              Prev
            </Button>
          )}
          <Button
            sx={!buttonEnabled ? stlBtn : { ...stlBtn, ...stlBtnMain() }}
            disabled={!buttonEnabled}
            onClick={() => {
              if (page === FormTitles.length - 1) {
                // alert("FORM SUBMITTED");
                handleSubmit(formData);
              } else {
                setPage((currPage) => currPage + 1);
              }
            }}
          >
            {page === FormTitles.length - 1 ? "Submit" : "Next"}
          </Button>
        </Box>
      </div>
    </div>
  );
}

export default CreateNewRoleHandler;
