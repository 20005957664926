import * as React from "react";
import { Avatar, Button, IconButton, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setAvatrDialogOpen } from "../users/usersSlice";
import CloseIcon from "@mui/icons-material/Close";
import { CONSTANT } from "../../constants/constants";

interface SelectAvatarBoxProps {
  onReset: () => void;
  avatarUrl: string;
}

const SelectAvatarBox: React.FC<SelectAvatarBoxProps> = ({ avatarUrl, onReset }) => {
  const dispatch = useDispatch();
  const { editedUser } = useSelector((state: any) => state.users);
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Box position="relative" display="inline-block">
        <Avatar
          src={editedUser.avatarUrl || avatarUrl || CONSTANT.defaultAvatarUrl}
          sx={{ width: "72px", height: "72px" }}
        />
        <IconButton
          aria-label="close"
          onClick={onReset}
          size="small"
          sx={{
            position: "absolute",
            right: 0,
            top: "14px",
            backgroundColor: "transparent", // Change as needed
            color: "black", // Change as needed
            "&:hover": {
              backgroundColor: "#e0e0e0", // Change as needed
            },
            // Adjust positioning if needed, considering the size of the avatar
            transform: "translate(50%, -50%)",
            minWidth: "auto", // Reduces the size of the IconButton
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Button
        variant="contained"
        id="test_id_btn_select_avatar"
        onClick={() => dispatch(setAvatrDialogOpen(true))}
      >
        Select avatar
      </Button>
    </Box>
  );
};

export default SelectAvatarBox;
