import { Box } from "@mui/material";

export const renderTableLoader = (title = "...", subtitle = "", icon) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "600px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {icon ? icon : null}
      <div style={{ fontSize: subtitle ? "18px" : "16px" }}>{title}</div>
      {subtitle ? (
        <div
          style={{
            marginTop: "10px",
            padding: "10px",
            backgroundColor: "rgba(27, 178, 255, 0.15)",
            borderRadius: "5px",
          }}
        >
          {subtitle}
        </div>
      ) : null}
    </Box>
  );
};
