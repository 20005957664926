import * as React from "react";
import Popover, { PopoverVirtualElement } from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box, IconButton, List, ListItem } from "@mui/material";
import { colors } from "../../../../theme";
import EditIcon from "../../../../components/IconComponents/EditIcon";
import { FilterGroup } from "../../../../parametersConstancy/parametersConstancyTypes";

export interface FilterGroupsDropdownProps {
  open: boolean;
  anchorEl: Element | PopoverVirtualElement | null | undefined;
  handleClose: () => void;
  handleClickOpenFilterGroupDialogEditForm: (groupId: number) => void;
  handleClickApplyFilterGroup: (groupId: number) => void;
  filterGroups: FilterGroup[];
}

const FilterGroupsDropdown: React.FC<FilterGroupsDropdownProps> = ({
  open = false,
  anchorEl = null,
  handleClose,
  handleClickOpenFilterGroupDialogEditForm,
  handleClickApplyFilterGroup,
  filterGroups = [],
}) => {
  const id = open ? "FilterGroupsDropdownID" : undefined;
  return (
    <Box>
      <Popover
        title="Saved filter groups"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              width: "388px",
              borderRadius: "16px",
              padding: "16px",
              boxShadow: `0px -4px 12px 0px rgba(16, 29, 65, 0.10), 0px 6px 6px 0px rgba(16, 29, 65, 0.10)`,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "16px",
            },
          },
        }}
      >
        <Typography variant="h3Medium600">Saved filter groups</Typography>
        <Box>
          <List
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "flex-start",
              alignContent: "flex-start",
              alignSelf: "stretch",
              padding: "0",
            }}
          >
            {filterGroups?.map((group) => (
              <ListItem
                key={group.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  width: "fit-content",
                  padding: "0",
                  ":hover": {
                    background: colors.mainWhite,
                  },
                  ":focus": {
                    background: colors.mainWhite,
                  },
                }}
              >
                <Typography
                  variant="body2XSmall"
                  sx={{
                    display: "flex",
                    padding: "4px 12px",
                    backgroundColor: `${colors.navy50}`,
                    alignItems: "center",
                    borderRadius: "12px 2px 2px 12px",
                    textAlign: "right",
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: `${colors.navy50}`,
                    },
                    ":focus": {
                      backgroundColor: `${colors.navy50}`,
                    },
                  }}
                  onClick={() => handleClickApplyFilterGroup(group.id ?? 0)}
                >
                  {group.name}
                </Typography>
                <IconButton
                  size="small"
                  sx={{
                    display: "flex",
                    padding: "4px 12px",
                    alignItems: "center",
                    borderRadius: "2px 12px 12px 2px",
                    backgroundColor: `${colors.navy50}`,
                    ":hover": {
                      backgroundColor: `${colors.navy50}`,
                    },
                    ":focus": {
                      backgroundColor: `${colors.navy50}`,
                    },
                  }}
                  onClick={() => handleClickOpenFilterGroupDialogEditForm(group.id ?? 0)}
                >
                  <EditIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  );
};
export default FilterGroupsDropdown;
