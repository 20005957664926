import * as React from "react";
import { Typography, Box, Tabs, Tab, TextField, IconButton, InputAdornment } from "@mui/material";
import { useGetAllUsersQuery } from "../accountsApi";
import { mainTableStyle } from "../../core/performanceReports/advancedReport/table/mainTableStyle";
import { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SessionTimeout from "../../components/SessionTimeout/SessionTimeout";
import RoleDetailsPopup from "../rolesMangementComponents/RoleDetailsPopup";
import { UsersTable } from "../tableComponents/UsersTable";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";
import { setOrigin } from "./usersSlice";
import { OriginNames } from "./interfaces";
import { useDispatch } from "react-redux";
import SideBoardWrapper from "../sideBoard/SideBoardWrapper";
import { pageContainerStyle, textFieldInputStl } from "../styles";

interface UsersPageProps {}

export const menuTabs = [
  {
    id: 1,
    title: "All users",
    isDisabled: false,
  },
  {
    id: 2,
    title: "Active users",
    isDisabled: false,
  },
  {
    id: 3,
    title: "Inactive users",
    isDisabled: false,
  },
];

const UsersPage: React.FC<UsersPageProps> = () => {
  const { data, isFetching, isLoading } = useGetAllUsersQuery();
  const users = data?.users;
  const dispatch = useDispatch();
  const [tabIdSelected, setTabIdSelected] = useState(1);
  const [filterText, setFilterText] = useState("");
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIdSelected(newValue);
  };
  const handleFilterChange = (event: any) => {
    setFilterText(event.target.value);
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    dispatch(setOrigin("users"));
  }, []);

  const filteredData = React.useMemo(() => {
    if (!users) return [];

    let filteredUsers = users;
    if (tabIdSelected === 1) {
      filteredUsers = filteredUsers.filter((user) => user.active === 1);
    } else if (tabIdSelected === 2) {
      filteredUsers = filteredUsers.filter((user) => user.active === 0);
    }

    if (filterText) {
      filteredUsers = filteredUsers.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        const partnerAccount = user?.account_id?.company?.toLowerCase() || "";
        return (
          fullName.includes(filterText.toLowerCase()) ||
          user?.email?.toLowerCase().includes(filterText.toLowerCase()) ||
          partnerAccount.includes(filterText.toLowerCase())
        );
      });
    }

    return filteredUsers;
  }, [users, tabIdSelected, filterText]);
  const conbinedDataRef = useRef([]);
  const roleName = useRef("");
  if (isLoading || isFetching) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }
  return (
    <Box sx={pageContainerStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs value={tabIdSelected} onChange={handleTabChange} aria-label="user management tabs">
          {menuTabs.map((tab, index) => (
            <Tab label={tab.title} disabled={tab.isDisabled} key={tab.id} />
          ))}
        </Tabs>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <TextField
            placeholder="Search..."
            variant="outlined"
            value={filterText}
            onChange={handleFilterChange}
            fullWidth
            sx={{ maxWidth: "256px",
              fieldset: { borderColor: "#E0E2E7", borderRadius: "0.5rem" },
             ...textFieldInputStl
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ width: "256px" }}>
            <SideBoardWrapper
              type="user"
              headerTitle="Add new user to account"
              btnTitle="Add new user"
              value={{}}
              btnStyles={{}}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ ...mainTableStyle(), width: "100%" }}>
        {filteredData && <UsersTable users={filteredData}></UsersTable>}
      </Box>

      {open ? (
        <RoleDetailsPopup setOpen={setOpen} data={conbinedDataRef.current} roleName={roleName.current} />
      ) : null}
      <SessionTimeout />
    </Box>
  );
};

export default UsersPage;
