import React from "react";
import { List, ListItem, Typography, Box } from "@mui/material";
import { ItemListProps } from "./interfaces";
import CheckboxWrapper from "../CustomCheckbox/Checkbox";
import { colors } from "../../theme";

const itemSx = {
  padding: "0px",
  backgroundColor: "white",
  alignItems: "left",
  cursor: "pointer",
};

const ItemList: React.FC<ItemListProps> = ({ items, handleToggle, itemFieldName, actionItem }) => {
  return (
    <List
      component="nav"
      aria-label="filtered options"
      sx={{
        maxHeight: "200px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "100%",
        overflow: "auto",
        padding: "10px",
        // border: `1px solid ${colors.primaryCta40}`,
        borderRadius: "16px",
        backgroundColor: "transparent",
      }}
    >
      {items?.length > 0 &&
        items.map((item, index) => (
          <ListItem
            sx={{
              ...itemSx,
              color: item.isSelected ? "#1BB2FF" : "#000",
            }}
            key={index}
            onClick={() => handleToggle(item)}
          >
            <CheckboxWrapper checked={item.isSelected} />
            <Typography>{item[itemFieldName]}</Typography>
          </ListItem>
        ))}
      {actionItem && (
        <ListItem
          sx={{
            ...itemSx,
          }}
          onClick={() => actionItem.onClick()}
        >
          <CheckboxWrapper checked={false} />
          <Typography>{actionItem.name}</Typography>
        </ListItem>
      )}
    </List>
  );
};

export default ItemList;
