const badgeStyle = {
  padding: "0.5rem 0.75rem",
  background: "#001738",
  borderRadius: "1rem",
  color: "#fff",
  fontWeight: 600,
  margin: "0px 6px",
};

const closeBadgeStyle = {
  cursor: "pointer",
  marginLeft: "8px",
  color: "#fff",
};

//POC
export default function NotificationBadge(props) {
  return (
    <div
      style={{
        position: "absolute",
        fontSize: "0.875rem",
        top: "15px",
        right: "15px",
        zIndex: 1010,
      }}
    >
      <div style={{ display: "flex", margin: "0px 10px" }}>
        {props.selectedCellName ? (
          <div onClick={() => props.resetSort()} style={badgeStyle}>
            {props.selectedCellName}
            <span style={closeBadgeStyle}>x</span>
          </div>
        ) : null}
        {props.filterTerms ? (
          <div onClick={() => props.resetColumnFilter()} style={badgeStyle}>
            {props.filterTerms} <span style={closeBadgeStyle}>x</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
