import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Box, Tooltip, Button, Checkbox } from "@mui/material";
import { setDays, prepareDateFormat } from "../../../../utils";
import { singleBtn, activeBtn, stylesWidget } from "../../../overview/styles";
import { getStats } from "../getStats";
import { MonthPicker } from "../../../../components/DatePicker/MonthPicker";
import { handleReset, monthPickChange } from "../../../../components/DatePicker/monthPicker-utils";
import { resetSavedRawDataState } from "../../../../savedRawData/savedRawDataSlice";
import { resetSavedRawDataPreviousPeriodState } from "../../../../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import { resetSummaryChartRecords } from "../../../../summary/summarySlice";
import { resetMainTableData, resetVRTData } from "../advancedReportSlice";
import { resetRawPctAndRawCurrencyIndicators, resetSummaryCompleteDate } from "../../../../report/reportSlice";
import Fade from "@mui/material/Fade";
import { CONSTANT } from "../../../../constants/constants";
import { useLocation } from "react-router-dom";
import { getVisitorRecognitionData } from "../../visitorRecognition/visitorRecognitionFetches";
import { validateSelection } from "../../../../components/DatePicker/datePickerValidator";
import { setCurrentCommonParameter } from "../../../../parametersConstancy/platformParametersSlice";
import { setCurrentCommonParameterWrapper } from "../../../../parametersConstancy/parametersConstancyUtils";
import { BasicTooltip } from "../../../../components/BasicTooltip/BasicTooltip";
import { adjustForTimezone } from "../../../../components/DatePicker/DatePicker";

const DAY = CONSTANT.DATE_GROUP_MODES.DAY;

const styleDatePicker = {
  position: "absolute",
  top: "50px",
  left: "-85px",
  zIndex: 1011,
  boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.1)",
};

const styleDatePickerBG = {
  position: "absolute",
  top: "51px",
  left: "-942px",
  zIndex: 9,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(67, 138, 198, 0.09)",
  backdropFilter: "blur(3px)",
};

export default function ExtendedDatePicker(props) {
  const { filters } = useSelector((state) => state.advancedReport);
  const activeFilters = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values.filter((unit) => unit.isSelected)]),
  );
  const { customDateRange, dateGroupingMode } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const timeGrouping = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange.value,
  );
  const { partnersSelected } = useSelector((state) => state.role);

  const defaultEndDate = new Date();
  const defaultStartDate = setDays(defaultEndDate, -2);
  const contRef = useRef(null);
  const refModal = useRef(null);
  const selectInnerRef = useRef(dateGroupingMode);
  const selectSliceRef = useRef(dateGroupingMode);
  const warnRef = useRef(false);
  const location = useLocation();

  const [openDate, setOpenDate] = useState(false);
  const [reset, setReset] = useState(true);
  const [showWarn, setShowWarn] = useState(false);
  const [warnMessage, setWarnMessage] = useState("");

  const [dateRange, setDateRange] = useState([
    {
      startDate: !!customDateRange.customDateStart
        ? adjustForTimezone(customDateRange.customDateStart)
        : defaultStartDate,
      endDate: !!customDateRange.customDateEnd ? adjustForTimezone(customDateRange.customDateEnd) : defaultEndDate,
      key: "selection",
    },
  ]);
  const [checked, setChecked] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * close datepicker if clicked on outside of element (on blured bg)
     */

    const handleClickOutside = (event) => {
      if (warnRef.current) {
        setShowWarn(false);
        warnRef.current = false;
      }
      if (
        refModal.current &&
        (!refModal.current.contains(event.target) || !contRef.current.contains(event.target))
      ) {
        if (selectInnerRef.current !== selectSliceRef.current) props.setGroupingMode(selectSliceRef.current);
        setOpenDate(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refModal]);

  useEffect(() => {
    selectInnerRef.current = dateGroupingMode;
    selectSliceRef.current = dateGroupingMode;
  }, [dateGroupingMode]);

  const handleOpenClick = (savedDatesRange) => {
    setOpenDate(!openDate);
    //if custom date is active - show selected custom dates
    //else set preselected to store
    if (timeGrouping === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) return;

    const { startDate, endDate } = dateRange[0];

    let payload = {
      customDateRange: {
        customDateStart: prepareDateFormat(startDate),
        customDateEnd: prepareDateFormat(endDate),
      },
    };
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleApplyClick = () => {
    let { isValid, warnMessage } = validateSelection(dateGroupingMode, dateRange);
    setWarnMessage(warnMessage);
    if (!isValid) {
      warnRef.current = true;
      setShowWarn(true);
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    setOpenDate(false);
    const { startDate, endDate } = dateRange[0];

    const customDateRange = {
      customDateStart: prepareDateFormat(startDate),
      customDateEnd: prepareDateFormat(endDate),
    };

    dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("customDateRange", customDateRange)));
    dispatch(
      setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateRange", CONSTANT.DATE_RANGE_TYPES.CUSTOM)),
    );
    if (props.dateGroupingMode !== dateGroupingMode) {
      // apply time group mode if it was changed
      dispatch(
        setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateGroupingMode", props.dateGroupingMode)),
      );
    }
    if (location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) {
      dispatch(resetVRTData());

      dispatch(
        getVisitorRecognitionData({
          requestData: {
            ...activeFilters,
            timeGrouping: CONSTANT.DATE_RANGE_TYPES.CUSTOM.value,
            customDateRange: customDateRange,
            partnersIds: partnersSelected.map((p) => p.dummy_id),
          },
          signal,
        }),
      );
    } else {
      //and reset all saved raw datas
      dispatch(resetSummaryCompleteDate());
      dispatch(resetSavedRawDataState());
      dispatch(resetSavedRawDataPreviousPeriodState());
      dispatch(resetRawPctAndRawCurrencyIndicators());
      dispatch(resetSummaryChartRecords());
      dispatch(resetMainTableData());
      // dispatch(resetTimeRangeAndSelectionBase());
      // dispatch(resetSelectionBaseRawData());

      dispatch(
        getStats({
          ...activeFilters,
          timeGrouping: CONSTANT.DATE_RANGE_TYPES.CUSTOM.value,
          customDateRange: customDateRange,
          partnersIds: partnersSelected,
        }),
      );
    }
  };

  return (
    <div className="lsItem" style={{ position: "relative" }}>
      <BasicTooltip tooltipText="Choose a date range…">
        <Button
          id="test_id_date_range_btn_custom"
          style={props.isActiveButton ? activeBtn : null}
          disabled={props.isDisabled}
          sx={singleBtn}
          onClick={() => handleOpenClick(customDateRange)}
        >
          Custom
        </Button>
      </BasicTooltip>
      {openDate && (
        <div style={{ overflow: "hidden" }}>
          <div
            id="extendedDateRange"
            // style={styleDatePickerBG}
            ref={contRef}
          >
            <div
              style={{ ...styleDatePicker, width: checked ? "615px" : 1140, backgroundColor: "#fff" }}
              ref={refModal}
            >
              <div style={{ display: "flex" }}>
                <div style={{ width: "330px" }}>
                  <MonthPicker
                    className="MonthPicker"
                    initialRange={{
                      // start: { year: 2023, month: 4 },
                      // end: { year: 2023, month: 6 }
                      start: {
                        year: null,
                        month: null,
                      },
                      end: {
                        year: null,
                        month: null,
                      },
                    }}
                    onChange={(selectedRange) => {
                      monthPickChange(selectedRange, setDateRange);
                    }}
                    onReset={reset}
                    darkMode={false}
                  />
                </div>
                <DateRange
                  maxDate={new Date()}
                  editableDateInputs={true}
                  onChange={(item) => {
                    handleReset(setReset);
                    setDateRange([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  direction={"horizontal"}
                  months={checked ? 1 : 2}
                />
                {/* date group mode buttons */}
              </div>
              <div
                style={{
                  textAlign: "center",
                  background: "#fff",
                  paddingBottom: "12px",
                }}
              >
                <Button type="button" onClick={() => handleReset(setReset)} sx={{ marginRight: "2rem" }}>
                  Reset months
                </Button>
                <Button
                  sx={{ ...singleBtn, width: "3rem", padding: 0, color: "#1BB2FF" }}
                  type="button"
                  className="btn"
                  onClick={() => setOpenDate(false)}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ ...singleBtn, width: "3rem", padding: 0, color: "#1BB2FF" }}
                  type="button"
                  className="btn"
                  onClick={handleApplyClick}
                >
                  Apply
                </Button>

                <Tooltip
                  title={
                    <Box style={{ fontSize: "16px" }}>{checked ? "Expand calendar" : "Show only one month"}</Box>
                  }
                >
                  <Checkbox
                    sx={stylesWidget.tickSize}
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Tooltip>
              </div>
              {showWarn ? (
                <Fade
                  in={true}
                  // style={{ transformOrigin: '0 0 0' }}
                  timeout={1300}
                >
                  <Box
                    sx={{
                      padding: "15px",
                      fontSize: "1rem",
                      color: "coral",
                      width: "75%",
                    }}
                  >
                    {warnMessage}
                  </Box>
                </Fade>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
