import { Navigate } from "react-router-dom";
import auth from "./auth-helper";

const PrivateRoute = ({ children, forAdmin = true, modules = [] }) => {
  const isAuth = auth.isAuthenticated();
  if (!isAuth) return <Navigate to="/login" />;
  const permitted = modules.length === 0 || auth.checkModulesVisibility(modules);
  if (forAdmin && permitted) return children;
};

export default PrivateRoute;
