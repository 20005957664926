import React, { useRef, useEffect } from "react";
import Box from '@mui/material/Box';

import { ReactComponent as LoaderProgress } from "../../assets/svgIcons/loader_progress.svg";
import { ReactComponent as LoaderWithoutProgressIndicator } from "../../assets/svgIcons/loader_simple.svg";

interface LoaderWithProgressProps {
  sx?: React.CSSProperties;
  progressInfo?: boolean;
}

const stl: any = {
  wrapper: {
    "& #innerRotatingItem": {
      strokeWidth: 5,
      animation: "spinnerOne 3.2s linear infinite",
      "@keyframes spinnerOne": {
        "0%": {
          transform: "rotate(0deg)",
          transformOrigin: "50% 50%"
        },
        "50%": {
          transform: "rotate(180deg)",
          transformOrigin: "50% 50%",
        },
        "100%": {
          transform: "rotate(360deg)",
          transformOrigin: "50% 50%",
        }
      },
    },

    "& #Ellipse_1200": {
      animation: "ellipse1200 7.2s linear infinite",
      "@keyframes ellipse1200": {
        "0%": {
          opacity: .1
        },
        "50%": {
          opacity: 1
        },
        "100%": {
          opacity: .1
        }
      }
    },

    "& #t1": {
      dominantBaseline: "middle",
      textAnchor: "middle",
      fontSize: "24px",
      stroke: "#1BB2FF",
      fill: "#1BB2FF"
    }
  },
};

const LoaderWithProgress: React.FC<LoaderWithProgressProps> = ({ sx, progressInfo = true }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!progressInfo) return;

    let interval: NodeJS.Timeout;
    let t1 = ref.current!.querySelector("#t1") as HTMLElement;

    const progress = () => {
      let step = 1;
      let current_progress = 0;
      interval = setInterval(function () {
        current_progress += step;
        let str = current_progress.toFixed();

        if(+str >= 99) str = "99"; 
        t1.textContent = `${str} %`;

        if (current_progress >= 55) {
          step = 0.1;
        }

        if (current_progress >= 75) {
          step = 0.01;
        }

        if (current_progress === 99) {
          step = 0.000000001;
        }

      }, 100);
    };

    progress();

    return function cleanup() {
      clearInterval(interval);
    }
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
        ...stl.wrapper
      }}
    >
      {progressInfo ? <LoaderProgress /> : <LoaderWithoutProgressIndicator />}
    </Box>
  );
}

export default LoaderWithProgress;
