import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import auth from "../../auth/auth-helper";
import { useNavigate } from "react-router-dom";
import { unassignRole } from "../api/accounts-api";

export default function UnassignRole(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const jwt = auth.isAuthenticated();
  const clickButton = () => {
    setOpen(true);
  };

  const unassignUserRole = () => {
    let payload = {
      id: +props.userIds,
      role: props.row.id,
      target: +props.row.account_id.id,
    };
    unassignRole(payload).then((data) => {
      if (data && data.error) {
        console.log(data.error);
        alert("Something wrong... " + data.error);
      } else {
        // setRedirect(true);
        props.refetchUserById();
        props.refetchAccounts();
        handleRequestClose();
      }
    });
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  if (redirect) {
    navigate("/accounts", { state: { rec: 1, xtraInfo: "test" } });
  }

  return (
    <span>
      <IconButton aria-label="Delete" onClick={clickButton} color="secondary">
        <DeleteIcon sx={{ fontSize: "1rem", marginTop: "0px" }} />
      </IconButton>

      <Dialog
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "8px",
            boxShadow: "0 .4rem .8rem 0 rgba(0, 56, 93, .1)",
            padding: "1.5rem",
          },
          ".MuiBackdrop-root": {
            backdropFilter: "blur(7px)",
            backgroundColor: "rgba(0, 0, 0, 0.15)",
          },
        }}
        open={open}
        onClose={handleRequestClose}
      >
        <DialogTitle>
          Are you sure you want to unassign <span style={{ fontWeight: 600 }}>{props.row.name}</span> role of this
          user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "1rem" }}>
          <Button onClick={unassignUserRole} color="secondary" autoFocus="autoFocus">
            Yes, unassign
          </Button>
          <Button onClick={handleRequestClose} color="primary">
            No, get back
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
