import { IRmpModulesConfiguration } from "./interfaces";

export const moduleConfigurations: IRmpModulesConfiguration[] = [
  {
    id: 1,
    name: "Default partners user",
    should_hide_sensitive_data: false,
    accept_user_override: false,
    accept_parent_override: false,
  },
  {
    id: 2,
    name: "Default Partners Account",
    should_hide_sensitive_data: false,
    accept_user_override: true,
    accept_parent_override: true,
  },
  {
    id: 3,
    name: "Default Partners Partner",
    should_hide_sensitive_data: false,
    accept_user_override: true,
    accept_parent_override: true,
  },
  {
    id: 4,
    name: "IIQ Power User",
    should_hide_sensitive_data: false,
    accept_user_override: false,
    accept_parent_override: false,
  },
  {
    id: 5,
    name: "IIQ User (Hide sensetive data)",
    should_hide_sensitive_data: true,
    accept_user_override: false,
    accept_parent_override: false,
  },
  // {
  //   id: 6,
  //   name: "Independant visible partner",
  //   should_hide_sensitive_data: false,
  //   accept_user_override: false,
  //   accept_parent_override: false,
  // },
  // {
  //   id: 7,
  //   name: "Detouched private partner",
  //   should_hide_sensitive_data: true,
  //   accept_user_override: true,
  //   accept_parent_override: false,
  // },
];
