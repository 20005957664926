import { validationEmailAddress } from "../../utils";

export const INPT_LENGTH = 50;

function prepareFormattedData(
  name,
  users,
  partner,
  creationDate,
  status = "POS",
  state = "non-active",
  support_type = "support type 1"
) {
  return { name, users, partner, creationDate, status, state, support_type };
}

function formFieldsValidator(formValues, isChecked) {
  let formFields = Object.keys(formValues);
  let newFormValues = { ...formValues };
  let errors = [];

  for (let index = 0; index < formFields.length; index++) {
    const currentField = formFields[index];
    const currentValueStr = formValues[currentField]?.value?.toString();

    //sikp validation if partner was not added
    if (formValues[currentField].required == 0 && !isChecked) continue;

    for (let validator of formValues[currentField].validationFunction) {
      if (!validator(currentValueStr)) {
        newFormValues = {
          ...newFormValues,
          [currentField]: { ...newFormValues[currentField], error: true },
        };
        errors.push({ ...newFormValues[currentField], error: true });
      }
    }
  }

  return {
    newFormValues,
    errors,
  };
}

const validatorFunctions = {
  firstName: [(val) => +val.trim().length < INPT_LENGTH, (val) => !!val.trim().length],
  lastName: [(val) => !!val.trim().length],
  email: [(val) => !!val.trim().length, (val) => validationEmailAddress(val)],
  phone: [
    (val) => {
      if (val.trim().length == 0) return true;
      let patt = new RegExp(/^[+]*[0-9]{1,3}[0-9]{10}$/g); // +XXXXXXXXXXXX ('+' is optional)
      return patt.test(val);
    },
  ],
  position: [(val) => +val?.trim().length < INPT_LENGTH || !val],
};

export { prepareFormattedData, formFieldsValidator, validatorFunctions };
