import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { BasicTooltip } from "../../components/BasicTooltip/BasicTooltip";
import InfoIcon from "@mui/icons-material/Info";
import Loader from "../../components/Loader";
import { addPartnerToAccount } from "../api/accounts-api";
import { buttonSx } from "../../core/performanceReports/advancedReport/styles";
import { formFieldsValidator, validatorFunctions } from "../helpers/board-helpers";
import { boardWrapperStl, stl, titleStl, titleStlAsterics, inputSx } from "./boardStl";
import { validationEmailAddress } from "../../utils";
const INPT_LENGTH = 75;

export default function AddPartnerBoardHandler(props) {
  const [showLoader, setShowLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formValues, setFormValues] = useState({
    company: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Please, enter partner/company name (" + INPT_LENGTH + " symbols)",
      validationFunction: [(val) => +val.trim().length < INPT_LENGTH, (val) => !!val.trim().length],
    },
    pid: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Please, assign partner ID (PID) - optional",
      validationFunction: [
        (val) => {
          if (val.trim().length == 0) return true;
          let patt = new RegExp(/^\d+$/); // ('+' is optional)
          return patt.test(val);
        },
      ],
    },
    pdid: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "Please, enter designated partner id (PDID) - optional",
      validationFunction: [
        (val) => {
          if (val.trim().length == 0) return true;
          let patt = new RegExp(/^\d+$/); // ('+' is optional)
          return patt.test(val);
        },
      ],
    },
    firstName: {
      required: 0,
      value: "",
      error: false,
      errorMessage: "Please, enter user's name",
      validationFunction: validatorFunctions.firstName,
    },
    lastName: {
      required: 0,
      value: "",
      error: false,
      errorMessage: "Please, enter user's last name",
      validationFunction: validatorFunctions.lastName,
    },
    email: {
      required: 0,
      value: "",
      error: false,
      errorMessage: "Please, add an email addres in correct format",
      validationFunction: validatorFunctions.email,
    },
    phone: {
      required: 0,
      value: "",
      error: false,
      errorMessage: "Please, add phone num. (+XXXXXXXXXXXX ('+' is optional))",
      validationFunction: validatorFunctions.phone,
    },
    position: {
      required: 0,
      value: "",
      error: false,
      errorMessage: "---",
      validationFunction: [(v) => 1],
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: { ...formValues[name], value, error: false },
    });
  };

  const handleRetrieve = () => {
    let { newFormValues, errors } = formFieldsValidator(formValues, isChecked);
    if (!!errors.length) {
      setFormValues(newFormValues);
      return;
    }

    setShowLoader(true);

    let data = {
      company: formValues.company.value,
    };

    if (formValues.pid.value.trim() !== "") {
      data.pid = formValues.pid.value.trim();
    }

    if (formValues.pdid.value.trim() !== "") {
      data.pdid = formValues.pdid.value.trim();
    }

    if (isChecked) {
      data.userData = [
        {
          firstName: formValues.firstName.value,
          lastName: formValues.lastName.value,
          phone: formValues.phone.value,
          email: formValues.email.value,
          position: formValues.position.value,
        },
      ];
    }

    addPartnerToAccount(data, props.value.id).then((data) => {
      setShowLoader(false);
      if (data.error) {
        console.log(data.error);
        alert("Action failed, something went wrong... " + data.error);
      } else {
        props.updateDataAndRows(data);
        props.onClose();
      }
    });
  };
  let isDisabled = !Boolean(formValues.company.value);

  if (showLoader) return <Loader sx={{ height: "80vh" }} />;

  return (
    <div>
      <div style={boardWrapperStl}>
        {/* Company name */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            Partner name
          </Box>
          <TextField
            id={"test_id_inp_company_cab"}
            sx={inputSx}
            onChange={handleChange}
            error={formValues.company.error}
            helperText={formValues.company.error && formValues.company.errorMessage}
            value={formValues.company.value}
            placeholder="Partner name"
            name="company"
            autoFocus
          />
        </Box>

        <Box sx={{ ...titleStl, marginTop: "1rem", position: "relative" }}>
          Assigned partner ID (PID)
          <BasicTooltip
            tooltipText={`Optional field. Option to assign ID, associated with partner. If you skip this field - ID will be generated by api`}
            delay={1}
          >
            <span style={{ position: "absolute", top: "-4px", right: "0px" }}>
              <InfoIcon sx={{ fontSize: "1.2rem" }} />
            </span>
          </BasicTooltip>
        </Box>
        <TextField
          id={"test_id_inp_partnerName_cab21"}
          sx={inputSx}
          placeholder="Enter PID"
          name="pid"
          value={formValues.pid.value}
          onChange={handleChange}
          error={formValues.pid.error}
          helperText={formValues.pid.error && formValues.pid.errorMessage}
        />

        <Box sx={{ ...titleStl, marginTop: "1rem", position: "relative" }}>
          Designated partner ID (PDID)
          <BasicTooltip
            tooltipText={`Optional field. You can add PDID, associated with partner. If you skip this field - PDID will be generated by api`}
            delay={1}
          >
            <span style={{ position: "absolute", top: "-4px", right: "0px" }}>
              <InfoIcon sx={{ fontSize: "1.2rem" }} />
            </span>
          </BasicTooltip>
        </Box>
        <TextField
          id={"test_id_inp_partnerName_cab2"}
          sx={inputSx}
          placeholder="Enter PDID"
          name="pdid"
          value={formValues.pdid.value}
          onChange={handleChange}
          error={formValues.pdid.error}
          helperText={formValues.pdid.error && formValues.pdid.errorMessage}
        />

        {/* Do not remove - working functionality */}
        {/* <Box
          sx={{ display: 'flex', justifyContent: "space-between" }}
        >
          <Box>
            Add user to account
          </Box>
          <Box>
            <AntSwitch checked={isChecked}
              onChange={(e) => { setIsChecked(e.target.checked) }} />
          </Box>
        </Box> */}
        {isChecked ? (
          <Box sx={{ border: "1px solid #bebebe", borderRadius: "1rem", padding: "1rem", marginTop: "1rem" }}>
            <Box sx={{ ...titleStl, position: "relative", fontSize: "1rem", marginTop: "1rem" }}>
              {" "}
              <BasicTooltip
                tooltipText={`Optional field. You can add user , associated with this partner. If you skip this fields - all users of this account will have access to this partner`}
                delay={1}
              >
                <span style={{ position: "absolute", top: "-4px", right: "0px" }}>
                  <InfoIcon sx={{ fontSize: "1.2rem" }} />
                </span>
              </BasicTooltip>
            </Box>

            {/* First name */}
            <Box sx={stl}>
              <Box
                sx={{
                  ...titleStl,
                  ...titleStlAsterics,
                }}
              >
                First name
              </Box>
              <TextField
                id={"test_id_inp_firstName_cab"}
                sx={inputSx}
                onChange={handleChange}
                error={formValues.firstName.error}
                helperText={formValues.firstName.error && formValues.firstName.errorMessage}
                value={formValues.firstName.value}
                placeholder="First name"
                name="firstName"
              />
            </Box>

            {/* Last name */}
            <Box sx={stl}>
              <Box
                sx={{
                  ...titleStl,
                  ...titleStlAsterics,
                }}
              >
                Last name
              </Box>
              <TextField
                id={"test_id_inp_LastName_cab"}
                sx={inputSx}
                onChange={handleChange}
                error={formValues.lastName.error}
                helperText={formValues.lastName.error && formValues.lastName.errorMessage}
                value={formValues.lastName.value}
                name="lastName"
                placeholder="Last name"
              />
            </Box>

            {/* Phone number */}
            <Box sx={stl}>
              <Box sx={titleStl}>Phone number</Box>
              <TextField
                id={"test_id_inp_phone_cab"}
                sx={inputSx}
                placeholder="+XXXXXXXXXXXX ('+' is optional)"
                name="phone"
                value={formValues.phone.value}
                onChange={handleChange}
                error={formValues.phone.error}
                helperText={formValues.phone.error && formValues.phone.errorMessage}
              />
            </Box>

            {/* email */}
            <Box sx={stl}>
              <Box
                sx={{
                  ...titleStl,
                  ...titleStlAsterics,
                }}
              >
                Email
              </Box>
              <TextField
                id={"test_id_inp_email_cab"}
                sx={inputSx}
                placeholder="Enter user's email"
                name="email"
                required
                value={formValues.email.value}
                onChange={handleChange}
                error={formValues.email.error}
                helperText={formValues.email.error && formValues.email.errorMessage}
              />
            </Box>

            {/* position */}
            <Box sx={stl}>
              <Box sx={titleStl}>Position</Box>
              <TextField
                id={"test_id_inp_position_cab"}
                sx={inputSx}
                placeholder="Enter users' position"
                name="position"
                value={formValues.position.value}
                onChange={handleChange}
                error={formValues.position.error}
                helperText={formValues.position.error && formValues.position.errorMessage}
              />
            </Box>
          </Box>
        ) : null}
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <Button onClick={handleRetrieve} sx={buttonSx} disabled={isDisabled}>
          Save
        </Button>
      </div>
    </div>
  );
}
