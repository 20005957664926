import {
  convertToValues,
  prepareLineChartsForWidgetBoxes,
  convertDataRawDataForWidgetsPreviousPeriod,
  getReportValues,
  prepareReportDates,
  getReportPctModes,
  getPercentageDifference,
  getPercentageDifferenceByPrevDate,
  convertToZeroValues,
  filterValues,
  filterStatsDataByUnitIds,
} from "../utils";

export const getReportPreviousPeriodReducer = (state, action) => {
  if (!action.payload.result.data.length) {
    state.previousPeriodSummaryRawData = [];
    state.previousPeriodSummaryValues = {};
    state.widgetChartPreviousPeriodData = {};
    state.comparisonResult = {};
    state.widgetDataPrevPerodProcessing = false;
    return;
  }

  let { pctModes, currencyModes, uniqPairs } = getReportPctModes(action.payload.result.data);
  let { stats, activeRangeDate, pct, base, dateGroupingMode } = getReportValues(action);

  if (!pctModes.includes(state.selectedPct)) {
    state.previousPeriodSummaryRawData = [];
    state.previousPeriodSummaryValues = {};
    state.widgetChartPreviousPeriodData = {};
    state.comparisonResult = {};

    // state.widgetDataPrevPerodProcessing = false;
    // return;
  }

  stats = stats.filter((i) => i.pct === +state.selectedPct && i.currency === state.selectedCurrency);

  //get proper dates
  let { reportDateStart, chartLastDate } = prepareReportDates(activeRangeDate, action, state, true);
  let { agregatedValues } = convertDataRawDataForWidgetsPreviousPeriod(
    stats,
    state.clutterRemovalPercentage.crpWithIIQ,
    base
  );

  // Widget chart
  let widgetChartDataPrevPeriod = prepareLineChartsForWidgetBoxes(
    stats,
    reportDateStart.startDate,
    chartLastDate,
    state.selectedPct,
    dateGroupingMode
  );

  //get agregated values, calculate for widgets and add to store
  state.previousPeriodSummaryRawData = agregatedValues;
  state.previousPeriodSummaryValues =
    stats.length > 0 ? convertToValues(agregatedValues, state.selectedPct) : convertToZeroValues();

  let comparisonResult = {};
  if (stats.length > 0) {
    for (let i in state.previousPeriodSummaryValues) {
      comparisonResult[i] = getPercentageDifference(
        state.previousPeriodSummaryValues[i],
        state.currentPeriodSummaryValues[i]
      );
    }
  }

  state.comparisonResult = comparisonResult;
  state.summaryCompleteData.previousPeriodSummaryValues = state.previousPeriodSummaryValues;
  state.summaryCompleteData.comparisonResult = state.comparisonResult;
  //save "full" original data of prev period
  state.summaryCompleteData.widgetChartDataPrevPeriod = widgetChartDataPrevPeriod;

  let chartData = state.recs;
  let statsFiltered = stats;
  if (chartData.excludedValues?.length > 0) {
    //remove from rawData excluded values
    let ids = chartData.excludedValues.map((i) => i.name);
    statsFiltered = statsFiltered.filter((i) => {
      return !ids.includes(i[base]);
    });
    // agregatedValues = getValuesFromRawDataForWidgets(statsFiltered);
  }

  // (1*)
  let filterBase = state.filterBase;
  stats = filterStatsDataByUnitIds(statsFiltered, filterBase, state.unit);

  widgetChartDataPrevPeriod = prepareLineChartsForWidgetBoxes(
    stats,
    reportDateStart.startDate,
    chartLastDate,
    state.selectedPct,
    dateGroupingMode
  );

  // FIXME: find alternative solution

  let { agregatedValues: agregatedValuesFiltered } = convertDataRawDataForWidgetsPreviousPeriod(
    stats,
    state.clutterRemovalPercentage.crpWithIIQ,
    base
  );
  state.previousPeriodSummaryRawData = agregatedValuesFiltered;
  state.previousPeriodSummaryValues =
    stats.length > 0 ? convertToValues(agregatedValuesFiltered, state.selectedPct) : convertToZeroValues();
  if (stats.length > 0) {
    for (let i in state.previousPeriodSummaryValues) {
      comparisonResult[i] = getPercentageDifference(
        state.previousPeriodSummaryValues[i],
        state.currentPeriodSummaryValues[i]
      );
    }
  }

  state.widgetChartPreviousPeriodData = widgetChartDataPrevPeriod;
  state.widgetDataPrevPerodProcessing = false;
};
