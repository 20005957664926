import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CreateAccountBoardHandler from "./CreateAccountBoardHandler";
import EditAccountBoardHandler from "./EditAccountBoardHandler";
import AddPartnerBoardHandler from "./AddPartnerBoardHandler";
import AddUserToAccountBoardHandler from "./AddUserToAccountBoardHandler";
import EditRoleBoardHandler from "./EditRoleBoardHandler";
import AddSubAccountBoardHandler from "./AddSubAccountBoardHandler";
import EditUserBoardHandler from "./EditUserBoardHandler";
import CreateNewRoleHandler from "./CreateNewRoleHandler";
import { useSelector } from "react-redux";
import SelectAvatarDialog from "./SelectAvatarDialog";
import EditRoleEntitiyBoardHandler from "../rolesMangementComponents/EditRoleEntitiyBoardHandler";

export default function Popup({
  open,
  onClose,
  updateDataAndRows,
  type,
  value = {},
  headerTitle = "Add new account",
}) {
  const PageDisplay = () => {
    if (type == "partner") {
      return <AddPartnerBoardHandler onClose={onClose} value={value} updateDataAndRows={updateDataAndRows} />;
    }
    if (type == "edit") {
      return <EditAccountBoardHandler updateDataAndRows={updateDataAndRows} value={value} onClose={onClose} />;
    }
    if (type == "user") {
      return (
        <AddUserToAccountBoardHandler updateDataAndRows={updateDataAndRows} value={value} onClose={onClose} />
      );
    }
    if (type == "edit_user") {
      return <EditUserBoardHandler updateDataAndRows={updateDataAndRows} value={value} onClose={onClose} />;
    }
    if (type == "role") {
      return <EditRoleBoardHandler updateDataAndRows={updateDataAndRows} value={value} onClose={onClose} />;
    }
    if (type == "role_creation") {
      return <CreateNewRoleHandler updateDataAndRows={updateDataAndRows} value={value} onClose={onClose} />;
    }
    if (type == "edit_role_entity") {
      return <EditRoleEntitiyBoardHandler updateDataAndRows={updateDataAndRows} value={value} onClose={onClose} />;
    }
    if (type == "virtual_account") {
      return <AddSubAccountBoardHandler updateDataAndRows={updateDataAndRows} value={value} onClose={onClose} />;
    }
    return <CreateAccountBoardHandler onClose={onClose} updateDataAndRows={updateDataAndRows} />;
  };
  const { isAvatrDialogOpen } = useSelector((state) => state.users);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      // fullWidth
      sx={{
        "& .MuiPaper-root": {
          width: "488px",
          height: "100vh !important",
          position: "absolute",
          top: "0",
          right: "0",
          margin: 0,
          padding: "1rem",
        },
        ".MuiBackdrop-root": {
          backdropFilter: "blur(7px)",
          backgroundColor: "rgba(0, 0, 0, 0.15)",
        },
      }}
    >
      {isAvatrDialogOpen ? (
        <SelectAvatarDialog avatarUrl={value.avatarUrl}></SelectAvatarDialog>
      ) : (
        <>
          <DialogTitle sx={{ paddingLeft: "1rem", fontWeight: 600, color: "secondary.mainDark" }}>
            {headerTitle}
          </DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 24,
              top: 30,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ background: "#fff" }}>{PageDisplay()}</DialogContent>
        </>
      )}
    </Dialog>
  );
}
