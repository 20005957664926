import { useState, useRef, useEffect } from "react";
import MenuList from "@mui/material/MenuList";
import { ListItemButton, ListItemText } from "@mui/material";
import { Button, Box } from "@mui/material";
import { singleBtn } from "../../../../core/overview/styles";
import { CONSTANT } from "../../../../constants/constants";
import CheckboxWrapper from "../../../../components/CustomCheckbox/Checkbox";
import { validateSelection } from "../../../../components/DatePicker/datePickerValidator";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCommonParameter } from "../../../../parametersConstancy/platformParametersSlice";
import { setCurrentCommonParameterWrapper } from "../../../../parametersConstancy/parametersConstancyUtils";

export const modeOptions = [
  {
    id: CONSTANT.DATE_GROUP_MODES.HOUR,
    title: "Hourly",
    subtitle: "Xtra test",
    isDisabled: false,
  },
  {
    id: CONSTANT.DATE_GROUP_MODES.DAY,
    title: "Daily",
    subtitle: "Xtra test",
    isDisabled: false,
  },
  {
    id: CONSTANT.DATE_GROUP_MODES.MONTH,
    title: "Monthly",
    subtitle: "test 2",
    isDisabled: false,
  },
  {
    id: CONSTANT.DATE_GROUP_MODES.QUARTER,
    title: "Quarterly",
    subtitle: "test 2",
    isDisabled: false,
  },
  {
    id: CONSTANT.DATE_GROUP_MODES.AGGREGATED,
    title: "Aggregated data",
    subtitle: "test 2",
    isDisabled: false,
  },
];

export default function DateGroupSelection({
  handleOpenClick,
  setOpenDate,
  handleClick,
  isDisabled,
  openDate,
  dateGroupingMode: dgm,
  active_range_date,
  mode,
  customDateRange,
  showHours = false,
}) {
  const { dateGroupingMode } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const contRef = useRef(null);
  const refModal = useRef(null);
  const [value, setValue] = useState(dateGroupingMode);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let disable = shouldDisable(value, active_range_date, customDateRange);
    if (
      (location.pathname !== CONSTANT.PAGES.PERFORMANCE_REPORT.path &&
        dateGroupingMode === CONSTANT.DATE_GROUP_MODES.HOUR) ||
      disable
    ) {
      setValue(CONSTANT.DATE_GROUP_MODES.DAY);
      dispatch(
        setCurrentCommonParameter(
          setCurrentCommonParameterWrapper("dateGroupingMode", CONSTANT.DATE_GROUP_MODES.DAY),
        ),
      );
    } else {
      setValue(dateGroupingMode);
    }
  }, []);

  useEffect(() => {
    setValue(dateGroupingMode);
  }, [dateGroupingMode, mode]);

  useEffect(() => {
    /**
     * close popup if clicked on outside of element
     */

    const handleClickOutside = (event) => {
      if (
        refModal.current &&
        (!refModal.current.contains(event.target) || !contRef.current.contains(event.target))
      ) {
        setValue(dateGroupingMode);
        setOpenDate(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refModal, dateGroupingMode]);

  const shouldDisable = (itemId, active_range_date, customDateRange, isDisabled) => {
    if (isDisabled) return true;
    if (
      itemId === CONSTANT.DATE_GROUP_MODES.QUARTER &&
      active_range_date !== CONSTANT.DATE_RANGE_TYPES.CUSTOM.value
    )
      return true;
    if (itemId === CONSTANT.DATE_GROUP_MODES.HOUR)
      return location.pathname !== CONSTANT.PAGES.PERFORMANCE_REPORT.path;
    switch (active_range_date) {
      case CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value: {
        if (itemId === CONSTANT.DATE_GROUP_MODES.MONTH || itemId === CONSTANT.DATE_GROUP_MODES.AGGREGATED)
          return true;
      }
      case CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value: {
        if (itemId === CONSTANT.DATE_GROUP_MODES.MONTH) return true;
      }
      case CONSTANT.DATE_RANGE_TYPES.MTD.value: {
        if (itemId === CONSTANT.DATE_GROUP_MODES.MONTH) return true;
      }
      case CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value: {
        if (itemId === CONSTANT.DATE_GROUP_MODES.QUARTER) return true;
        else return false;
      }
      case CONSTANT.DATE_RANGE_TYPES.CUSTOM.value: {
        const range = [{ startDate: customDateRange.customDateStart, endDate: customDateRange.customDateEnd }];
        return !validateSelection(itemId, range).isValid;
      }
    }
    return false;

    // if (active_range_date !== CUSTOM_DATE_RANGE) {
    //   return active_range_date < 29 && active_range_date !== CUSTOM_DATE_RANGE && item.id == 2;
    // }
  };

  return (
    <div style={{ position: "relative" }}>
      <Button
        id="test_id_dgm_select_btn_custom"
        disabled={false}
        sx={{ ...singleBtn, color: "secondary.lightBlue" }}
        onClick={() => handleOpenClick()}
      >
        {/* {openDate ? <>Group reports by</> : <>Grouping {dateGroupingMode == 1 ? "daily" : "monthly"}</>} */}
        {modeOptions.filter((e) => e.id === dateGroupingMode)[0].title}
      </Button>
      {openDate && location.pathname !== CONSTANT.PAGES.VISITOR_RECOGNITION.path && (
        <div id="extendedGroupingModeSelection" ref={contRef}>
          <Box
            sx={{
              position: "absolute",
              top: "50px",
              left: "20px",
              zIndex: 10,
              boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
              width: "234px",
              border: "1px solid #84DAFF",
              borderRadius: "5px",
              padding: "22px",
            }}
            ref={refModal}
          >
            <MenuList id="date_grouping_menu_board" sx={{ padding: "0 0 9px 0" }}>
              {modeOptions.map((item) => {
                const disabled = shouldDisable(item.id, active_range_date, customDateRange, item.isDisabled);
                const checked = value === item.id;
                if (item.id === -2 && !showHours) return <></>;
                if (
                  location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path &&
                  (item.id === CONSTANT.DATE_GROUP_MODES.QUARTER ||
                    item.id === CONSTANT.DATE_GROUP_MODES.AGGREGATED)
                ) {
                  return null;
                }
                if (
                  (location.pathname === CONSTANT.PAGES.OVERVIEW.path ||
                    location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) &&
                  item.id === CONSTANT.DATE_GROUP_MODES.HOUR
                )
                  return null;
                return (
                  <ListItemButton
                    key={item.id}
                    role={undefined}
                    dense
                    onClick={() => {
                      setValue(item.id);
                    }}
                    disabled={disabled}
                    sx={{ padding: 0 }}
                  >
                    <CheckboxWrapper
                      checked={checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": `checkbox-list-label-${item.id}` }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body2XSmallMain400",
                      }}
                      id={`checkbox-list-label-${item.id}`}
                      primary={item.title}
                    />
                  </ListItemButton>
                );
              })}
            </MenuList>
            <Button
              variant="containedFullWidth"
              onClick={() => {
                handleClick(value);
                // setOpenDate(false);
              }}
            >
              Apply
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
}
