import { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Layout/Header/Header";
import Grid from "@mui/material/Grid";
import PrivateRoute from "../auth/PrivateRoute";
import auth from "../auth/auth-helper";
import NotFound from "./NotFound";
import useMediaQuery from "@mui/material/useMediaQuery";
import AccountsPage from "../accounts/AccountsPage";
import AccountPage from "../accounts/AccountPage";
import UserDetailsPage from "../accounts/UserDetailsPage";
import BillingPage from "./billing/BillingPage";
import UsersPage from "../accounts/users/usersPage";
import VisitorRecognitionPage from "./performanceReports/visitorRecognition/VisitorRecognitionPage";
import RolesManagementPage from "../accounts/RolesManagementPage";
import { CONSTANT } from "../constants/constants";
import ConnectionForm from "../accounts/auth0Connections/ConnectionForm";
import ParametersStorer from "../parametersConstancy/ParametersStorer";
import OverviewChangedParametersHandler from "../parametersConstancy/changedParametersHandlers/OverviewChangedParametersHandler";
import CommonChangedParametersHandler from "../parametersConstancy/changedParametersHandlers/CommonChangedParametersHandler";
import PerformanceReportChangedParametersHandler from "../parametersConstancy/changedParametersHandlers/PerformanceReportChangedParametersHandler";
import NotificationsPage from "./notifications/NotificationsPage";
import Banner, { BannerLevelNames } from "../components/Banner/Banner";
import { getUserMetadataByToken } from "../auth/auth-api";
import { useAuth0 } from "@auth0/auth0-react";
// import { useSideBarIsOpen } from "../components/chartTemplates/useSideBarIsOpen";

const OverviewPage = lazy(() => import("./overview/OverviewPage"));
const AdvancedReportPage = lazy(() => import("./performanceReports/advancedReport/AdvancedReportPage"));

function UserEl({ children }) {
  let { partnersSelected } = useSelector((state) => state.role);
  let mp = 12;
  if (mp === 12 || partnersSelected.legth === 0) {
    return <>{children}</>;
  } else {
    return <div>No access</div>;
  }
}

const MainRoutes = () => {
  const isAuth = auth.isAuthenticated();
  let { sideBarIsOpen } = useSelector((state) => state.role);
  const matches = useMediaQuery("(min-width:1280px)");
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { loggedUser } = useSelector((state) => state.users);
  const { currentPage } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const { getAccessTokenSilently, logout, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  // let [isOpen] = useSideBarIsOpen();
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerLevel, setBannerLevel] = useState(null);
  const [bannerText, setBannerText] = useState("");
  const updateBanner = () => {
    const daysLeft = Math.round((new Date(loggedUser.trialEndDate) - new Date()) / 86400000); // number of ms in a day
    //console.log("daysLeft", daysLeft);
    if (daysLeft > 14) {
      setBannerLevel(BannerLevelNames.INFO);
      setBannerText(`Your trial period ends in ${daysLeft} days`);
    } else if (daysLeft > 1) {
      setBannerLevel(BannerLevelNames.WARNING);
      setBannerText(`Your trial period ends in ${daysLeft} days`);
    } else {
      setBannerLevel(BannerLevelNames.URGENT);
      setBannerText(`Your trial period ends tomorrow`);
    }
  };

  useEffect(() => {
    updateBanner();
  }, [currentPage]);

  useEffect(() => {
    const getUserMetadataWrapper = async () => {
      try {
        const token = await getAccessTokenSilently();
        await getUserMetadataByToken(token, dispatch, false, null, true);
      } catch (e) {
        console.error("Authentication error:", e);
      }
    };
    const intervalId = setInterval(() => {
      if (isAuthenticated) getUserMetadataWrapper();
    }, 60000 * 5);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setBannerVisible(loggedUser.accountStatus === CONSTANT.ACCOUNT_STATUS.TRIAL.value);
    updateBanner();
  }, [loggedUser]);

  const handleCloseBanner = () => {
    setBannerVisible(false);
  };

  return (
    <Grid id="mainWrapper" container direction={matches ? "row" : "column"}>
      <Grid item id="sidebarWrapper">
        <Sidebar setFeedbackOpen={setFeedbackOpen} />
      </Grid>
      <Grid
        item
        className="mainContent"
        style={{
          width: sideBarIsOpen ? "calc(100% - 340px)" : matches ? "calc(100% - 135px)" : "calc(100% - 1rem)",
          marginLeft: sideBarIsOpen ? "320px" : "115px",
          transition: "150ms",
        }}
      >
        <Header feedbackOpen={feedbackOpen} setFeedbackOpen={setFeedbackOpen} />
        <Banner text={bannerText} level={bannerLevel} visible={bannerVisible} onClose={handleCloseBanner} />
        <Routes>
          <Route path="/" element={<Navigate to={CONSTANT.PAGES.OVERVIEW.path} replace />} />
          <Route
            path={CONSTANT.PAGES.OVERVIEW.path}
            element={
              <PrivateRoute
              //  modules={CONSTANT.MODULES_PAGES.OVERVIEW.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <UserEl>
                    <OverviewPage />
                  </UserEl>
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={CONSTANT.PAGES.PERFORMANCE_REPORT.path}
            element={
              <PrivateRoute
              // modules={CONSTANT.MODULES_PAGES.PERFORMANCE_REPORT.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <AdvancedReportPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={CONSTANT.PAGES.VISITOR_RECOGNITION.path}
            element={
              <PrivateRoute
              // modules={CONSTANT.MODULES_PAGES.VISITOR_RECOGNITION.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <VisitorRecognitionPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={CONSTANT.PAGES.ACCOUNTS.path}
            element={
              <PrivateRoute
              // modules={CONSTANT.MODULES_PAGES.ACCOUNTS.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <AccountsPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={CONSTANT.PAGES.ROLES.path}
            element={
              <PrivateRoute
              // modules={CONSTANT.MODULES_PAGES.ROLES.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <RolesManagementPage />
                </Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path={CONSTANT.PAGES.BILLING.path}
            element={
              <PrivateRoute
                forAdmin={isAuth.data?.user.acctId === CONSTANT.IIQ_}
                // modules={CONSTANT.MODULES_PAGES.BILLING.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <BillingPage />
                </Suspense>
              </PrivateRoute>
            }
          />

          <Route
            path={`${CONSTANT.PAGES.ACCOUNTS.path}/:id`}
            element={
              <PrivateRoute
              // modules={CONSTANT.MODULES_PAGES.ACCOUNTS.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <AccountPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONSTANT.PAGES.ACCOUNTS.path + CONSTANT.PAGES.USERS.path}/:id`}
            element={
              <PrivateRoute
              // modules={[...CONSTANT.MODULES_PAGES.ACCOUNTS.modules, ...CONSTANT.MODULES_PAGES.USERS.modules]}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <AccountPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/accounts/connections/:id"
            element={
              <PrivateRoute>
                <Suspense fallback={<div>processing....</div>}>
                  <ConnectionForm />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={`${CONSTANT.PAGES.ACCOUNTS.path}/user_details/:id`}
            element={
              <PrivateRoute
              // modules={[...CONSTANT.MODULES_PAGES.ACCOUNTS.modules, ...CONSTANT.MODULES_PAGES.USERS.modules]}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <UserDetailsPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={CONSTANT.PAGES.USERS.path}
            element={
              <PrivateRoute
                forAdmin={isAuth.data?.user.acctId === CONSTANT.IIQ_}

                // modules={CONSTANT.MODULES_PAGES.USERS.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <UsersPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path={CONSTANT.PAGES.NOTIFICATIONS.path}
            element={
              <PrivateRoute
                forAdmin={isAuth.data?.user.acctId === CONSTANT.IIQ_}
                // modules={CONSTANT.MODULES_PAGES.BILLING.modules}
              >
                <Suspense fallback={<div>processing....</div>}>
                  <NotificationsPage />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <NotFound />
              </PrivateRoute>
            }
          />
        </Routes>
        <PrivateRoute>
          <ParametersStorer />
        </PrivateRoute>
        <CommonChangedParametersHandler />
        <OverviewChangedParametersHandler />
        <PerformanceReportChangedParametersHandler />
      </Grid>
    </Grid>
  );
};

export default MainRoutes;
