import { SxProps, Typography } from "@mui/material";
import { colors } from "../../theme";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SendIcon from "@mui/icons-material/Send";

export const MAX_TITLE_LENGTH = 100;
export const MAX_TEXT_LENGTH = 2000;

export enum NotificationActionType {
  CREATED = "created",
  RECEIVED = "received",
}
export enum NotificationType {
  SYSTEM = "system",
  LOGIN_PAGE = "loginPage",
}

export enum NotificationStepType {
  COMMON = "common",
  RECIPIENTS = "recipients",
}

export enum NotificationRecipientTypes {
  SPECIFIC = "all",
  ALL = "specific",
}

export enum NotificationLabel {
  INFORMATION = "information",
  URGENT = "urgent",
  IMPORTANT = "important",
}

export enum NotificationLevel {
  INFORMATION = 1,
  URGENT = 2,
  IMPORTANT = 3,
}

// export enum NotificationStatus {
//   DRAFT = "draft",
//   PENDING = "pending",
//   SENT = "sent",
//   RECEIVED = "received",
//   READ = "read",
// }

export enum NotificationStatus {
  DRAFT = 1,
  PENDING = 2,
  SENT = 3,
  RECEIVED = 4,
  READ = 5,
}

export interface CreateNotificationDto {
  id?: number;
  // type: NotificationType;
  notificationLevel: NotificationLevel;
  // label: NotificationLabel;
  status: NotificationStatus;
  title: string;
  text: string;
  recipientIds: number[];
}
export interface UserType {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}
export interface UserNotificationType {
  recipient: UserType;
  // status: NotificationStatus;
  notificationStatusName: string;
  notificationStatus: number;
  updatedAt: Date;
}
export interface ResponseNotificationDto {
  id: number;
  title: string;
  text: string;
  notificationStatus: number; 
  usageContextName: string;
  // type: NotificationType;
  actionType: NotificationActionType;
  notificationLevel: string;
  
  // label: NotificationLevel;
  // label: NotificationLabel;
  // status: NotificationStatus;
  notificationStatusName: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: UserType;
  recipients: UserType[];
  userNotifications: {
    recipient: UserType;
    // status: string;
    notificationStatusName: string;
    notificationStatus: number;
    updatedAt: Date;
  }[];
}

export interface SelectionItem {
  id: number;
  title: string;
  base:
    | NotificationType
    | NotificationStepType
    | NotificationRecipientTypes
    | NotificationLabel
    | NotificationLevel
    | NotificationStatus;
  description?: string;
}
export interface NoticeSwitchSelectorProps {
  id: number;
  title: string;
  isChecked: boolean;
  onChange: () => void;
}
export interface NoticeSwitchSelectorWrapperProps {
  types: SelectionItem[];
  selectedType: SelectionItem;
  handleChangeType: (noticeType: SelectionItem) => void;
}

export const notificationTypes = {
  system: {
    id: 1,
    title: "System",
    base: NotificationType.SYSTEM,
  },
  // loginPage: {
  //   id: 2,
  //   title: "Login page",
  // },
};
export const notificationTypesSelection: SelectionItem[] = Object.values(notificationTypes);

export const notificationLabelTypes = {
  information: {
    id: 1,
    title: "information",
    base: NotificationLevel.INFORMATION,
  },
  urgent: {
    id: 2,
    title: "urgent",
    base: NotificationLevel.URGENT,
  },
  important: {
    id: 3,
    title: "important",
    base: NotificationLevel.IMPORTANT,
  },
};
export const notificationLabelSelection: SelectionItem[] = Object.values(notificationLabelTypes);

export const notificationStepTypes = {
  common: {
    id: 1,
    title: "Step 1",
    base: NotificationStepType.COMMON,
    description: "common",
  },
  users: {
    id: 2,
    title: "Step 2",
    base: NotificationStepType.RECIPIENTS,
    description: "users",
  },
  // scheduling: {
  //   id: 3,
  //   title: "Step 3",
  //   description: "scheduling",
  // },
};
export const notificationSteps: SelectionItem[] = Object.values(notificationStepTypes);

export const notificationRecipientTypes = {
  specific: {
    id: 1,
    title: "Specific users",
    base: NotificationRecipientTypes.SPECIFIC,
  },
  // all: {
  //   id: 2,
  //   title: "All users",
  // },
};
export const notificationRecipientTypesSelection: SelectionItem[] = Object.values(notificationRecipientTypes);

export const defaultNoticeTextFieldStyle: SxProps = {
  margin: "6px 0",
  "& .MuiOutlinedInput-root": {
    height: "auto",
    borderRadius: "8px",
    "& fieldset": {
      border: `1px solid ${colors.borderDefault}`,
    },
    "&:hover fieldset": {
      border: `1px solid ${colors.brandPressed}`,
    },
    "&.Mui-focused fieldset": {
      border: `1px solid ${colors.brandPressed}`,
    },
  },
  "& .MuiInputBase-input": {
    height: "auto",
    padding: "12px 16px",
    fontSize: "16px",
    "&::placeholder": {
      opacity: 0.3,
    },
  },
  "& .MuiInputAdornment-root": {
    fontSize: "24px", // Set icon size
  },
};

export const getAccordionSummaryStyle = (isExpanded: boolean): SxProps => ({
  height: "52px",
  minHeight: "52px",
  maxHeight: "52px",
  display: "flex",
  padding: "2px 8px 2px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  borderRadius: "16px",
  background: colors.blue50,
  ...(isExpanded
    ? {
        border: `1px solid ${colors.brandPressed}`,
      }
    : {
        border: "none",
      }),
  "& .MuiAccordionSummary-content": {
    margin: "0", // Remove default margin to ensure vertical centering
  },
  "&.Mui-expanded": {
    minHeight: "52px", // Explicitly set for expanded state
  },
});

export const customAccountsSelectionStyle = (isExpanded: boolean): SxProps => ({
  borderRadius: "24px",
  background: isExpanded ? colors.blue50 : colors.navy50,
  border: `none`,
  "& .MuiInputBase-root": {
    "& > fieldset": {
      border: "none",
    },
  },
  input: {
    paddingLeft: "12px 16px",
    // color: isDisabled ? "grey" : "secondary.mainDark", // Change the color when disabled
    fontSize: "1rem",
    "&::placeholder": {
      opacity: 1,
    },
  },
});

export const notificationColumns: GridColDef[] = [
  // { field: "id", headerName: "ID", flex: 1 },
  { field: "title", headerName: "Title", flex: 1 },
  { field: "type", headerName: "Type", flex: 1 },
  { field: "label", headerName: "Label", flex: 1 },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: ({ value , row}) => {
      //TODO: fix colorized status badge 
      return (
        <Typography
          sx={{
            color: row.notificationStatus === NotificationStatus.READ ? colors.secGreen100 : "inherit",
            fontWeight: row.notificationStatus === NotificationStatus.READ ? 600 : "inherit",
          }}
        >
          {value}
        </Typography>
      )
    },
  },
  { field: "createdBy", headerName: "created by", flex: 1 },
  {
    field: "createdAt",
    headerName: "Created at",
    flex: 1,
    valueFormatter: (params) => format(new Date(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  {
    field: "updatedAt",
    headerName: "Updated at",
    flex: 1,
    valueFormatter: (params) => format(new Date(params.value), "yyyy-MM-dd HH:mm:ss"),
  },
  {
    field: "recipientName",
    headerName: "Recipient name",
    flex: 1,
    renderCell: ({ value }) => (
      <Typography
        sx={{
          color: value === "Me" ? colors.secGreen100 : "inherit",
          fontWeight: value === "Me" ? 600 : "inherit",
        }}
      >
        {value}
      </Typography>
    ),
  },
  { field: "recipientEmail", headerName: "Recipient email", flex: 1 },
  // {
  //   field: "actions",
  //   type: "actions",
  //   headerName: "Actions",
  //   width: 100,
  //   cellClassName: "actions",
  //   getActions: ({ row }) => {
  //     if (row.noticeActionType === NotificationActionType.CREATED) {
  //       return [
  //         <GridActionsCellItem
  //           icon={<EditIcon />}
  //           label="Edit"
  //           onClick={() => {
  //             console.log("Edit draft? notification");
  //           }}
  //           color="inherit"
  //         />,
  //         <GridActionsCellItem
  //           icon={<DeleteIcon />}
  //           label="Delete"
  //           onClick={() => {
  //             console.log("Delete draft? notification");
  //           }}
  //           color="inherit"
  //         />,
  //         <GridActionsCellItem
  //           icon={<SendIcon />}
  //           label="Delete"
  //           onClick={() => {
  //             console.log("Send draft? notification");
  //           }}
  //           color="inherit"
  //         />,
  //       ];
  //     }
  //     return [];
  //   },
  // },
];
