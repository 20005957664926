import React, { useState, useRef } from "react";
import BasicModal from "../../components/BasicModal/BasicModal";
import { Typography, Box } from "@mui/material";

const titleStl = { fontWeight: 700, marginBottom: "1rem" };

const renderSection = (list, title, { field = "accountName", color = "#c2ecc2" } = false) => {
  return (
    <Box>
      <Box sx={titleStl}>{title}</Box>

      <Box sx={{ display: "flex", padding: "0.15rem" }}>
        <Box sx={{ width: "50%", color: "silver" }}> Name</Box>
        <Box sx={{ width: "50%", color: "silver" }}> Module/permission </Box>
      </Box>

      {list.map((i) => {
        return (
          <Box sx={{ display: "flex", padding: "0.15rem" }}>
            <Box sx={{ paddingLeft: "0.5rem", width: "50%", borderLeft: "6px solid " + color }}> {i[field]}</Box>
            <Box sx={{ paddingLeft: "0.5rem", width: "50%", borderLeft: "6px solid " + i.moduleColor }}>
              {" "}
              {i.moduleName}{" "}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const RoleDetailsPopup = (props) => {
  let accounts = [];
  let partners = [];
  props?.data?.forEach((i) => {
    if (i.accountName !== null) accounts.push(i);
    if (i.partnerName !== null) partners.push(i);
  });

  return (
    <div>
      <BasicModal
        boxStl={{ width: "auto" }}
        renderButton={false}
        isOpenByDefault={true}
        renderCloseButton={false}
        textCloseButton={"Got it"}
        renderCloseIcon={true}
        cb={() => props.setOpen(false)}
      >
        <Box sx={{ textAlign: "left", width: "43rem", maxHeight: "70vh", overflow: "auto" }}>
          <Box sx={{ fontWeight: 600, fontSize: "1.5rem", marginBottom: "1rem" }}>{props.roleName}</Box>
          {accounts.length ? renderSection(accounts, "Account") : null}
          <br />
          <br />
          {partners.length ? renderSection(partners, "Partner", { field: "partnerName", color: "#a3d0f3" }) : null}
        </Box>
      </BasicModal>
    </div>
  );
};

export default RoleDetailsPopup;
